import React, { createContext, useState } from "react";
import firebase from "firebase";

export const UserContext = createContext<null | firebase.User>(null);

function UserProvider(props: any) {
  const [user, setUser] = useState<null | firebase.User>(null);

  firebase.auth().onAuthStateChanged((userAuth) => {  
    setUser(userAuth);
  });
  
  return (
    <UserContext.Provider value={user}>{props.children}</UserContext.Provider>
  );
}
export default UserProvider;
