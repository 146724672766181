import React, { useContext, useEffect, useState } from "react";
import {
  fade,
  makeStyles,
  Theme,
  createStyles,
} from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import SecurityIcon from "@material-ui/icons/Security";
import SportsSoccerIcon from '@material-ui/icons/SportsSoccer';


import firebase from "firebase";
import {
  PeopleOutline,
  StarBorder,
  SettingsOutlined,
  AccountCircleOutlined,
} from "@material-ui/icons";
import { Button } from "@material-ui/core";
import { NavLink, Link } from "react-router-dom";
import { StoreUserContext } from "../../../core/providers/StoreUserProvider";
import { UserContext } from "../../../core/providers/UserProvider";
import SearchBar from "../SearchBar/SearchBar";
import { ReactComponent as Logo } from "../../../assets/logo_invister_blanc.svg";
import { ContactUs } from "../ContactUs/ContactUs";
import { useTranslation } from "react-i18next";
import FlagIcon from "../FlagButton/FlagIcon";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grow: {
      flexGrow: 1,
      marginBottom: '5rem',
    },
    title: {
      marginRight: 10,
    },
    search: {
      position: "relative",
      borderRadius: 20,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      "&:hover": {
        backgroundColor: fade(theme.palette.common.white, 0.25),
      },
      marginRight: theme.spacing(2),
      marginLeft: 0,
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(3),
        width: "30%",
      },
      textAlign: "left",
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    inputRoot: {
      color: "inherit",
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("md")]: {
        width: "20ch",
      },
    },
    sectionDesktop: {
      display: "none",
      [theme.breakpoints.up("md")]: {
        display: "flex",
      },
    },
    sectionMobile: {
      display: "flex",
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
  })
);

export default function PrimarySearchAppBar() {
  const { t } = useTranslation();

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const me = useContext(StoreUserContext);
  const [newPlayers, setNewPlayers] = useState(0);

  const [isAdmin, setIsAdmin] = useState(false);

  const authUser = useContext(UserContext);

  const isMenuOpen = Boolean(anchorEl);

  const [openContactUs, setOpenContactUs] = useState(false);

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const auth = firebase.auth();
  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <NavLink
        to="/profile"
        activeStyle={{ color: "grey" }}
        style={{ color: "black" }}
      >
        <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      </NavLink>
      <MenuItem onClick={() => auth.signOut().then(() => window.location.reload())}>Sign Out</MenuItem>
    </Menu>
  );

  useEffect(() => {
    if (!me) return;

    const newPlayers =
      me.notifications != null && me.notifications.newPlayers
        ? me.notifications.newPlayers.length
        : 0;
    setNewPlayers(newPlayers);
  }, [me]);

  useEffect(() => {
    if (!authUser) return;

    authUser.getIdTokenResult().then((token) => setIsAdmin(token.claims.admin));
  }, [authUser]);

  return (
    <div className={classes.grow}>
      <AppBar position="fixed" style={{ borderBottomStyle: "solid", borderBottomColor: "rgb(50,50,50)", borderBottomWidth: "1px" }}>
        <Toolbar>
          <Link to="/" style={{ color: "white", textDecoration: "none" }}>
            <Logo className={classes.title} width="20rem" height="5rem" />
          </Link>
          <SearchBar />
          <div className={classes.sectionDesktop}>
            <NavLink exact to="/" activeStyle={{ color: "white" }} style={{ color: "grey" }}>
              <IconButton color="inherit" className="mr-2 ml-3">
                <Badge badgeContent={newPlayers} color="secondary">
                  <PeopleOutline/>
                </Badge>
              </IconButton>
            </NavLink>

            <NavLink to="/wishlist" activeStyle={{ color: "white" }} style={{ color: "grey" }}>
              <IconButton color="inherit" className="mx-2">
                <StarBorder/>
              </IconButton>
            </NavLink>

            {me && me.parent === "none" ? (
              <NavLink to="/methodology" activeStyle={{ color: "white" }} style={{ color: "grey" }}>
                <IconButton color="inherit" className="mx-2">
                  <SettingsOutlined/>
                </IconButton>
              </NavLink>
            ) : null}

             <NavLink to="/board" activeStyle={{ color: "white" }} style={{ color: "grey" }}>
              <IconButton color="inherit" className="mx-2">
                <SportsSoccerIcon/>
              </IconButton>
            </NavLink>

            <NavLink to="/profile" activeStyle={{ color: "white" }} style={{ color: "grey" }}>
              <IconButton color="inherit" className="mx-2">
                <AccountCircleOutlined/>
              </IconButton>
            </NavLink>

            {isAdmin ? (
              <NavLink to="/admin" activeStyle={{ color: "white" }} style={{ color: "grey" }}>
                <IconButton color="inherit" className="mx-2">
                  <SecurityIcon/>
                </IconButton>
              </NavLink>
            ) : null}
          </div>
          <div className={classes.grow} />
          <div className="d-flex flex-row-reverse">
            <FlagIcon/>
            <Link to="/" style={{ color: "white", textDecoration: "none" }}>
              <Button className="ml-2 my-2" variant="outlined" style={{ color: "white", textTransform: "capitalize", border: "1px solid rgba(255, 255, 255, 1)", borderRadius: "30px" }} onClick={() => auth.signOut().then(() => window.location.reload())}>
                <div className="mx-3">{t("appBar.disconnect")}</div>
              </Button>
            </Link>
            <Button style={{ color: "white", textTransform: "capitalize", textDecoration: "underline" }} onClick={() => setOpenContactUs(true)}>
              {t("appBar.contactUs")}
            </Button>
          </div>
        </Toolbar>
      </AppBar>
      {renderMenu}
      <ContactUs open={openContactUs} handleClose={() => setOpenContactUs(false)}></ContactUs>
    </div>
  );
}
