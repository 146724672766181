import './searchBar.css';

import React from "react";

import { Search } from "@material-ui/icons";

import { connectSearchBox } from "react-instantsearch-dom";

import { InputBase } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const SearchBar = ({ test, currentRefinement, refine } : any) => {

  const { t } = useTranslation();
  
  return (
    <div className="popup-search-wrapper">
      <InputBase placeholder={t("searchBox.search")} fullWidth type="search" value={currentRefinement} onChange={(event) => { refine(event.currentTarget.value); }} />
      <Search />
    </div>
  );
}

export const SearchBoxTest = connectSearchBox(SearchBar);