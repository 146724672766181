import React, { useState, ChangeEvent } from "react";
import "./LoginPage.css";
import { Button } from "@material-ui/core";
import CustomTextField from "../../components/CustomTextField/CustomTextField";
import firebase from "firebase";
import { Link, useHistory } from "react-router-dom";
import { ReactComponent as Logo } from "../../../assets/logo_invister_name.svg";
import { ContactUs } from "../../components/ContactUs/ContactUs";
import whiteLogo from "../../../assets/logo_invister_blanc.svg";
import FlagIcon from "../../components/FlagButton/FlagIcon";
import { useTranslation } from "react-i18next";
import { CgvuLink } from "../../components/Cgvu/CgvuLink";

const LoginPage = () => {
  const { t } = useTranslation();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const history = useHistory();
  const auth = firebase.auth();

  const [openContactUs, setOpenContactUs] = useState(false);

  const signInWithEmailAndPasswordHandler = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    email: string,
    password: string
  ) => {
    auth
      .signInWithEmailAndPassword(email, password)
      .then(() => history.push("/"))
      .catch((error) => setError(error.message));
  };

  const onChangeHandler = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.currentTarget;

    if (name === "userEmail") {
      setEmail(value);
    } else if (name === "userPassword") {
      setPassword(value);
    }
  };

  return (
    <React.Fragment>
      <img src="/LandingPage.png" id="bg" alt=""></img>
      <img src="/landingpage_mobile.png" id="bg_mobile" alt=""></img>
      <div className="mt-8">
        <div className="d-flex flex-row-reverse pt-2 pr-2 pt-sm-4 pr-sm-4 justify-content-between justify-content-sm-start">
          <FlagIcon />
          <Link
            to="/signup"
            style={{ color: "white", textDecoration: "none" }}
            className="d-sm-block d-none"
          >
            <Button
              className="ml-2 my-2"
              variant="outlined"
              style={{
                color: "white",
                textTransform: "capitalize",
                border: "1px solid rgba(255, 255, 255, 1)",
                borderRadius: "30px",
              }}
            >
              <div className="mx-3">{t("login.Sinscrire")}</div>
            </Button>
          </Link>
          <Button
            style={{
              color: "white",
              textTransform: "capitalize",
              textDecoration: "underline",
            }}
            onClick={() => setOpenContactUs(true)}
            className="d-sm-block d-none"
          >
            {t("login.Contactez-nous")}
          </Button>
          <img
            src={whiteLogo}
            alt=""
            width="170"
            className="d-block d-sm-none pl-4"
          ></img>
        </div>

        <div className="d-flex flex-column">
          <Logo
            style={{
              top: "-3em",
              marginTop: "-1.4rem",
              marginLeft: "-7rem",
            }}
            width="50rem"
            height="5rem"
            className="d-sm-block d-none"
          />

          <p
            className="text-left font-weight-bold d-sm-block d-none"
            style={{
              fontSize: "13px",
              top: "-3em",
              marginTop: "-1.4rem",
              marginLeft: "11.9rem",
            }}
          >
            working for tomorrow
          </p>
        </div>

        <div
          className="d-flex flex-column align-items-sm-end align-items-center"
          style={{ marginTop: "13%" }}
          id="login_formContainer"
        >
          <form className="d-flex flex-column" id="login_form">
            <h3 style={{ color: "white" }}>{t("login.Se_connecter")}</h3>

            <CustomTextField
              id="standard-basic"
              label={t("login.E-mail")}
              type="email"
              name="userEmail"
              onChange={(event) => onChangeHandler(event)}
            />
            <CustomTextField
              id="standard-basic"
              label={t("login.Mot_de_passe")}
              type="password"
              name="userPassword"
              onChange={(event) => onChangeHandler(event)}
            />

            <Link
              to="/forgotpassword"
              style={{
                color: "grey",
                textDecoration: "underline",
                textAlign: "end",
              }}
              className="mt-3"
            >
              {t("login.forgot_mdp")}
            </Link>

            <Button
              onClick={(event) =>
                signInWithEmailAndPasswordHandler(event, email, password)
              }
              className="mt-3"
              variant="contained"
              style={{
                background: "linear-gradient(#01B3F4, #044CAB)",
                color: "white",
                borderRadius: "30px",
              }}
            >
              <div className="mx-3">{t("login.Je_me_connecte")}</div>
            </Button>

            {error !== null && <div className="mt-3 text-danger">{error}</div>}

            <Link
              to="/signup"
              style={{ color: "white", textDecoration: "none" }}
              className="d-block d-sm-none mt-4"
            >
              <p>{"login.no_account"}</p>
            </Link>
          </form>
        </div>
      </div>

      <ContactUs
        open={openContactUs}
        handleClose={() => setOpenContactUs(false)}
      ></ContactUs>
      <CgvuLink />
    </React.Fragment>
  );
};
export default LoginPage;
