export default class Document {
    private _uid: string;
  
    private _realName: string;
  
    constructor(
      uid: string,
      realName: string
    ) {
      this._uid = uid;
      this._realName = realName;
    }

    get uid(): string {
      return this._uid;
    }

    set uid(value: string) {
      this._uid = value;
    }

    set realName(value: string) {
      this._realName = value;
    }

    get realName(): string {
      return this._realName;
    }
}