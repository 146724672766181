import { TextField, withStyles } from "@material-ui/core";

const CustomTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "white",
    },
    "& label": {
      color: "white",
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: "white",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled)::before": {
      borderBottomColor: "white",
    },
    "& .MuiInputBase-root": {
      color: "white",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "white",
    },
    "& div.MuiPaper-root": {
      backgroundColor: "#2b2828",
    },
    "& li.MuiButtonBase-root": {
      'background-color': "#2b2828",
    },
    "& svg.MuiSelect-icon": {
      color: "#ffffff",
    }
  },
})(TextField);

export default CustomTextField;
