import User from "../models/User";
import Player from "../models/Player";
import Methodology from "../models/Methodology";
import Item from "../models/Item";
import Document from "../models/Document";
import Wishlist from "../models/Wishlist";
import Note from "../models/Note";
import AudioNote from "../models/AudioNote";
import PlayerClassification from "../models/PlayerClassification";
import PlayerEvaluation from "../models/PlayerEvaluation";
import Category from "../models/Category";
import PlayerItem from "../models/PlayerItem";
import PlayerCriteria from "../models/PlayerCriteria";
import { firestore } from "firebase";
import Badge from "../models/Badge";
import Comment, { IComment } from "../models/Comment";
import { IPlayerWrapper } from "../../ui/components/Player/PlayerBoard/playerList";
import BadgeService from "./badge.service";

interface CompositionJSON {
  name : string,
  index : number,
  image : string,
  archive : string,
  players : any[],
  user : string,
  userId : string
  time : string,
}

export default class ModelFactory {
  // User Firestore
  static CreateUserFromFirestore(userData: any) {
    return new User(
      userData.uid,
      userData.lastName,
      userData.firstName,
      userData.phone ? userData.phone : "",
      userData.isNotified,
      userData.club ? userData.club : "",
      userData.sport ? userData.sport : "",
      userData.parent,
      userData.approved,
      userData.notifications
    );
  }
  static SerializeUserToFirestore(userData: User) {
    return {
      uid: userData.uid,
      lastName: userData.lastName,
      firstName: userData.firstName,
      phone: userData.phone ? userData.phone : "",
      isNotified: userData.isNotified,
      club: userData.club ? userData.club : "",
      sport: userData.sport ? userData.sport : "",
      parent: userData.parent,
      approved: userData.approved,
      notifications: userData.notifications,
    };
  }

  // Player
  static CreatePlayerFromFirestore(playerData: any) {
    const audioNotes: AudioNote[] = [];

    if (playerData.audioNotes !== null && playerData.audioNotes !== undefined) {
      playerData.audioNotes.forEach((a: any) => {
        audioNotes.push(this.CreateAudioNoteFromFirestore(a));
      });
    }

    return new Player(
      playerData.uid,
      playerData.owner ?? "",
      playerData.isUrgent ?? false,
      playerData.requestForLoan ?? false,
      playerData.requestForTransfer ?? false,
      playerData.toWatch ?? false,
      playerData.fileReport ?? false,
      playerData.lastName ?? "",
      playerData.firstName ?? "",
      playerData.injuries ?? "",
      playerData.club ?? "",
      playerData.championship ?? "",
      playerData.citizenship ?? "",
      playerData.placeOfbirth ?? "",
      playerData.position ?? "",
      playerData.sex ?? "male",
      playerData.size ?? "",
      playerData.strongFoot ?? "",
      playerData.weight ?? "",
      playerData.dateOfBirth ?? firestore.Timestamp.now(),
      playerData.agent ?? "",
      playerData.contract ?? "",
      playerData.salary ?? "",
      playerData.marketValue ?? "",
      playerData.avatar ?? "",
      playerData.grade ?? -1,
      audioNotes
    );
  }

  static CreateBadgeFromFirestore(badgeData: any, uid : string) {
    return new Badge(
      badgeData.user,
      badgeData.shape,
      badgeData.color,
      badgeData.name,
      uid
    );
  }

  static SerializeBadgeToFirestore(badgeData: Badge, user : User) {
    return {
      shape: badgeData.shape,
      color: badgeData.color,
      name: badgeData.name,
      user : user.uid,
    };
  }

  static SerializeCommentToFirestore(commentData: IComment) {
    return {
      composition: commentData.id,
      message: commentData.message,
    };
  }

  static CreateCommentFromFirestore(commentData: any) {
    return new Comment(
      commentData.id,
      commentData.message
    );
  }

  static SerializeCompositionToFirestore(composition : any, user : User) : CompositionJSON {
    
    var tmp : any[] = [];
    
    composition.players.forEach((player : any) => {
      var p = { name : player.name, index : player.index, x : player.x, y : player.y, selected : player.selected };
      var selected : { badges : string[], player : string, index : number }[] = [];
      
      player.selected.forEach((element : IPlayerWrapper, count : number) => {
        var badgesList : string[] = [];
        element.badges.forEach((b : Badge) => {
          badgesList.push(b["_uid"]);
        });
        selected.push({ badges : badgesList, player : element.player["_uid"], index : count });
      });

      p.selected = selected;

      tmp.push(p);
    });

    const userName = composition.id === "" ? user.lastName[0] + "." + user.lastName : composition.user;
    const treePath = user.parent === "none" ? composition.userId ? composition.userId : user.uid : user.parent + '_' + user.uid;

    return {
      name : composition.name,
      index : composition.index,
      image : composition.image,
      archive : composition.archive,
      players : tmp,
      user : userName,
      userId : treePath,
      time : firestore.Timestamp.now().toDate().toISOString().split('T')[0].toString()
    };
  }

  static CreatePlayerFromAlgolia(playerData: any) {
    const audioNotes: AudioNote[] = [];

    if (playerData.audioNotes !== null && playerData.audioNotes !== undefined) {
      playerData.audioNotes.forEach((a: any) => {
        audioNotes.push(this.CreateAudioNoteFromFirestore(a));
      });
    }

    return new Player(
      playerData.objectID,
      "",
      playerData.isUrgent ?? false,
      playerData.requestForLoan ?? false,
      playerData.requestForTransfer ?? false,
      playerData.toWatch ?? false,
      playerData.fileReport ?? false,
      playerData.lastName ?? "",
      playerData.firstName ?? "",
      playerData.injuries ?? "",
      playerData.club ?? "",
      playerData.championship ?? "",
      playerData.citizenship ?? "",
      playerData.placeOfbirth ?? "",
      playerData.position ?? "",
      playerData.sex ?? "male",
      playerData.size ?? "",
      playerData.strongFoot ?? "",
      playerData.weight ?? "",
      playerData.dateOfBirth
        ? firestore.Timestamp.fromMillis(playerData.dateOfBirth._seconds * 1000)
        : firestore.Timestamp.now(),
      playerData.agent ?? "",
      playerData.contract ?? "",
      playerData.salary ?? "",
      playerData.marketValue ?? "",
      playerData.avatar ?? "",
      playerData.grade ?? -1,
      audioNotes
    );
  }

  static SerializePlayerToFirestore(playerData: Player) {
    return {
      uid: playerData.uid,
      isUrgent: playerData.isUrgent,
      requestForLoan: playerData.requestForLoan,
      requestForTransfer: playerData.requestForTransfer,
      toWatch: playerData.toWatch,
      fileReport: playerData.fileReport,
      lastName: playerData.lastName,
      firstName: playerData.firstName,
      injuries: playerData.injuries,
      club: playerData.club,
      championship: playerData.championship,
      citizenship: playerData.citizenship,
      placeOfBirth: playerData.placeOfbirth,
      position: playerData.position,
      sex: playerData.sex,
      size: playerData.size,
      strongFoot: playerData.strongFoot,
      weight: playerData.weight,
      dateOfBirth: playerData.dateOfBirth,
      agent: playerData.agent,
      contract: playerData.contract,
      salary: playerData.salary,
      marketValue: playerData.marketValue,
      avatar: playerData.avatar,
      grade: playerData.grade,
      flutterUpdate: true,
    };
  }

  // PlayerClassifications
  static CreatePlayerClassificationFromFirestore(classificationData: any) {
    return new PlayerClassification(
      classificationData.uid,
      classificationData.classifications
    );
  }

  // PlayerEvaluation
  static CreatePlayerEvaluationFromFirestore(playerEvaluationData: any) {
    const categories: Category[] = [];

    playerEvaluationData.categories.forEach((element: any) => {
      categories.push(this.CreateCategoryFromFirestore(element));
    });

    return new PlayerEvaluation(playerEvaluationData.uid, categories, playerEvaluationData.ownerName, playerEvaluationData.createdAt, playerEvaluationData.grade ?? -1,
      );
  }
  static CreateCategoryFromFirestore(categoryData: any) {
    const items: PlayerItem[] = [];

    categoryData.items.forEach((element: any) => {
      items.push(this.CreatePlayerItemFromFirestore(element));
    });

    return new Category(categoryData.type, items);
  }
  static CreatePlayerItemFromFirestore(playerItemData: any) {
    const criterias: PlayerCriteria[] = [];

    playerItemData.criterias.forEach((element: any) => {
      criterias.push(this.CreatePlayerCriteriaFromFirestore(element));
    });

    return new PlayerItem(playerItemData.title, criterias);
  }
  static CreatePlayerCriteriaFromFirestore(playerCriteriaData: any) {
    return new PlayerCriteria(
      playerCriteriaData.grade,
      playerCriteriaData.title,
      playerCriteriaData.textNote,
      playerCriteriaData.audioNote
    );
  }

  // PlayerAudioNotes
  static CreateAudioNoteFromFirestore(audioData: any) {
    return new AudioNote(
      audioData.owner,
      audioData.ownerFullName,
      audioData.createdAt,
      audioData.path
    );
  }

  // PlayerNote
  static CreateNoteFromFirestore(noteData: any) {
    return new Note(
      noteData.uid,
      noteData.owner,
      noteData.ownerName,
      noteData.createdAt,
      noteData.text,
    );
  }

  // PlayerDocument
  static CreateDocumentFromFirestore(documentData: any) {
    return new Document(
      documentData.uid,
      documentData.realName
    );
  }

  // Methodology
  static CreateMethodologyFromFirestore(methodologyData: any) {
    return new Methodology(
      methodologyData.uid,
      methodologyData.name,
      methodologyData.owner,
      methodologyData.tactic,
      methodologyData.technic,
      methodologyData.athletic,
      methodologyData.mind,
      methodologyData.classification
    );
  }
  static SerializeMethodologyToFirestore(methodologyData: Methodology) {
    let tacticItems: any[] = [];
    methodologyData.tactic.forEach((m) =>
      tacticItems.push(this.SerializeItemToFirestore(m))
    );

    let technicItems: any[] = [];
    methodologyData.technic.forEach((m) =>
      technicItems.push(this.SerializeItemToFirestore(m))
    );

    let athleticItems: any[] = [];
    methodologyData.athletic.forEach((m) =>
      athleticItems.push(this.SerializeItemToFirestore(m))
    );

    let mindItems: any[] = [];
    methodologyData.mind.forEach((m) =>
      mindItems.push(this.SerializeItemToFirestore(m))
    );

    let classificationItems: any[] = [];
    methodologyData.classification.forEach((m) =>
      classificationItems.push(this.SerializeItemToFirestore(m))
    );

    return {
      uid: methodologyData.uid,
      name: methodologyData.name,
      owner: methodologyData.owner,
      tactic: tacticItems,
      technic: technicItems,
      athletic: athleticItems,
      mind: mindItems,
      classification: classificationItems,
    };
  }

  // Item
  static CreateItemFromFirestore(itemData: any) {
    return new Item(itemData.title, itemData.criteria);
  }
  static SerializeItemToFirestore(itemData: Item) {
    return {
      title: itemData.title,
      criteria: itemData.criteria,
    };
  }

  // Wishlist
  static CreateWishlistFromFirestore(wishlistData: any) {
    return new Wishlist(
      wishlistData.uid,
      wishlistData.name,
      wishlistData.description,
      wishlistData.players,
      wishlistData.user
    );
  }
  static SerializeWishlistToFirestore(wishlistData: Wishlist) {
    return {
      uid: wishlistData.uid,
      name: wishlistData.name,
      description: wishlistData.description,
      players: wishlistData.players,
      user: wishlistData.user,
    };
  }
}
