import React from "react";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  DialogTitle,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";

type ContactUsProps = {
  open: boolean;
  handleClose: (event: any) => void;
};

export const ContactUs = ({ open, handleClose }: ContactUsProps) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle style={{ color: "black" }}>
        {t("contactUs.contactUs")}
      </DialogTitle>
      <DialogContent>
        <a href="mailto:contact@invister.com">contact@invister.com</a>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} style={{ color: "black" }}>
          {t("contactUs.close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
