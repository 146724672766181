import React from "react";
export const Cgvu = () => {
  return <div style={{ width: 1300, margin: 'auto' }}>
    <br />
    <h1 style={{ color: "white", textAlign: "center", }}>CONDITIONS GÉNÉRALES D’UTILISATION & MENTIONS LÉGALES</h1>
    <br />
    <br />
    <p style={{ color: "white", textAlign: "left", }}>L’application mobile INVISTER Scouting App ainsi que la plateforme web www.invister.com</p>
    <p style={{ color: "white", textAlign: "left", }}>(tous deux appelés ci-après la "Plateforme")</p>
    <p style={{ color: "white", textAlign: "left", }}>est une initiative de :</p>
    <p style={{ color: "white", textAlign: "left", }}>INVISTER SAS 
	17 route de Mormes, 32460 LE HOUGA<br />
	Numéro RCS : 883 489 312 R.C.S. Auch<br />
	E-mail : contact@invister.com<br />
	(ci-après "Invister SAS" ou encore le « Vendeur")</p>
  <h3 style={{ color: "white", textAlign: "left", }}>CONDITIONS GÉNÉRALES D’UTILISATION</h3>
  <h4 style={{ color: "white", textAlign: "left", }}>Champ d’application</h4>
  <p style={{ color: "white", textAlign: "left", }}>Les présentes conditions générales d'utilisation (ci-après les "CGU") s'appliquent à toute visite ou utilisation de la Plateforme et de ses informations par un internaute (ci- après "Utilisateur").
En visitant ou utilisant la Plateforme, l'Utilisateur reconnait avoir pris connaissance des présentes CGU et accepte expressément les droits et obligations qui y sont mentionnés.</p>
<p style={{ color: "white", textAlign: "left", }}>Il peut exceptionnellement être dérogé aux dispositions des CGU par un accord écrit. Ces dérogations peuvent consister en la modification, l'ajout ou la suppression des clauses auxquelles elles se rapportent et n'ont aucune incidence sur l'application des autres dispositions des CGU.
Nous nous réservons le droit de modifier nos CGU à tout moment, sans notification préalable, mais nous nous engageons à appliquer les dispositions qui étaient en vigueur au moment où vous avez utilisé notre Plateforme.</p>
<h4 style={{ color: "white", textAlign: "left", }}>2. Plateforme</h4>
<h5 style={{ color: "white", textAlign: "left", }}>a. Accès et navigation</h5>
<p style={{ color: "white", textAlign: "left", }}>Nous prenons toutes les mesures raisonnables et nécessaires pour assurer le bon fonctionnement, la sécurité et l'accessibilité de notre Plateforme. Toutefois, nous ne pouvons pas offrir de garantie d'opérabilité absolue et il faut dès lors considérer nos actions comme étant couvertes par une obligation de moyen.</p>
<p style={{ color: "white", textAlign: "left", }}>Toute utilisation de la Plateforme se fait toujours aux propres risques de l'Utilisateur. Ainsi, nous ne sommes pas responsables des dommages pouvant résulter de possibles dysfonctionnements, interruptions, défauts ou encore d'éléments nuisibles présents sur la Plateforme.</p>
<p style={{ color: "white", textAlign: "left", }}>Nous nous réservons le droit de restreindre l'accès à la Plateforme ou d'interrompre son fonctionnement à tout moment, sans obligation de notification préalable.</p>
<h5 style={{ color: "white", textAlign: "left", }}>b. Contenu</h5>
<p style={{ color: "white", textAlign: "left", }}>Invister SAS détermine en grande partie le contenu de la Plateforme et prend grand soin des informations présentes sur celle-ci. Nous prenons toutes les mesures possibles pour maintenir notre Plateforme aussi complète, précise et à jour que possible, même lorsque les informations présentes sur celle-ci sont fournies par des tiers. Nous nous réservons le droit de modifier, compléter ou supprimer à tout moment la Plateforme et son contenu, sans que sa responsabilité ne puisse être engagée.</p>
<p style={{ color: "white", textAlign: "left", }}>Invister SAS ne peut pas offrir de garantie absolue concernant la qualité de l'information présente sur la Plateforme. Il est donc possible que cette information ne soit pas toujours complète, exacte, suffisamment précise ou à jour. Par conséquent, Invister SAS ne pourra pas être tenue responsable des dommages, directs ou indirects, que l'Utilisateur subirait de par l'information présente sur la Plateforme.</p>
<p style={{ color: "white", textAlign: "left", }}>Si certains contenus de la Plateforme sont en violation avec la loi ou les droits des tiers, ou sont contraires à la morale, nous vous demandons de nous en informer le plus rapidement possible par mail afin que nous puissions prendre des mesures appropriées.</p>
<p style={{ color: "white", textAlign: "left", }}>Tout téléchargement à partir de la Plateforme a toujours lieu aux risques de l'Utilisateur. Invister SAS ne pourra pas être tenue responsable des éventuels dommages, directs ou indirects, découlant de ces téléchargements, tels qu'une perte de données ou un endommagement du système informatique de l'Utilisateur, qui relèvent entièrement et exclusivement de la responsabilité de ce dernier.</p>
<h5 style={{ color: "white", textAlign: "left", }}>c. Services réservés aux Utilisateurs inscrits</h5>
<h6 style={{ color: "white", textAlign: "left", }}>1) Inscription</h6>
<p style={{ color: "white", textAlign: "left", }}>L'accès à certains services est conditionné par l'inscription de l’Utilisateur, et est régit dans un contrat d’utilisation entre l’Utilisateur et Invister SAS.
Ce contrat formalise notamment le cadre d’utilisation de la Plateforme, le coût de la mise à disposition, la durée, et toute autre condition supplémentaire ajouté au contrat.</p>
<p style={{ color: "white", textAlign: "left", }}>L'inscription et l'accès aux services de la Plateforme sont réservés exclusivement aux personnes physiques capables juridiquement, ayant rempli et validé le formulaire d'inscription disponible en ligne sur la Plateforme ainsi que les présentes CGU.</p>
<p style={{ color: "white", textAlign: "left", }}>Lors de son inscription, l'Utilisateur s'engage à fournir des informations exactes, sincères et à jour sur sa personne et son état civil. L'Utilisateur devra en outre procéder à une vérification régulière des données le concernant afin d'en conserver l’exactitude.</p>
<p style={{ color: "white", textAlign: "left", }}>L'Utilisateur doit ainsi fournir impérativement une adresse e-mail valide, sur laquelle la Plateforme lui adressera une confirmation de son inscription à ses services. Une adresse de messagerie électronique ne peut être utilisée plusieurs fois pour s'inscrire aux services.</p>
<p style={{ color: "white", textAlign: "left", }}>Toute communication réalisée par la Plateforme et ses partenaires est en conséquence réputée avoir été réceptionnée et lue par l'Utilisateur. Ce dernier s'engage donc à consulter régulièrement les messages reçus sur cette adresse e- mail et, le cas échéant, à répondre dans un délai raisonnable.
Une seule inscription est admise par personne physique.</p>
<p style={{ color: "white", textAlign: "left", }}>L'Utilisateur se voit attribuer un identifiant lui permettant d'accéder à un espace dont l'accès lui est réservé (ci-après "Espace personnel"), en complément de la saisie de son mot de passe.
Le mot de passe est modifiable en ligne par l’Utilisateur. Le mot de passe est personnel et confidentiel, l'Utilisateur s'engage ainsi à ne pas le communiquer à des tiers.</p>
<p style={{ color: "white", textAlign: "left", }}>Invister SAS se réserve en tout état de cause la possibilité de refuser une demande d'inscription aux services de la Plateforme en cas de non-respect par l'Utilisateur des CGU.</p>
<h6 style={{ color: "white", textAlign: "left", }}>2) Désinscription</h6>
<p style={{ color: "white", textAlign: "left", }}>L'Utilisateur inscrit pourra demander sa désinscription à l’issu de son contrat d’utilisation avec Invister SAS, en tenant compte des termes du contrat d’utilisation rédigé avec Invister SAS. Toute demande désinscription, de résiliation du contrat d’utilisation, de la Plateforme avant la date de fin du contrat d’utilisation, devra faire l’objet d’un préavis de 2 mois. Les conditions et modalités de résiliation du contrat d’utilisation avec Invister SAS devront être inscrit dans ce dernier.</p>
<h5 style={{ color: "white", textAlign: "left", }}>d. Contenu publié par l’Utilisateur</h5>
<p style={{ color: "white", textAlign: "left", }}>L'utilisateur s'engage au respect des lois en vigueur à travers chacune de ses publications sur la Plateforme. Il sera particulièrement attentif aux intérêts des tiers, au contenu à caractère offensant ainsi qu'au contenu susceptible d'être contraire à l'ordre public ou au moeurs.</p>
<p style={{ color: "white", textAlign: "left", }}>L'Utilisateur demeure responsable de tout contenu publié sur la Plateforme.</p>
<p style={{ color: "white", textAlign: "left", }}>La Plateforme peut exercer une modération sur toute publication et refuser la mise en ligne de contenu sans devoir fournir de justification. 
Au même titre, le contenu publié par un Utilisateur pourra être modifié ou supprimé sans motif ni délai.
En publiant sur la Plateforme, l'Utilisateur cède gratuitement et à titre non exclusif à Invister SAS le droit de représenter, reproduire, adapter, modifier, distribuer et diffuser, directement ou indirectement ainsi que sur tout support et dans le monde entier le contenu publié.</p>
<h4 style={{ color: "white", textAlign: "left", }}>3. Liens vers d'autres sites internet</h4>
<p style={{ color: "white", textAlign: "left", }}>La Plateforme pourrait contenir des liens ou hyperliens renvoyant vers des sites internet externes. De tels liens ne signifient pas de manière automatique qu'il existe une relation entre Invister SAS et le site internet externe ou même qu'un accord implicite existe avec le contenu de ces sites externes.</p>
<p style={{ color: "white", textAlign: "left", }}>Invister SAS n'exerce aucun contrôle sur les sites internet externes. Nous ne sommes donc pas responsables du fonctionnement sûr et correct des hyperliens et de leur destination finale. Dès l'instant où l'Utilisateur clique sur l'hyperlien, il quitte la Plateforme. Nous ne pouvons dès lors pas être tenus responsables en cas de dommage ultérieur.</p>
<h4 style={{ color: "white", textAlign: "left", }}>4. Propriété intellectuelle</h4>
<p style={{ color: "white", textAlign: "left", }}>La structuration de la Plateforme, mais aussi les textes, graphiques, images, photographies, sons, vidéos, bases de données, applications informatiques, etc. qui le composent ou qui sont accessibles via la Plateforme sont la propriété de l'éditeur et sont protégés comme tels par les lois en vigueur au titre de la propriété intellectuelle.</p>
<p style={{ color: "white", textAlign: "left", }}>Toute représentation, reproduction, adaptation ou exploitation partielle ou totale des contenus, marques déposées et services proposés par la Plateforme, par quelque procédé que ce soit, sans l'autorisation préalable, expresse et écrite de l'éditeur, est strictement interdite, à l'exception des éléments expressément désignés comme libres de droits sur la Plateforme.</p>
<p style={{ color: "white", textAlign: "left", }}>L'Utilisateur de la Plateforme se voit octroyer un droit limité d'accès, d'utilisation et d'affichage de la Plateforme et de son contenu. Ce droit est accordé à titre non exclusif, non transférable et ne peut être utilisé que moyennant un usage personnel et non commercial. Sauf accord préalable et écrit, les Utilisateurs ne sont pas autorisés à modifier, reproduire, traduire, distribuer, vendre, communiquer au public, en tout ou en partie, les éléments protégés.</p>
<p style={{ color: "white", textAlign: "left", }}>Il est interdit à l'Utilisateur d'introduire des données sur la Plateforme qui modifieraient ou qui seraient susceptibles d'en modifier le contenu ou l’apparence.</p>
<h4 style={{ color: "white", textAlign: "left", }}>5. Protection des données personnelles.</h4>
<p style={{ color: "white", textAlign: "left", }}>Les données personnelles fournies par l'Utilisateur lors de sa visite ou de l'utilisation de la Plateforme sont collectées et traitées par Invister SAS exclusivement à des fins internes. Invister SAS assure à ses utilisateurs qu'elle attache la plus grande importance à la protection de leur vie privée et de leurs données personnelles, et qu'elle s'engage toujours à communiquer de manière claire et transparente sur ce point.</p>
<p style={{ color: "white", textAlign: "left", }}>Invister SAS s'engage à respecter la législation applicable en la matière, à savoir la Loi du 8 décembre 1992 relative à la protection de la vie privée à l'égard des traitements de données à caractère personnel ainsi que le Règlement européen du 27 avril 2016 relatif à la protection des personnes physiques à l'égard du traitement des données à caractère personnel et à la libre circulation de ces données.</p>
<h4 style={{ color: "white", textAlign: "left", }}>6. Loi applicable et juridiction compétente</h4>
<p style={{ color: "white", textAlign: "left", }}>Les présentes CGU sont régies par le droit français.
En cas de différend et à défaut d'accord amiable, le litige sera porté devant les tribunaux de l'arrondissement judiciaire où Invister SAS a son siège social.</p>
<h4 style={{ color: "white", textAlign: "left", }}>7. Dispositions générales</h4>
<p style={{ color: "white", textAlign: "left", }}>Invister SAS se réserve la possibilité de modifier, étendre, supprimer, limiter ou interrompre la Plateforme et les services associés à tout moment, sans notification préalable, et sans engager sa responsabilité.</p>
<p style={{ color: "white", textAlign: "left", }}>En cas de violation des CGU par l'Utilisateur, Invister SAS se réserve le droit de prendre des mesures de sanction et de réparation adaptées. Invister SAS se réserve notamment le droit de refuser à l'Utilisateur tout accès à la Plateforme ou à nos services temporairement ou définitivement. Ces mesures peuvent être prises sans indication de motif et sans préavis. Elles ne peuvent pas engager la responsabilité de Invister SAS ou donner lieu à une quelconque forme d’indemnisation.</p>
<p style={{ color: "white", textAlign: "left", }}>L'illégalité ou la nullité totale ou partielle d'une disposition de nos CGU n'aura aucun impact sur la validité et l'application des autres dispositions. Nous disposons, dans un tel cas, du droit de remplacer la disposition par une autre disposition valable et de portée similaire.</p>

  </div>;
};
