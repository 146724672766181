
import React from "react";

import Badge from "../../../core/models/Badge";

import { ReactComponent as Square } from '../../../assets/square.svg';
import { ReactComponent as Triangle } from '../../../assets/bleach.svg';
import { ReactComponent as Circle } from '../../../assets/dry-clean.svg';
import { ReactComponent as Diamond } from '../../../assets/diamonds.svg';

export default class BadgesFactory
{
    static CreateBadgeFromObject(badge : Badge) : JSX.Element | null
    {
        if (badge)
        {
            switch (badge["_shape"])
            {
                case "square" : return (<Square style={{ fill : badge["_color"] }}/>);
                case "triangle" : return (<Triangle style={{ fill : badge["_color"] }}/>);
                case "circle" : return (<Circle style={{ fill : badge["_color"] }}/>);
                case "diamond" : return (<Diamond style={{ fill : badge["_color"] }}/>);
            }
        }

        return (null);
    }
}