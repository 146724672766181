import React from "react";

import algoliasearch from "algoliasearch/lite";
import { InstantSearch } from "react-instantsearch-dom";
import "instantsearch.css/themes/algolia.css";
import { CustomSearchBox } from "./SearchBox";

export default function SearchBar() {
  const searchClient = algoliasearch(
    "V5ECK0SXTE",
    "c49983a9c23df7eee3b1f01a9b58caf4"
  );

  return (
    <InstantSearch searchClient={searchClient} indexName="players">
      <CustomSearchBox />
    </InstantSearch>
  );
}
