export default class Item {
  private _title: string;
  private _criteria: string[];

  constructor(title: string, criteria: string[]) {
    this._title = title;
    this._criteria = criteria;
  }

  get title(): string {
    return this._title;
  }

  set title(value: string) {
    this._title = value;
  }

  get criteria(): string[] {
    return this._criteria;
  }

  set criteria(value: string[]) {
    this._criteria = value;
  }
}
