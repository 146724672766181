export default class ColorService {
  public static getColorFromGrade(grade: number): string {
    if (grade === null) return "#dee2e6";

    if (grade < 0) {
      return "#dee2e6";
    } else if (grade <= 1) {
      return "#E7432A";
    } else if (grade <= 2) {
      return "#F2A73D";
    } else if (grade <= 3) {
      return "#FEF753";
    } else if (grade <= 4) {
      return "#61AE5A";
    } else {
      return "#76FC4E";
    }
  }
}
