import {
  connectSearchBox,
  connectHits,
  Configure,
} from "react-instantsearch-dom";
import React, { useState, useContext, Fragment } from "react";
import { Search } from "@material-ui/icons";
import {
  InputBase,
  makeStyles,
  Theme,
  createStyles,
  fade,
  Typography,
} from "@material-ui/core";
import "./SearchBox.css";
import Player from "../../../core/models/Player";
import ModelFactory from "../../../core/service/model.factory";
import { useHistory } from "react-router-dom";
import { UserContext } from "../../../core/providers/UserProvider";
import moment from "moment";
import { Avatar } from "../Avatar/Avatar";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    search: {
      position: "relative",
      // borderRadius: 20,
      borderTopLeftRadius: 20,
      borderTopRightRadius: 20,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      "&:hover": {
        backgroundColor: fade(theme.palette.common.white, 0.25),
      },
      marginRight: theme.spacing(2),
      marginLeft: 0,
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(3),
        width: "20%",
      },
      textAlign: "left",
      display: "inline-block",
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    inputRoot: {
      color: "inherit",
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("md")]: {
        width: "20ch",
      },
    },
  })
);

function SearchBox({ currentRefinement, isSearchStalled, refine }: any) {
  const { t } = useTranslation();

  const classes = useStyles();
  const me = useContext(UserContext);

  const [displayHits, setDisplayHits] = useState(false);

  if (!me) return <Fragment />;

  return (
    <div
      className={classes.search}
      style={{
        borderBottomLeftRadius: displayHits ? 0 : 20,
        borderBottomRightRadius: displayHits ? 0 : 20,
      }}
      onBlur={(event) => {
        setDisplayHits(false);
        refine("");
      }}
      onFocus={() => {
        setDisplayHits(true);
      }}
    >
      <div className={classes.searchIcon}>
        <Search />
      </div>
      <InputBase
        placeholder={t("searchBox.search")}
        fullWidth
        type="search"
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        value={currentRefinement}
        onChange={(event) => {
          return refine(event.currentTarget.value);
        }}
      />
      <div
        className="search-content"
        style={{ display: displayHits ? "block" : "none" }}
      >
        <Configure filters={`owner: ${me.uid}`} hitsPerPage={5} />
        <CustomHits />
      </div>
    </div>
  );
}

export const CustomSearchBox = connectSearchBox(SearchBox);

const Hits = ({ hits }: any) => {
  const { t } = useTranslation();

  let history = useHistory();

  return (
    <div className="d-flex flex-column px-4">
      {hits.map((p: any, i: number) => {
        const player: Player = ModelFactory.CreatePlayerFromAlgolia(p);
        const borderClass = hits.length - 1 !== i ? "border-bottom" : "";

        let age: number;
        if (player.dateOfBirth) {
          const date = player.dateOfBirth.toDate();
          age = moment().diff(date, "years");
        } else {
          age = 0;
        }

        return (
          <div
            key={player.uid}
            className={`d-flex flex-row p-3 ${borderClass}`}
            onMouseDown={(event) => {
              history.push(`/player/${player.uid}/identity`);
            }}
            style={{ cursor: "pointer" }}
          >
            <Avatar player={player} />

            <div className="d-flex flex-column justify-content-around ml-3">
              <Typography
                style={{ fontWeight: "bold" }}
                variant="body1"
                color="textPrimary"
              >
                {player.firstName + " " + player.lastName}
              </Typography>
              <Typography variant="body1" color="textPrimary">
                {age} {t("hits.years")}
              </Typography>
              <Typography variant="body1" color="textPrimary">
                {player.club === "" ? "\u00A0" : player.club}
              </Typography>
            </div>
          </div>
        );
      })}
    </div>
  );
};

const CustomHits = connectHits(Hits);
