import React, { useState, useRef } from "react";
import Player from "../../../../core/models/Player";
import { Divider, Typography, IconButton } from "@material-ui/core";
import { PlayCircleOutline, PauseCircleOutline } from "@material-ui/icons";
import AudioService from "../../../../core/service/audio.service";
import AudioNote from "../../../../core/models/AudioNote";
import { useTranslation } from "react-i18next";

type PlayerAudiosPros = {
  player: Player | null;
};

function PlayerAudioList({ player }: PlayerAudiosPros) {
  const { t } = useTranslation();

  const audioService = useRef(new AudioService());
  const [audioPlaying, setAudioPlaying] = useState<AudioNote | null>(null);

  const handleClickAudioNote = (note: AudioNote) => {
    if (audioPlaying !== note) playAudioNote(note);
    else stopAudioNote(note);
  };

  const playAudioNote = (note: AudioNote) => {
    audioService.current.play(note);
    setAudioPlaying(note);
  };

  const stopAudioNote = (note: AudioNote) => {
    audioService.current.stop(note);
    setAudioPlaying(null);
  };

  if (player?.audioNotes.length === 0) {
    return (
      <Typography
        className="pt-3 justify-content-start align-items-start"
        color="textPrimary"
      >
        {t("playerAudioList.noAudio")}
      </Typography>
    );
  }

  return (
    <div className="d-flex flex-column align-items-start p-5">
      {player?.audioNotes.map((note) => {
        return (
          <div className="p-3" key={note.createdAt.toMillis()}>
            <div
              className="d-flex align-items-center"
              style={{ cursor: "pointer" }}
              onClick={() => handleClickAudioNote(note)}
            >
              <Typography color="textPrimary">
                {t("playerAudioList.report")}&nbsp; {note.createdAt.toDate().toLocaleDateString()} -{" "}
                {note.createdAt.toDate().toLocaleTimeString()} -{" "}
                {t("playerAudioList.by")} &nbsp;{note.ownerFullName}
              </Typography>
              <IconButton aria-label="delete" className="pl-4">
                {audioPlaying === note ? (
                  <PauseCircleOutline style={{ color: "white" }} />
                ) : (
                  <PlayCircleOutline style={{ color: "white" }} />
                )}
              </IconButton>
            </div>
            <Divider light />
          </div>
        );
      })}
    </div>
  );
}

export default PlayerAudioList;
