import React, { Fragment, useMemo, useCallback, useRef, useState, useEffect } from "react";
import Player from "../../../../core/models/Player";
import Document from "../../../../core/models/Document";
import { useTranslation } from "react-i18next";
import {useDropzone} from "react-dropzone";
import DocumentService from "../../../../core/service/document.service";
import ModelFactory from "../../../../core/service/model.factory";
import PlayerDocument from "./PlayerDocument";
import { firestore } from "firebase";

type PlayerDocumentsPros = {
    player: Player | null;
};

const baseStyle = {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#212529',
    color: '#fafafa',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    cursor: "pointer",
  };
  
  const activeStyle = {
    borderColor: '#2196f3'
  };
  
  const acceptStyle = {
    borderColor: '#00e676'
  };
  
  const rejectStyle = {
    borderColor: '#ff1744'
  };
  
function PlayerDocuments({ player }: PlayerDocumentsPros) {
    const { t } = useTranslation();
    const [documents, setDocuments] = useState<Document[]>([]);
    const documentService = useRef(new DocumentService());

    useEffect(() => {
        let unsubscribe = firestore()
          .collection("players/" + player?.uid + "/attachments")
          .onSnapshot((data) => {
            let d: Document[] = [];

            data.forEach((doc) => {
              let document: any = doc.data();
              document.uid = doc.id;
              d.push(ModelFactory.CreateDocumentFromFirestore(document));
            });
    
            setDocuments([...d]);
          });
    
        return () => {
          unsubscribe();
        };
      }, [player]);

    const onDrop = useCallback((acceptedFiles) => {
        documentService.current.upload(acceptedFiles, player);
    }, [player]);

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject
    } = useDropzone({ onDrop });

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
      }), [
        isDragActive,
        isDragReject,
        isDragAccept
      ]);
  
    if (player === null) {
      return <Fragment />;
    }  


    return <div className="d-flex flex-column justify-content-between p-5">
        <div>
            <section className="container">
              <div {...getRootProps({style})}>
                <input {...getInputProps()} />
                <p>{t("playerDocuments.dropzone")}</p>
              </div>
            </section>
        </div>
        <div className="d-flex flex-row justify-content-start pt-3">
            <div style={{color: "#fafafa"}}>
                {documents.map((document) => {
                    return <PlayerDocument document={document} player={player}></PlayerDocument>
                })}
            </div>
        </div>
    </div>;
}

export default PlayerDocuments;