
export default class FlagService
{
  public static getFlag(country : string)
  {
    switch (country)
    {
        case "France" : return ("fr");
        case "Croatie" : return ("hr");
        case "Argentine" : return ("ar");
        case "Brésil" : return ("br");
        case "Uruguay" : return ("uy");
        case "Slovénie" : return ("sl");
        case "Japon" : return ("jp");
        case "Sénégal" : return ("sn");
        case "Ghana" : return ("gh");
        case "Mozambique" : return ("mz");
        case "Albanie" : return ("al");
        case "Tunisie" : return ("tn");
        case "Mozambique" : return ("mz");
        case "Burkina Faso" : return ("bf");
        case "Colombie" : return ("co");
        case "Italie" : return ("it");
        case "Suisse" : return ("ch");
        case "États-Unis" : return ("us");
        default : return ("Error");
    }
  }
}
