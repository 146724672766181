import './badgeItem.css';

import React from "react";
import Badge from '../../../core/models/Badge';
import BadgesFactory from '../../pages/BoardPage/badges.factory';

type BadgesItemProps = {
  badge : Badge,
  deleteBadges : Function
}
  
const BadgeItem = (props : BadgesItemProps) => {
  return (
    <div className="badges-item-wrapper">
      { BadgesFactory.CreateBadgeFromObject(props.badge) }
      <div className="test-item">
        <p>{ props.badge["_name"] }</p>
      </div>
      <button onClick={ () => { props.deleteBadges(props.badge) }}>X</button>
    </div>
  );
}

export default BadgeItem;