import './popupAdd.css';

import React, { useContext, useEffect, useRef, useState } from "react";
import { firestore } from 'firebase';
import Player from '../../../core/models/Player';
import ModelFactory from '../../../core/service/model.factory';

import PlayerAddItem from '../../components/Player/PlayerBoard/playerAddItem';
import { SearchBoxTest } from './searchBar';
import algoliasearch from 'algoliasearch';
import { InstantSearch, Hits, Configure } from 'react-instantsearch-dom';

import Add from '../../../assets/addPlayer.png';
import Checked from '../../../assets/checked.png';
import { StoreUserContext } from '../../../core/providers/StoreUserProvider';

type PlayerListProps = {
  who : string,
  handleAddPlayer : Function,
  setOpen : Function
}

const PopupAdd = (props : PlayerListProps) => {

  const [players, setPlayers] = useState<Player[]>([]);
  const [list, addToList] = useState<Player[]>([]);

  const [last, setLast] = useState<any>();

  const dummy = useRef<any>();

  const user = useContext(StoreUserContext);
  
  const searchClient = algoliasearch(
    "V5ECK0SXTE",
    "c49983a9c23df7eee3b1f01a9b58caf4"
  );

  useEffect(() => {

    if (!user) return;

    const treePath = user.parent === "none" ? `${user.uid}` : `${user.uid}_${user.parent}`;

    firestore().collection("players").where("owner", ">=", treePath).where("owner", "<=", treePath + "~").limit(15).get().then(async (data) => {

      let p: Player[] = [];

      const docs = data.docs.map(doc => doc.data());

      for (var test : number = 0; test < docs.length; test++)
      {
        let player : any = docs[test];
        player.uid = data.docs[test].id;

        let tmp : Player = ModelFactory.CreatePlayerFromFirestore(player);

        if (tmp.position)
        {
          await firestore().collection("methodologies").doc(tmp.position).get().then((snap) => {
            let result : any = snap.data();

            if (result)
            {
              tmp.post = result.name;
            }

            p.push(tmp);
          });
        }
        else
        {
          p.push(tmp);
        }

      }

      const lastDoc = data.docs[data.docs.length - 1];

      setPlayers(p);
      setLast(lastDoc);
    });

  }, []);

  function fetchMore()
  {
    if (!user || dummy.current === null) return;

    const treePath = user!.parent === "none" ? `${user!.uid}` : `${user!.parent}_${user!.uid}`;

    firestore().collection("players").where("owner", ">=", treePath).where("owner", "<=", treePath + "~").limit(15).startAfter(last).get().then(async (data) => {

      let p: Player[] = [];

      const docs = data.docs.map(doc => doc.data());

      for (var test : number = 0; test < docs.length; test++)
      {
        let player : any = docs[test];
        player.uid = data.docs[test].id;

        let tmp : Player = ModelFactory.CreatePlayerFromFirestore(player);

        if (tmp.position)
        {
          await firestore().collection("methodologies").doc(tmp.position).get().then((snap) => {
            let result : any = snap.data();
            
            if (result)
            {
              tmp.post = result.name;
            }

            p.push(tmp);
          });
        }
        else
        {
          p.push(tmp);
        }
      }

      const lastDoc = data.docs[data.docs.length - 1];

      setPlayers((players) => [...players, ...p]);
      setLast(lastDoc);
      
      dummy.current.scrollIntoView({ behavior: 'smooth' });
    });
  }

  useEffect(() => {

    document.addEventListener("click", handleClose);

    return () => { document.removeEventListener("click", handleClose); }
  });

  function handleClose(event : any)
  {
    event.preventDefault();
    
    var element = event.target;
    var parents : string[] = [];

    for ( ; element && element !== document; element = element.parentNode)
    {
      parents.push(element.className);
    }

    if (parents.indexOf("popup-add-wrapper") !== -1)
    {
      return;
    }

    props.setOpen(false);

    event.preventDefault();
  }
  
  function handleAddButton()
  {
    if (list.length !== 0)
    {
      props.handleAddPlayer(list);
      props.setOpen(false);
    }
  }

  function handleAddHit(player : Player)
  {
    const find = list.find(item => item.uid === player.uid);

    if (find)
    {
      addToList(list.filter((item : Player) => item.uid !== player.uid));
    }
    else
    {
      addToList((list : Player[]) => [...list, player]);
    }
  }

  const Hit = ({ hit } : any) => {

    const player: Player = ModelFactory.CreatePlayerFromAlgolia(hit);
    const find = list.find(item => item.uid === player.uid);
    
    return (
      <div className="custom-hits" onClick={ () => handleAddHit(player) }>
        <img className="custom-hit-profile" src={ player.avatar ? "https://firebasestorage.googleapis.com/v0/b/invister-1efa6.appspot.com/o/avatars%2F" + player.avatar + "?alt=media" : "/unknown.png" } width={30} height={30} />
        { player.lastName }
        <img src={ find ? Checked : Add } width={30} height={30} />
      </div>
    );
  }

  const treePath = user!.parent === "none" ? `${user!.uid}` : `${user!.parent}_${user!.uid}`;

  return (
    
    <div className="popup-add-wrapper">
      <div className="popup-add-header-wrapper">
        <InstantSearch searchClient={searchClient} indexName="players">
          <SearchBoxTest />
          <div className="custom-hits-wrapper">
            <Configure hitsPerPage={4} filters={`owner:${treePath}`} />
            <Hits hitComponent={Hit} />
          </div>
        </InstantSearch>
        <div className="popup-add-button-wrapper">
          <button className={`${ list.length === 0 ? "popup-add-button-disable" : "popup-add-button-enable" }`} onClick={ () => handleAddButton() }>Ajouter</button>
          <button className="popup-close-button" onClick={ () => props.setOpen(false) }>Fermer</button>
        </div>
      </div>
      <div className="popup-player-list-wrapper">
        { players && players.map((player : Player, index : number) => (index === players.length -1) ? <PlayerAddItem key={index} reference={dummy} player={player} addToList={addToList} list={list}/> : <PlayerAddItem reference={null} key={index} player={player} addToList={addToList} list={list}/> ) }
      </div>
      <button className="popup-more-button" onClick={ () => fetchMore() }>More</button>
    </div>
  );
}

export default PopupAdd;