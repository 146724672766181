import React, { useContext, useState, useEffect } from "react";
import {
  Button,
  Typography,
  Fab,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  MenuItem,
} from "@material-ui/core";
import { StoreUserContext } from "../../../core/providers/StoreUserProvider";
import { Formik, ErrorMessage } from "formik";
import { firestore, functions } from "firebase";
import CustomTextField from "../CustomTextField/CustomTextField";
import { Add } from "@material-ui/icons";
import User from "../../../core/models/User";
import ModelFactory from "../../../core/service/model.factory";
import { useTranslation } from "react-i18next";

function ScoutManagement() {
  const { t } = useTranslation();

  const fsUser = useContext(StoreUserContext);

  const [open, setOpen] = useState(false);
  const [users, setUsers] = useState<User[]>([]);

  useEffect(() => {
    if (!fsUser) return;

    let unsubscribe = firestore()
      .collection("users")
      .where("parent", ">=", fsUser.uid)
      .where("parent", "<=", fsUser.uid + "~")
      .onSnapshot((data) => {
        let u: User[] = [];

        data.forEach((doc) => {
          let user: any = doc.data();
          user.uid = doc.id;
          u.push(ModelFactory.CreateUserFromFirestore(user));
        });

        setUsers([...u]);
      });

    return () => {
      unsubscribe();
    };
  }, [fsUser]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const deleteMember = (user: User) => {
    firestore().collection("users").doc(user.uid).delete();
  };

  if (!fsUser) return <div></div>;

  return (
    <div>
      <div className="d-flex align-items-center">
        <Typography variant="h6" color="textPrimary" align="left">
          {t("scoutManagement.management")}
        </Typography>

        <Fab
          variant="extended"
          className="ml-4"
          size="small"
          style={{
            background: "linear-gradient(#01B3F4, #044CAB)",
            color: "white",
          }}
          onClick={handleClickOpen}
        >
          <Add />
          <span className="ml-1" style={{ fontSize: 12 }}>
            &nbsp;{t("scoutManagement.addMember")}&nbsp;
          </span>
        </Fab>
      </div>

      <div
        className="px-4 py-3 rounded mt-3 float-left col-6"
        style={{ border: "1px solid rgb(50,50,50)" }}
      >
        <div className="d-flex flex-column">
          {users.map((user) => (
            <div
              className="d-flex my-2 justify-content-between align-items-center"
              key={user.uid}
            >
              <Typography variant="body2" color="textPrimary" align="left">
                {user.parent.includes("_") ? "Scout" : "Head Scout"} -{" "}
                {user.firstName + " " + user.lastName}
              </Typography>

              <Typography variant="body2" color="textPrimary">
                {user.approved
                  ? t("scoutManagement.validate")
                  : t("scoutManagement.pending")}
              </Typography>

              <Button
                className="ml-3"
                style={{
                  background: "red",
                  color: "white",
                }}
                onClick={() => deleteMember(user)}
              >
                {t("scoutManagement.delete")}
              </Button>
            </div>
          ))}
        </div>
      </div>

      {/* DIALOG */}

      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            backgroundColor: "black",
            boxShadow: "0px 0px 15px 2px rgba(255,252,255,1)",
          },
        }}
      >
        <Formik
          initialValues={{
            lastName: "",
            firstName: "",
            email: "",
            supervisor: "",
            password: "",
          }}
          validate={(values) => {
            const errors: any = {};
            if (!values.firstName) {
              errors.firstName = t("scoutManagement.mandatory");
            }
            if (!values.lastName) {
              errors.lastName = t("scoutManagement.mandatory");
            }
            if (!values.email) {
              errors.email = t("scoutManagement.mandatory");
            }
            if (!values.supervisor) {
              errors.supervisor = t("scoutManagement.mandatory");
            }
            if (!values.password) {
              errors.password = t("scoutManagement.mandatory");
            }
            return errors;
          }}
          onSubmit={async (values, { setSubmitting }) => {
            const addUser = functions().httpsCallable("addUser");
            addUser({
              firstName: values.firstName,
              lastName: values.lastName,
              parent: values.supervisor,
              email: values.email,
              password: values.password,
            });

            handleClose();
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <DialogTitle>{t("scoutManagement.request")}</DialogTitle>
              <DialogContent className="d-flex flex-column">
                <CustomTextField
                  label={t("scoutManagement.lastName")}
                  type="text"
                  name="lastName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={isSubmitting}
                  value={values.lastName}
                  className="my-2"
                />
                <ErrorMessage
                  name="lastName"
                  render={(msg) => <span style={{ color: "red" }}>{msg}</span>}
                />

                <CustomTextField
                  label={t("scoutManagement.firstName")}
                  type="text"
                  name="firstName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={isSubmitting}
                  value={values.firstName}
                  className="my-2"
                />
                <ErrorMessage
                  name="firstName"
                  render={(msg) => <span style={{ color: "red" }}>{msg}</span>}
                />

                <CustomTextField
                  label={t("scoutManagement.email")}
                  type="email"
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={isSubmitting}
                  value={values.email}
                  className="my-2"
                />
                <ErrorMessage
                  name="email"
                  render={(msg) => <span style={{ color: "red" }}>{msg}</span>}
                />

                <CustomTextField
                  label={t("scoutManagement.password")}
                  type="password"
                  name="password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={isSubmitting}
                  value={values.password}
                  className="my-2"
                />
                <ErrorMessage
                  name="password"
                  render={(msg) => <span style={{ color: "red" }}>{msg}</span>}
                />

                <CustomTextField
                  label={t("scoutManagement.supervisor")}
                  select
                  type="text"
                  name="supervisor"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={isSubmitting}
                  value={values.supervisor}
                  className="my-2"
                >
                  <MenuItem
                    key={fsUser.uid}
                    value={fsUser.uid}
                    style={{ color: "black" }}
                  >
                    {fsUser.firstName + " " + fsUser.lastName}
                  </MenuItem>
                  {users.map((user) =>
                    !user.parent.includes("_") ? ( // it means it's a scout, we don't want to display them
                      <MenuItem
                        key={user.uid}
                        value={fsUser.uid + "_" + user.uid}
                        style={{ color: "black" }}
                      >
                        {user.firstName + " " + user.lastName}
                      </MenuItem>
                    ) : (
                      <div></div>
                    )
                  )}
                </CustomTextField>
                <ErrorMessage
                  name="supervisor"
                  render={(msg) => <span style={{ color: "red" }}>{msg}</span>}
                />
              </DialogContent>
              <DialogActions className="justify-content-center mt-3 mb-4">
                <Button
                  type="submit"
                  variant="contained"
                  style={{
                    background: "linear-gradient(#01B3F4, #044CAB)",
                    color: "white",
                    borderRadius: "30px",
                  }}
                  size="large"
                >
                  <div className="mx-3">
                    {t("scoutManagement.s")}
                    <span className="text-lowercase">
                      {t("scoutManagement.ubmit")}
                    </span>
                  </div>
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
}

export default ScoutManagement;
