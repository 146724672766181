import React, { useState, useEffect } from "react";
import {
  useParams,
  Link,
  useRouteMatch,
  Route,
  Switch,
  NavLink,
} from "react-router-dom";
import Player from "../../../core/models/Player";
import { firestore, storage } from "firebase";
import ModelFactory from "../../../core/service/model.factory";
import ColorService from "../../../core/service/color.service";
import {
  Breadcrumbs,
  MenuItem,
  MenuList,
  Paper,
  Typography,
} from "@material-ui/core";
import PlayerProfile from "../../components/Player/PlayerScaffold/PlayerProfile";
import PlayerActions from "../../components/Player/PlayerScaffold/PlayerActions";
import PlayerIdentity from "../../components/Player/PlayerScaffold/PlayerIdentity";
import moment from "moment";
import PlayerNoteList from "../../components/Player/PlayerScaffold/PlayerNoteList";
import PlayerAudioList from "../../components/Player/PlayerScaffold/PlayerAudioList";
import {
  NavigateBefore,
  SupervisorAccount,
  AccountBox,
} from "@material-ui/icons";
import User from "../../../core/models/User";
import PlayerClassifications from "../../components/Player/PlayerScaffold/PlayerClassifications";
import PlayerEvaluationsList from "../../components/Player/PlayerScaffold/PlayerEvaluationsList";
import { useTranslation } from "react-i18next";
import PlayerDocuments from "../../components/Player/PlayerScaffold/PlayerDocuments";

function PlayerDetail() {
  const { t } = useTranslation();

  let { path, url } = useRouteMatch();
  let { playerId } = useParams();

  const [player, setPlayer] = useState<Player | null>(null);
  const [avatarUrl, setAvatarUrl] = useState<string | null>(null);

  useEffect(() => {
    let unsubscribe = firestore()
      .collection("players")
      .doc(playerId)
      .onSnapshot((doc) => {
        let p: any = doc.data();
        p.uid = doc.id;
        setPlayer(ModelFactory.CreatePlayerFromFirestore(p));
      });

    return () => {
      unsubscribe();
    };
  }, [playerId]);

  const [position, setPosition] = useState("");

  useEffect(() => {
    if (player == null) return;
    if (!player.position) return;
    firestore()
      .collection("methodologies")
      .doc(player.position)
      .get()
      .then((doc) => {
        const data = doc.data();
        if (data) setPosition(data.name);
      });
  }, [player]);

  const [owner, setOwner] = useState<User | null>(null);

  useEffect(() => {
    if (!player) return;
    firestore()
      .collection("users")
      .doc(player.owner.split("_").pop())
      .get()
      .then((doc) => {
        const data = doc.data();
        const user = ModelFactory.CreateUserFromFirestore(data);
        if (data) setOwner(user);
      });
  }, [player]);

  let age: number;
  if (player != null && player.dateOfBirth) {
    const date = player.dateOfBirth.toDate();
    age = moment().diff(date, "years");
  } else {
    age = 0;
  }

  if (
    player !== null &&
    player.avatar !== null &&
    player.avatar !== undefined &&
    player.avatar !== ""
  ) {
    let avatarRef = storage().ref(`avatars/${player.avatar}`);
    avatarRef.getDownloadURL().then((url) => {
      setAvatarUrl(url);
    });
  }

  if (!player || !owner) return <React.Fragment></React.Fragment>;

  return (
    <div
      style={{ backgroundColor: "black", height: "100%" }}
      className="pt-5 pl-5"
    >
      <div className="d-flex flex-row">
        <div className="d-flex flex-column">
          <div>
            <Breadcrumbs aria-label="breadcrumb">
              <Link to="/" style={{ color: "white", textDecoration: "none" }}>
                <NavigateBefore />
                <span>{t("playerPage.myplayers")}</span>
              </Link>
            </Breadcrumbs>
          </div>
          <div className="d-flex flex-row justify-content-between pt-3">
            <div>
              {avatarUrl ? (
                <img
                  src={avatarUrl}
                  width="125"
                  height="125"
                  alt=""
                  style={{ borderRadius: "12px" }}
                ></img>
              ) : (
                <AccountBox
                  style={{ width: "125px", height: "125px", fill: "grey" }}
                ></AccountBox>
              )}
            </div>
            <div className="d-flex flex-column pl-3 align-items-start justify-content-between">
              <div className="d-flex flex-column align-items-start">
                <Typography color="textPrimary" style={{ fontWeight: "bold" }}>
                  {player?.lastName} {player?.firstName}
                </Typography>
                <Typography color="textPrimary">
                  {age} {t("playerPage.years")}
                </Typography>
                <Typography color="textPrimary">{position}</Typography>
                <Typography color="textPrimary">{player?.club}</Typography>
              </div>
              <div className="d-flex">
                {player?.grade !== null &&
                player?.grade !== undefined &&
                player.grade > 0 ? (
                  <React.Fragment>
                    <Typography
                      style={{
                        color: player
                          ? ColorService.getColorFromGrade(player.grade)
                          : "black",
                      }}
                    >
                      {Math.round(player?.grade * 100) / 100}
                    </Typography>
                    <Typography color="textPrimary">/5</Typography>
                  </React.Fragment>
                ) : (
                  <div></div>
                )}
              </div>
            </div>
          </div>
          <div>
            <Paper className="mt-3">
              <MenuList style={{ backgroundColor: "black" }}>
                <MenuItem
                  component={NavLink}
                  to={`${url}/identity`}
                  style={{ color: "white", textDecoration: "none" }}
                  className="border"
                  activeStyle={{
                    fontWeight: "bold",
                    color: "#4d8ecb",
                  }}
                >
                  {t("playerPage.identity")}
                </MenuItem>
                <MenuItem
                  component={NavLink}
                  to={`${url}/classifications`}
                  style={{ color: "white", textDecoration: "none" }}
                  className="border"
                  activeStyle={{
                    fontWeight: "bold",
                    color: "#4d8ecb",
                  }}
                >
                  {t("playerPage.classifications")}
                </MenuItem>
                <MenuItem
                  component={NavLink}
                  to={`${url}/profile`}
                  style={{ color: "white", textDecoration: "none" }}
                  className="border"
                  activeStyle={{
                    fontWeight: "bold",
                    color: "#4d8ecb",
                  }}
                >
                  {t("playerPage.infos")}
                </MenuItem>
                <MenuItem
                  component={NavLink}
                  to={`${url}/actions`}
                  style={{ color: "white", textDecoration: "none" }}
                  className="border"
                  activeStyle={{
                    fontWeight: "bold",
                    color: "#4d8ecb",
                  }}
                >
                  {t("playerPage.actions")}
                </MenuItem>
                <MenuItem
                  component={NavLink}
                  to={`${url}/evaluation`}
                  style={{ color: "white", textDecoration: "none" }}
                  className="border"
                  activeStyle={{
                    fontWeight: "bold",
                    color: "#4d8ecb",
                  }}
                >
                  {t("playerPage.evaluations")}
                </MenuItem>
                <MenuItem
                  component={NavLink}
                  to={`${url}/notes`}
                  style={{ color: "white", textDecoration: "none" }}
                  className="border"
                  activeStyle={{
                    fontWeight: "bold",
                    color: "#4d8ecb",
                  }}
                >
                  {t("playerPage.writtenrapport")}
                </MenuItem>
                <MenuItem
                  component={NavLink}
                  to={`${url}/audios`}
                  style={{ color: "white", textDecoration: "none" }}
                  className="border"
                  activeStyle={{
                    fontWeight: "bold",
                    color: "#4d8ecb",
                  }}
                >
                  {t("playerPage.oralrapport")}
                </MenuItem>
                <MenuItem
                  component={NavLink}
                  to={`${url}/documents`}
                  style={{ color: "white", textDecoration: "none" }}
                  className="border"
                  activeStyle={{
                    fontWeight: "bold",
                    color: "#4d8ecb",
                  }}
                >
                  {t("playerPage.documents")}
                </MenuItem>
              </MenuList>
            </Paper>
          </div>
        </div>

        <div className="d-flex flex-grow-1 flex-column p-5">
          <div className="align-self-start d-flex">
            <Typography color="textPrimary">
              {t("playerPage.followedBy")}:&nbsp;
              {owner ? `${owner.firstName} ${owner.lastName}` : null}
            </Typography>

            <SupervisorAccount
              className="ml-3"
              style={{ color: owner ? hierarchyColor(owner) : "black" }}
            />
          </div>
          <div
            style={{
              backgroundColor: "rgba(43, 40, 40, 0.6)",
              border: "1px solid #707070",
              borderRadius: "0.6rem",
            }}
            className="flex-grow-1 mt-3 float-left"
          >
            <Switch>
              <Route exact path={path}>
                <Typography></Typography>
              </Route>
              <Route path={`${url}/identity`}>
                <PlayerIdentity player={player} />
              </Route>
              <Route path={`${url}/profile`}>
                <PlayerProfile player={player} />
              </Route>
              <Route path={`${url}/actions`}>
                <PlayerActions player={player} />
              </Route>
              <Route path={`${url}/classifications`}>
                <PlayerClassifications player={player} />
              </Route>
              <Route path={`${url}/evaluation`}>
                <PlayerEvaluationsList player={player} />
              </Route>
              <Route path={`${url}/notes`}>
                <PlayerNoteList player={player} />
              </Route>
              <Route path={`${url}/audios`}>
                <PlayerAudioList player={player} />
              </Route>
              <Route path={`${url}/documents`}>
                <PlayerDocuments player={player} />
              </Route>
            </Switch>
          </div>
        </div>
      </div>
    </div>
  );
}

const hierarchyColor = (owner: User) => {
  if (owner.parent.split("_").length === 2) return "green";
  else if (owner.parent === "none") return "red";
  else return "orange";
};

export default PlayerDetail;
