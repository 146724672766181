import React, { useState, useEffect } from "react";

import { Route, Switch, BrowserRouter } from "react-router-dom";
import DashboardPage from "../../pages/Dashboard/Dashboard";
import PrimarySearchAppBar from "../AppBar/AppBar";
import MethodologyPage from "../../pages/Methodology/Methodology";
import Profile from "../../pages/Profile/Profile";
import Admin from "../../pages/Admin/Admin";
import MethodologyDetail from "../../pages/Methodology/MethodologyDetail/MethodologyDetail";
import PlayerDetail from "../../pages/Player/PlayerDetail";
import WishlistDetail from "../../pages/Wishlist/WishlistDetail/WishlistDetail";
import WishlistPage from "../../pages/Wishlist/Wishlist";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Cgvu } from "../Cgvu/Cgvu";
import { CgvuLink } from "../Cgvu/CgvuLink";
import BoardPage from "../../pages/BoardPage/board";

function ScaffoldPage() {
  const { t } = useTranslation();

  const [windowWidth, setWindowWidth] = useState(0);
  const [windowHeight, setWindowHeight] = useState(0);
  
  let resizeWindow = () => {
    setWindowWidth(window.innerWidth);
    setWindowHeight(window.innerHeight);
  };

  useEffect(() => {
    resizeWindow();
    window.addEventListener("resize", resizeWindow);
    return () => window.removeEventListener("resize", resizeWindow);
  }, []);

  if (windowWidth < 1680 || windowHeight < 800) {
    return (
      <div
        className="d-flex flex-column justify-content-center"
        style={{ backgroundColor: "black", height: "100%", width: "100%" }}
      >
        <Typography color="textPrimary" align="center">
          {t("scaffold.screen")}
        </Typography>
      </div>
    );
  }

  return (
    <React.Fragment>
      <BrowserRouter>
        <PrimarySearchAppBar />
        <Switch>
          <Route path="/wishlist/:wishlistId">
            <WishlistDetail />
          </Route>
          <Route path="/wishlist">
            <WishlistPage />
          </Route>
          <Route path="/methodology/:methodologyId">
            <MethodologyDetail />
          </Route>
          <Route path="/methodology">
            <MethodologyPage /> 
          </Route>
          <Route path="/board">
            <BoardPage/>
          </Route>
          <Route path="/player/:playerId" component={PlayerDetail}/>
          <Route path="/profile">
            <Profile />
          </Route>
          <Route path="/admin">
            <Admin />
          </Route>
          <Route path="/cgvu">
            <Cgvu />
          </Route>
          <Route exact path="/">
            <DashboardPage />
          </Route>
        </Switch>
        <CgvuLink />
      </BrowserRouter>
    </React.Fragment>
  );
}

export default ScaffoldPage;
