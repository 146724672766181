import PlayerCriteria from "./PlayerCriteria";

export default class PlayerItem {
    private _title: string;
    private _criterias: PlayerCriteria[];


	constructor(title: string, criterias: PlayerCriteria[]) {
		this._title = title;
		this._criterias = criterias;
	}


    /**
     * Getter title
     * @return {string}
     */
	public get title(): string {
		return this._title;
	}

    /**
     * Setter title
     * @param {string} value
     */
	public set title(value: string) {
		this._title = value;
	}

    /**
     * Getter criterias
     * @return {PlayerCriteria[]}
     */
	public get criterias(): PlayerCriteria[] {
		return this._criterias;
	}

    /**
     * Setter criterias
     * @param {PlayerCriteria[]} value
     */
	public set criterias(value: PlayerCriteria[]) {
		this._criterias = value;
	}

}