import './soccerFieldInput.css';

import React, { useContext, useEffect, useState }  from "react";
import { useTranslation } from 'react-i18next';

import { ReactComponent as PDF } from '../../../assets/pdf.svg';
import { IComposition } from './board';
import PopupPDFForm from './popupPdfForm';
import { BoardContext } from './boardProvider';

type SoccerFieldInputProps = {
  compositioName : string,
  setCompositionName : Function,
  composition : IComposition | null,
  reff : any
}

const SoccerFieldInput = (props : SoccerFieldInputProps) => {

  const [open, setOpen] = useState(false);

  const { setSave, save } = useContext(BoardContext);

  var { t } = useTranslation();

  useEffect(() => {
    
    if (props.composition === null)
    {
      props.setCompositionName("");
    }

  }, [props.composition]);

  function handleChange(event : any)
  {
    props.setCompositionName(event.target.value);

    if (props.composition && save === false)
    {
      setSave(true);
    }
  }

  return (
    <div>
      {( props.composition && props.composition.id !== "") && <PDF id="pdf-button" className="soccer-field-pdf-button" onClick={ () => { setOpen(true) }}/> }
      <input className="soccer-field-input" id="inputt" type="text" value={props.compositioName} placeholder={t("boardPage.name")} onChange={ (e) => { handleChange(e) } }></input>
      { open && <PopupPDFForm reff={props.reff} composition={props.composition} setOpen={setOpen} /> }
    </div>
  );
}

export default SoccerFieldInput;