export default class PlayerCriteria {
    private _grade: number;
    private _title: string;
    private _textNote: string;
    private _audioNote: string;


	constructor(grade: number, title: string, textNote: string, audioNote: string) {
		this._grade = grade;
		this._title = title;
		this._textNote = textNote;
		this._audioNote = audioNote;
	}


    /**
     * Getter grade
     * @return {number}
     */
	public get grade(): number {
		return this._grade;
	}

    /**
     * Setter grade
     * @param {number} value
     */
	public set grade(value: number) {
		this._grade = value;
	}

    /**
     * Getter title
     * @return {string}
     */
	public get title(): string {
		return this._title;
	}

    /**
     * Setter title
     * @param {string} value
     */
	public set title(value: string) {
		this._title = value;
	}

    /**
     * Getter textNote
     * @return {string}
     */
	public get textNote(): string {
		return this._textNote;
	}

    /**
     * Setter textNote
     * @param {string} value
     */
	public set textNote(value: string) {
		this._textNote = value;
	}

    /**
     * Getter audioNote
     * @return {string}
     */
	public get audioNote(): string {
		return this._audioNote;
	}

    /**
     * Setter audioNote
     * @param {string} value
     */
	public set audioNote(value: string) {
		this._audioNote = value;
	}

}