import React, { useEffect, useState } from "react";
import Player from "../../../../core/models/Player";
import { firestore } from "firebase";
import PlayerEvaluation from "../../../../core/models/PlayerEvaluation";
import ModelFactory from "../../../../core/service/model.factory";
import ColorService from "../../../../core/service/color.service";
import { Divider, IconButton, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import PlayerEvaluationPage from "./PlayerEvaluationPage";
import { KeyboardArrowLeft, PictureAsPdf } from "@material-ui/icons";
import PdfService from "../../../../core/service/pdf.service";
import User from "../../../../core/models/User";

type PlayerEvaluationsListPros = {
  player: Player | null;
};

function PlayerEvaluationsList({ player }: PlayerEvaluationsListPros) {
  const { t } = useTranslation();

  const [evaluations, setEvaluations] = useState<PlayerEvaluation[]>([]);
  const [
    selectedEvaluation,
    setSelectedEvaluation,
  ] = useState<PlayerEvaluation | null>(null);

  const [owner, setOwner] = useState<User | null>(null);
  const [position, setPosition] = useState("");

  const handleEvaluationClick = (evaluation: PlayerEvaluation) => {
    setSelectedEvaluation(evaluation);
  };
  const handleBackClick = () => {
    setSelectedEvaluation(null);
  };

  useEffect(() => {
    let unsubscribe = firestore()
      .collection("players/" + player?.uid + "/evaluations")
      .onSnapshot((data) => {
        let pes: PlayerEvaluation[] = [];

        data.forEach((doc) => {
          let evaluation: any = doc.data();
          evaluation.uid = doc.id;
          pes.push(
            ModelFactory.CreatePlayerEvaluationFromFirestore(evaluation)
          );
        });

        setEvaluations([...pes]);
      });

    return () => {
      unsubscribe();
    };
  }, [player]);

  useEffect(() => {
    if (!player) return;
    firestore()
      .collection("users")
      .doc(player.owner.split("_").pop())
      .get()
      .then((doc) => {
        const data = doc.data();
        const user = ModelFactory.CreateUserFromFirestore(data);
        if (data) setOwner(user);
      });
  }, [player]);

  useEffect(() => {
    if (player == null) return;
    if (!player.position) return;
    firestore()
      .collection("methodologies")
      .doc(player.position)
      .get()
      .then((doc) => {
        const data = doc.data();
        if (data) setPosition(data.name);
      });
  }, [player]);

  if (evaluations.length === 0) {
    return (
      <Typography
        className="pt-3 justify-content-start align-items-start"
        color="textPrimary"
      >
        {t("playerEvaluationsList.noEvaluation")}
      </Typography>
    );
  }

  if (selectedEvaluation !== null) {
    return (
      <div className="d-flex flex-column align-items-stretch p-5">
        <div
          className="d-flex flex-row"
          style={{ cursor: "pointer" }}
          onClick={() => {
            handleBackClick();
          }}
        >
          <KeyboardArrowLeft color="secondary" />
          <Typography color="textPrimary">
            {" "}
            {t("playerEvaluationsList.MyEvaluations")}
          </Typography>
        </div>

        <div className="d-flex flex-row">
          {selectedEvaluation?.grade !== null &&
          selectedEvaluation?.grade !== undefined &&
          selectedEvaluation.grade > 0 ? (
            <React.Fragment>
              <Typography color="textPrimary">
                {t("playerEvaluationsList.grade")} :{" "}
              </Typography>
              <Typography
                className="pb-2"
                style={{
                  color: selectedEvaluation
                    ? ColorService.getColorFromGrade(
                        Math.round(selectedEvaluation.grade * 100) / 100
                      )
                    : "black",
                }}
              >
                {Math.round(selectedEvaluation.grade * 100) / 100}
              </Typography>
              <Typography color="textPrimary">/5</Typography>
            </React.Fragment>
          ) : (
            <div></div>
          )}
        </div>

        <PlayerEvaluationPage player={player} evaluation={selectedEvaluation} />
      </div>
    );
  }

  if (!player || !owner) return <div></div>;

  console.log("frfrfrfr")

  return (
    <div className="d-flex flex-column align-items-start p-5">
      {evaluations.map((evaluation) => (
        <div className="p-3" key={evaluation.uid}>
          <div className="d-flex align-items-center">
            <Typography
              style={{ cursor: "pointer" }}
              onClick={() => {
                handleEvaluationClick(evaluation);
              }}
              color="textPrimary"
            >
              {t("playerEvaluationsList.evaluationOf")}&nbsp;
              {evaluation.createdAt.toDate().toLocaleDateString()} -{" "}
              {evaluation.createdAt.toDate().toLocaleTimeString()} -{" "}
              {t("playerEvaluationsList.by")}&nbsp;
              {evaluation.ownerName}
            </Typography>
            <IconButton
              aria-label="editPdf"
              color="secondary"
              className="ml-4"
              onClick={() =>
                PdfService.downloadPdfFromPlayer(player, owner, position, evaluation)
              }
            >
              <PictureAsPdf />
            </IconButton>
          </div>
          <Divider light color="#ffffff" />
        </div>
      ))}
    </div>
  );
}

export default PlayerEvaluationsList;
