import firebase from "firebase";
import AudioNote from "./AudioNote";

export default class Player {
  private _uid: string;

  private _avatarLink : string;
  private _post : string;
  // Ownership
  private _owner: string;

  // Boolean Signals
  private _isUrgent: boolean;
  private _requestForLoan: boolean;
  private _requestForTransfer: boolean;
  private _toWatch: boolean;
  private _fileReport: boolean;

  // Identity
  private _lastName: string;
  private _firstName: string;
  private _injuries: string;
  private _club: string;
  private _championship: string;
  private _citizenship: string;
  private _placeOfbirth: string;
  private _position: string;
  private _sex: string;
  private _size: string;
  private _strongFoot: string;
  private _weight: string;
  private _dateOfBirth: firebase.firestore.Timestamp;

  // Profile
  private _agent: string;
  private _contract: string;
  private _salary: string;
  private _marketValue: string;

  // Avatar
  private _avatar: string;

  //Various
  private _grade: number;
  private _audioNotes: AudioNote[];

  constructor(
    uid: string,
    owner: string,
    isUrgent: boolean,
    requestForLoan: boolean,
    requestForTransfer: boolean,
    toWatch: boolean,
    fileReport: boolean,
    lastName: string,
    firstName: string,
    injuries: string,
    club: string,
    championship: string,
    citizenship: string,
    placeOfbirth: string,
    position: string,
    sex: string,
    size: string,
    strongFoot: string,
    weight: string,
    dateOfBirth: firebase.firestore.Timestamp,
    agent: string,
    contract: string,
    salary: string,
    marketValue: string,
    avatar: string,
    grade: number,
    audioNotes: AudioNote[]
  ) {
    this._uid = uid;
    this._owner = owner;
    this._isUrgent = isUrgent;
    this._requestForLoan = requestForLoan;
    this._requestForTransfer = requestForTransfer;
    this._toWatch = toWatch;
    this._fileReport = fileReport;
    this._lastName = lastName;
    this._firstName = firstName;
    this._injuries = injuries;
    this._club = club;
    this._championship = championship;
    this._citizenship = citizenship;
    this._placeOfbirth = placeOfbirth;
    this._position = position;
    this._sex = sex;
    this._size = size;
    this._strongFoot = strongFoot;
    this._weight = weight;
    this._dateOfBirth = dateOfBirth;
    this._agent = agent;
    this._contract = contract;
    this._salary = salary;
    this._marketValue = marketValue;
    this._avatar = avatar;
    this._grade = grade;
    this._audioNotes = audioNotes;
    this._avatarLink = "";
    this._post = "";
  }

  get uid(): string {
    return this._uid;
  }

  set uid(value: string) {
    this._uid = value;
  }

  get post(): string {
    return this._post;
  }

  set post(value: string) {
    this._post = value;
  }

  get avatarLink(): string {
    return this._avatarLink;
  }

  set avatarLink(value: string) {
    this._avatarLink = value;
  }

  get owner(): string {
    return this._owner;
  }

  set owner(value: string) {
    this._owner = value;
  }

  get isUrgent(): boolean {
    return this._isUrgent;
  }

  set isUrgent(value: boolean) {
    this._isUrgent = value;
  }

  get requestForLoan(): boolean {
    return this._requestForLoan;
  }

  set requestForLoan(value: boolean) {
    this._requestForLoan = value;
  }

  get requestForTransfer(): boolean {
    return this._requestForTransfer;
  }

  set requestForTransfer(value: boolean) {
    this._requestForTransfer = value;
  }

  get toWatch(): boolean {
    return this._toWatch;
  }

  set toWatch(value: boolean) {
    this._toWatch = value;
  }

  get fileReport(): boolean {
    return this._fileReport;
  }

  set fileReport(value: boolean) {
    this._fileReport = value;
  }

  get lastName(): string {
    return this._lastName;
  }

  set lastName(value: string) {
    this._lastName = value;
  }

  get firstName(): string {
    return this._firstName;
  }

  set firstName(value: string) {
    this._firstName = value;
  }

  get injuries(): string {
    return this._injuries;
  }

  set injuries(value: string) {
    this._injuries = value;
  }

  get club(): string {
    return this._club;
  }

  set club(value: string) {
    this._club = value;
  }

  get championship(): string {
    return this._championship;
  }

  set championship(value: string) {
    this._championship = value;
  }

  get citizenship(): string {
    return this._citizenship;
  }

  set citizenship(value: string) {
    this._citizenship = value;
  }

  get placeOfbirth(): string {
    return this._placeOfbirth;
  }

  set placeOfbirth(value: string) {
    this._placeOfbirth = value;
  }

  get position(): string {
    return this._position;
  }

  set position(value: string) {
    this._position = value;
  }

  get sex(): string {
    return this._sex;
  }

  set sex(value: string) {
    this._sex = value;
  }

  get size(): string {
    return this._size;
  }

  set size(value: string) {
    this._size = value;
  }

  get strongFoot(): string {
    return this._strongFoot;
  }

  set strongFoot(value: string) {
    this._strongFoot = value;
  }

  get weight(): string {
    return this._weight;
  }

  set weight(value: string) {
    this._weight = value;
  }

  get dateOfBirth(): firebase.firestore.Timestamp {
    return this._dateOfBirth;
  }

  set dateOfBirth(value: firebase.firestore.Timestamp) {
    this._dateOfBirth = value;
  }

  get agent(): string {
    return this._agent;
  }

  set agent(value: string) {
    this._agent = value;
  }

  get contract(): string {
    return this._contract;
  }

  set contract(value: string) {
    this._contract = value;
  }

  get salary(): string {
    return this._salary;
  }

  set salary(value: string) {
    this._salary = value;
  }

  get marketValue(): string {
    return this._marketValue;
  }

  set marketValue(value: string) {
    this._marketValue = value;
  }

  get avatar(): string {
    return this._avatar;
  }

  set avatar(value: string) {
    this._avatar = value;
  }

  get grade(): number {
    return this._grade;
  }

  set grade(value: number) {
    this._grade = value;
  }

  get audioNotes(): AudioNote[] {
    return this._audioNotes;
  }

  set audioNotes(value: AudioNote[]) {
    this._audioNotes = value;
  }
}
