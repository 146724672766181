import React, { Fragment } from "react";
import Player from "../../../../core/models/Player";
import { Formik } from "formik";
import { firestore } from "firebase";
import CustomTextField from "../../CustomTextField/CustomTextField";
import { Fab } from "@material-ui/core";
import { useTranslation } from "react-i18next";

type PlayerProfilePros = {
  player: Player | null;
};

function PlayerProfile({ player }: PlayerProfilePros) {
  const { t } = useTranslation();

  if (player === null) {
    return <Fragment />;
  }

  return (
    <div>
      <Formik
        enableReinitialize={true}
        initialValues={{
          agent: player.agent,
          contract: player.contract,
          salary: player.salary,
          marketValue: player.marketValue,
        }}
        onSubmit={async (values, { setSubmitting }) => {
          await firestore().collection("players").doc(player?.uid).update({
            agent: values.agent,
            contract: values.contract,
            salary: values.salary,
            marketValue: values.marketValue,
          });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="d-flex flex-column align-items-start">
              <div className="p-4">
                <CustomTextField
                  label={t("playerProfile.agent")}
                  type="text"
                  name="agent"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={isSubmitting}
                  value={values.agent}
                />
              </div>
              <div className="p-4">
                <CustomTextField
                  label={t("playerProfile.contract")}
                  type="text"
                  name="contract"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={isSubmitting}
                  value={values.contract}
                />
              </div>

              <div className="p-4">
                <CustomTextField
                  label={t("playerProfile.salary")}
                  type="text"
                  name="salary"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={isSubmitting}
                  value={values.salary}
                />
              </div>

              <div className="p-4">
                <CustomTextField
                  label={t("playerProfile.marketValue")}
                  type="text"
                  name="marketValue"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={isSubmitting}
                  value={values.marketValue}
                />
              </div>

              <div className="p-4">
                <Fab
                  type="submit"
                  variant="extended"
                  className="ml-4"
                  size="small"
                  style={{
                    background: "linear-gradient(#01B3F4, #044CAB)",
                    color: "white",
                  }}
                >
                  <span style={{ fontSize: 12 }}>
                    &nbsp;{t("playerProfile.save")}&nbsp;
                  </span>
                </Fab>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}

export default PlayerProfile;
