import React, { useState } from "react";
import "./SignupPage.css";
import { Button, Typography, Snackbar } from "@material-ui/core";
import CustomTextField from "../../components/CustomTextField/CustomTextField";
import firebase, { firestore } from "firebase";
import { Link, useHistory } from "react-router-dom";
import { Formik, ErrorMessage } from "formik";
import { Alert } from "@material-ui/lab";
import { ReactComponent as Logo } from "../../../assets/logo_invister_name.svg";
import { ContactUs } from "../../components/ContactUs/ContactUs";
import whiteLogo from "../../../assets/logo_invister_blanc.svg";
import FlagIcon from "../../components/FlagButton/FlagIcon";
import { useTranslation } from "react-i18next";
import { CgvuLink } from "../../components/Cgvu/CgvuLink";

const SignupPage = () => {
  const { t } = useTranslation();

  const auth = firebase.auth();
  const [open, setOpen] = useState(false);
  const [error, setError] = useState("");
  const history = useHistory();

  const [openContactUs, setOpenContactUs] = useState(false);

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <React.Fragment>
      <img src="/LandingPage.png" id="bg" alt=""></img>
      <img src="/landingpage_mobile.png" id="bg_mobile" alt=""></img>
      <div className="mt-8">
        <div className="d-flex flex-row-reverse pt-2 pr-2 pt-sm-4 pr-sm-4 justify-content-between justify-content-sm-start">
          <FlagIcon />
          <Link
            to="/login"
            style={{ color: "white", textDecoration: "none" }}
            className="d-sm-block d-none"
          >
            <Button
              className="ml-2 my-2"
              variant="outlined"
              style={{
                color: "white",
                textTransform: "capitalize",
                border: "1px solid rgba(255, 255, 255, 1)",
                borderRadius: "30px",
              }}
            >
              <div className="mx-3">{t("signup.Se_connecter")}</div>
            </Button>
          </Link>
          <Button
            style={{
              color: "white",
              textTransform: "capitalize",
              textDecoration: "underline",
            }}
            onClick={() => setOpenContactUs(true)}
            className="d-sm-block d-none"
          >
            {t("signup.Contactez-nous")}
          </Button>
          <img
            src={whiteLogo}
            alt=""
            width="170"
            className="d-block d-sm-none pl-4"
          ></img>
        </div>

        <div className="d-flex flex-column">
          <Logo
            style={{
              top: "-3em",
              marginTop: "-1.4rem",
              marginLeft: "-7rem",
            }}
            width="50rem"
            height="5rem"
            className="d-sm-block d-none"
          />

          <p
            className="text-left font-weight-bold d-sm-block d-none"
            style={{
              fontSize: "13px",
              top: "-3em",
              marginTop: "-1.4rem",
              marginLeft: "11.9rem",
            }}
          >
            working for tomorrow
          </p>
        </div>

        <div
          className="d-flex flex-column align-items-sm-end align-items-center"
          style={{ marginTop: "13%" }}
          id="signup_formContainer"
        >
          <Formik
            initialValues={{
              lastName: "",
              firstName: "",
              email: "",
              password: "",
            }}
            validate={(values) => {
              const errors: any = {};
              if (!values.lastName) {
                errors.lastName = "Champs obligatoire";
              }
              if (!values.firstName) {
                errors.firstName = "Champs obligatoire";
              }
              if (!values.email) {
                errors.email = "Champs obligatoire";
              }
              if (!values.password) {
                errors.password = "Champs obligatoire";
              }
              return errors;
            }}
            onSubmit={async (values, { setSubmitting }) => {
              auth
                .createUserWithEmailAndPassword(values.email, values.password)
                .then((user) => {
                  firestore()
                    .collection("users")
                    .doc(user.user?.uid)
                    .set({
                      firstName: values.firstName,
                      lastName: values.lastName,
                      parent: "none",
                    })
                    .then(() => {
                      history.push("/");
                      window.location.reload();
                    });
                })
                .catch((error) => {
                  setError(error.message);
                  setOpen(true);
                });
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit} id="signup_form">
                <div className="d-flex flex-column">
                  <Typography variant="h5" color="textPrimary">
                    {t("signup.Inscription_gratuite")}
                  </Typography>

                  <CustomTextField
                    label={t("signup.Nom*")}
                    type="text"
                    name="lastName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={isSubmitting}
                    value={values.lastName}
                  />
                  <ErrorMessage
                    name="lastName"
                    render={(msg) => (
                      <span style={{ color: "red" }}>{msg}</span>
                    )}
                  />

                  <CustomTextField
                    label={t("signup.Prénom*")}
                    type="text"
                    name="firstName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={isSubmitting}
                    value={values.firstName}
                    className="mt-3"
                  />
                  <ErrorMessage
                    name="firstName"
                    render={(msg) => (
                      <span style={{ color: "red" }}>{msg}</span>
                    )}
                  />

                  <CustomTextField
                    label={t("signup.E-mail*")}
                    type="text"
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={isSubmitting}
                    value={values.email}
                    className="mt-3"
                  />
                  <ErrorMessage
                    name="email"
                    render={(msg) => (
                      <span style={{ color: "red" }}>{msg}</span>
                    )}
                  />

                  <CustomTextField
                    label={t("signup.Mot_de_passe*")}
                    type="text"
                    name="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={isSubmitting}
                    value={values.password}
                    className="mt-3"
                  />
                  <ErrorMessage
                    name="password"
                    render={(msg) => (
                      <span style={{ color: "red" }}>{msg}</span>
                    )}
                  />
                </div>
                <Button
                  type="submit"
                  variant="contained"
                  style={{
                    background: "linear-gradient(#01B3F4, #044CAB)",
                    color: "white",
                    borderRadius: "30px",
                  }}
                  className="mt-4"
                  size="large"
                >
                  <div className="mx-3">{t("signup.Valider_inscription")}</div>
                </Button>
                <Link
                  to="/login"
                  style={{ color: "white", textDecoration: "none" }}
                  className="d-block d-sm-none mt-4"
                >
                  <p>{t("signup.Vous_avez_déjà_un_compte_?")}</p>
                </Link>
              </form>
            )}
          </Formik>
          <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={() => handleClose()} severity="error">
              {error}
            </Alert>
          </Snackbar>
        </div>
      </div>
      <ContactUs
        open={openContactUs}
        handleClose={() => setOpenContactUs(false)}
      ></ContactUs>
      <CgvuLink />
    </React.Fragment>
  );
};
export default SignupPage;
