import React, { useContext } from "react";
import {
  FormControlLabel,
  Switch,
  Button,
  Typography,
  withStyles,
} from "@material-ui/core";
import { StoreUserContext } from "../../../core/providers/StoreUserProvider";
import { Formik } from "formik";
import { firestore } from "firebase";
import CustomTextField from "../CustomTextField/CustomTextField";
import { blue } from "@material-ui/core/colors";
import { useTranslation } from "react-i18next";

const BlueSwitch = withStyles({
  switchBase: {
    //   color: blue[300],
    "&$checked": {
      color: blue[500],
    },
    "&$checked + $track": {
      backgroundColor: blue[500],
    },
  },
  checked: {},
  track: { backgroundColor: "#FFF" },
})(Switch);

function MyAccount() {
  const { t } = useTranslation();

  const fsUser = useContext(StoreUserContext);

  if (!fsUser) return <div></div>;

  return (
    <div className="mb-5">
      <div className="d-flex align-items-center">
        <Typography variant="h6" color="textPrimary" align="left">
          {t("myAccount.myAccount")}
        </Typography>
      </div>

      <div
        className="px-4 pb-4 pt-2 rounded mt-3 float-left col-6"
        style={{ border: "1px solid rgb(50,50,50)" }}
      >
        <Formik
          initialValues={{
            firstname: fsUser.firstName,
            lastname: fsUser.lastName,
            notifications: fsUser.isNotified,
            sport: fsUser.sport,
            club: fsUser.club,
            phoneNumber: fsUser.phone,
          }}
          onSubmit={async (values, { setSubmitting }) => {
            if (!fsUser) return;

            await firestore().collection("users").doc(fsUser.uid).update({
              firstName: values.firstname,
              lastName: values.lastname,
              isNotified: values.notifications,
              sport: values.sport,
              club: values.club,
              phone: values.phoneNumber,
            });
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="d-flex flex-wrap justify-content-between">
                <CustomTextField
                  label={t("myAccount.firstName")}
                  type="text"
                  name="firstname"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={isSubmitting}
                  value={values.firstname}
                  className="col-4 mx-1 my-1"
                />
                <CustomTextField
                  label={t("myAccount.mySport")}
                  type="text"
                  name="sport"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={isSubmitting}
                  value={values.sport}
                  className="col-4 mx-1 my-1"
                />
                <CustomTextField
                  label={t("myAccount.lastName")}
                  type="text"
                  name="lastname"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={isSubmitting}
                  value={values.lastname}
                  className="col-4 mx-1 my-1"
                />
                <CustomTextField
                  label={t("myAccount.myClub")}
                  type="text"
                  name="club"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={isSubmitting}
                  value={values.club}
                  className="col-4 mx-1 my-1"
                />
                <CustomTextField
                  label={t("myAccount.phoneNumber")}
                  type="text"
                  name="phoneNumber"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={isSubmitting}
                  value={values.phoneNumber}
                  className="col-4 mx-1 my-1"
                />
                <div className="col-4 my-3" style={{ color: "white" }}>
                  <FormControlLabel
                    control={
                      <BlueSwitch
                        checked={values.notifications}
                        onChange={handleChange}
                        name="notifications"
                      />
                    }
                    label={t("myAccount.notifications")}
                    className="float-left"
                  />
                </div>
              </div>

              <Button
                type="submit"
                variant="contained"
                style={{
                  background: "linear-gradient(#01B3F4, #044CAB)",
                  color: "white",
                  borderRadius: "30px",
                  float: "right",
                }}
                size="large"
              >
                <div className="mx-3">
                  {t("myAccount.s")}
                  <span className="text-lowercase">{t("myAccount.ave")}</span>
                </div>
              </Button>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default MyAccount;
