import './playerList.css';

import React, { useContext, useEffect, useState } from "react";

import PlayerButtonAdd from './playerButtonAdd';
import useComposition from '../../../../core/utils/useComposition';
import { BoardContext } from '../../../pages/BoardPage/boardProvider';
import PlayerListItem from './playerListItem';
import Player from '../../../../core/models/Player';
import PlayerListContainer from './playerListContainer';
import { IComposition } from '../../../pages/BoardPage/board';
import Badge from '../../../../core/models/Badge';
import { Container, Draggable } from 'react-smooth-dnd';
import { Height } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { firestore } from 'firebase';

type PlayerListProps = {
  composition : IComposition | null,
  setComposition : Function,
}

export interface IPlayerDisplay
{
  age : boolean,
  badges : boolean,
  origine : boolean,
  valeur : boolean
}

export interface IPlayerWrapper
{
  badges : Badge[],
  player : Player,
  index : number,
  display : IPlayerDisplay
}

const PlayerList = (props : PlayerListProps) => {

  var { composition } = useComposition(props.composition);
  var { index, setSave } = useContext(BoardContext);
  var [selectedPlayer, setSelectedPlayer] = useState<IPlayerWrapper | null>(null);

  var { t } = useTranslation();

  useEffect(() => {

    if (selectedPlayer)
    {
      document.addEventListener("click", handleClose);
    }

    return () => { document.removeEventListener("click", handleClose); }
  });

  function handleClose(event : any)
  {
    event.preventDefault();
    
    var element = event.target;
    var parents : string[] = [];

    if (element.tagName === "svg" || element.tagName === "rect" || element.tagName === "path" || element.tagName === "button")
    {
      return;
    }

    for ( ; element && element !== document; element = element.parentNode )
    {
      parents.push(element.className);
    }

    if (parents.indexOf("player-list-wrapper") !== -1)
    {
      return;
    }

    setSelectedPlayer(null);

    event.preventDefault();
  }

  const handleAddPlayer = async (players : Player[]) => {

    var tmp = Object.assign({ }, composition);

    for (var count : number = 0; count < players.length; count++)
    {
      var element : Player = players[count];

      if (tmp.players[index].selected.find((item : IPlayerWrapper) => item.player.uid === element.uid) === undefined)
      {
        var display : IPlayerDisplay = { age : true, badges : true, origine : true, valeur : true };
        var playerWrapper : IPlayerWrapper = { badges : [], player : element, index : count, display };
    
        tmp.players[index].selected.push(playerWrapper);
      }
    }

    setSave(true);

    props.setComposition(tmp);
  }

  const handleRemovePlayer = (player : IPlayerWrapper) => {
    
    var tmp : IComposition = Object.assign({ }, composition);

    tmp.players[index].selected = tmp.players[index].selected.filter((item : IPlayerWrapper) => item.player["_uid"] !== player.player["_uid"]);
    
    props.setComposition(tmp);
  }

  function onDrop(dropResult : any)
  {
    const { removedIndex, addedIndex, payload } = dropResult;

    if (removedIndex === addedIndex)
    {
      return;
    }

    var tmp : IComposition = Object.assign({ }, props.composition);

    const result = [...tmp.players[index].selected];
    let itemToAdd = payload;

    if (removedIndex !== null)
    {
      itemToAdd = result.splice(removedIndex, 1)[0];
    }

    if (addedIndex !== null)
    {
      result.splice(addedIndex, 0, itemToAdd);
    }

    tmp.players[index].selected = result;

    const list : IPlayerWrapper[] = [];

    tmp.players[index].selected.forEach((element : IPlayerWrapper, count : number) => {

      if (count < 3)
      {
        element.index = count;

        list.push(element);
      }

    });

    tmp.players[index].list = list;

    setSave(true);
    props.setComposition(tmp);
  }

  return (
    <div className="player-list-wrapper">
      <div className="player-list-content-wrapper">
        <div className="player-add-wrapper">
          {  index != -1 && <p>{ t("postes." + composition?.players[index].name) }</p> }
          <PlayerButtonAdd who={ index != -1 ? composition?.players[index].name : "" } handleAddPlayer={handleAddPlayer} composition={composition} />
        </div>
        <div className="player-list-scroll">
        <Container style={{ width : "100%", height : "100%", display : "flex", justifyContent : "flex-start", alignItems : "center", flexDirection : "column", overflow : "scroll" }} onDrop={onDrop} behaviour="contain" autoScrollEnabled={true}>
          { index !== -1 && composition?.players[index].selected.map((wrappper : IPlayerWrapper, count : number) => {
            return (
              <Draggable key={count} className="draggable-player-list">
                <PlayerListItem key={index} className={ (selectedPlayer && wrappper["player"]["_uid"] === selectedPlayer["player"]["_uid"]) ? "player-list-item-wrapper-selected" : "" } handleRemovePlayer={handleRemovePlayer} wrapper={wrappper} index={count} setSelectedPlayer={setSelectedPlayer} composition={props.composition} setComposition={props.setComposition} />
              </Draggable>
            );
          })}
        </Container>
        </div>
     </div>
      { selectedPlayer && <PlayerListContainer composition={props.composition} setComposition={props.setComposition} wrapper={selectedPlayer} setSelectedPlayer={setSelectedPlayer}/> }
    </div>
  );
}

export default PlayerList;