import Item from "./Item";

export default class Methodology {
  private _uid: string;

  // Title
  private _name: string;

  // Owner
  private _owner: string;

  // Assessments
  private _tactic: Item[];
  private _technic: Item[];
  private _athletic: Item[];
  private _mind: Item[];

  // Classification
  private _classification: Item[];

  constructor(
    uid: string,
    name: string,
    owner: string,
    tactic: Item[],
    technic: Item[],
    athletic: Item[],
    mind: Item[],
    classification: Item[]
  ) {
    this._uid = uid;
    this._name = name;
    this._owner = owner;
    this._tactic = tactic;
    this._technic = technic;
    this._athletic = athletic;
    this._mind = mind;
    this._classification = classification;
  }

  get uid(): string {
    return this._uid;
  }

  set uid(value: string) {
    this._uid = value;
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get owner(): string {
    return this._owner;
  }

  set owner(value: string) {
    this._owner = value;
  }

  get tactic(): Item[] {
    return this._tactic;
  }

  set tactic(value: Item[]) {
    this._tactic = value;
  }

  get technic(): Item[] {
    return this._technic;
  }

  set technic(value: Item[]) {
    this._technic = value;
  }

  get athletic(): Item[] {
    return this._athletic;
  }

  set athletic(value: Item[]) {
    this._athletic = value;
  }

  get mind(): Item[] {
    return this._mind;
  }

  set mind(value: Item[]) {
    this._mind = value;
  }

  get classification(): Item[] {
    return this._classification;
  }

  set classification(value: Item[]) {
    this._classification = value;
  }
}
