import './archiveList.css';

import React, { useState, useEffect, useContext } from "react";
import { firestore } from 'firebase';
import ArchiveItem from './archiveItem';
import { StoreUserContext } from '../../../core/providers/StoreUserProvider';
import { IComposition } from '../../pages/BoardPage/board';

type ArchiveListProps = {
  setComposition : Function,
  composition  : IComposition | null
}

const ArchiveList = (props : ArchiveListProps) => {

  var [archives, setArchives] = useState<IComposition[] | null>(null);

  const user = useContext(StoreUserContext);

  useEffect(() => {

    if (!user) return;

    const treePath = user.parent === "none" ? `${user.uid}` : `${user.uid}_${user.parent}`;

    firestore().collection("archives").where("userId", ">=", treePath).where("userId", "<=", treePath + "~").get().then((data) => {

      let tmp : IComposition[] = [];

      data.forEach( async (doc) => {
        let composition : any = doc.data();

        composition.id = doc.id;
        tmp.push(composition);
      });

      setArchives(tmp);
    });

  }, []);

  const handleSetComposition = (archive : IComposition) => {
    props.setComposition(archive);
  }

  return (
    <div className="archive-list-wrapper">
      <div className="archive-list-title-wrapper">
        <p>Archives</p>
      </div>
      { archives && archives.map((archive : IComposition, index : number) => <ArchiveItem key={index} setComposition={props.setComposition} handleSetComposition={handleSetComposition} composition={props.composition} archive={archive} setArchives={setArchives} archives={archives} /> ) }
    </div>
  );
}

export default ArchiveList;
