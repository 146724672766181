import React, { createContext, useState, useContext, useEffect } from "react";
import firebase from "firebase";
import User from "../models/User";
import ModelFactory from "../service/model.factory";
import { UserContext } from "./UserProvider";

export const StoreUserContext = createContext<null | User>(null);

function StoreUserProvider(props: any) {
  const [storeUser, setStoreUser] = useState<null | User>(null);

  const user = useContext(UserContext);

  useEffect(() => {
    let unsubscribe: any;
    if (user !== null) {
      unsubscribe = firebase
        .firestore()
        .collection("users")
        .doc(user.uid)
        .onSnapshot((res) => {        
          let userData: any = res.data();
          userData.uid = res.id;
          let user = ModelFactory.CreateUserFromFirestore(userData);
          setStoreUser(user);
        });
    } else {
      if (unsubscribe) unsubscribe();
      setStoreUser(null);
    }
  }, [user]);

  return (
    <StoreUserContext.Provider value={storeUser}>
      {props.children}
    </StoreUserContext.Provider>
  );
}
export default StoreUserProvider;
