import React, { useState, useEffect } from "react";
import Player from "../models/Player";
import { IComposition } from "../../ui/pages/BoardPage/board";

const useComposition = (value : IComposition | null) => {

  var [composition , setComposition] = useState(value);
  
  useEffect(() => {
    setComposition(value);
  }, [value]);
  
  return { composition, setComposition };
}

export default useComposition;