import React, { Fragment } from "react";
import Player from "../../../../core/models/Player";
import { Formik } from "formik";
import { firestore } from "firebase";
import { Fab, Switch, Typography, withStyles } from "@material-ui/core";
import { blue } from "@material-ui/core/colors";
import { useTranslation } from "react-i18next";

type PlayerActionsPros = {
  player: Player | null;
};

function PlayerActions({ player }: PlayerActionsPros) {
  const { t } = useTranslation();

  if (player === null) {
    return <Fragment />;
  }

  return (
    <div>
      <Formik
        enableReinitialize={true}
        initialValues={{
          isUrgent: player.isUrgent,
          requestForLoan: player.requestForLoan,
          requestForTransfer: player.requestForTransfer,
          toWatch: player.toWatch,
          fileReport: player.fileReport,
        }}
        onSubmit={async (values, { setSubmitting }) => {
          await firestore().collection("players").doc(player?.uid).update({
            isUrgent: values.isUrgent,
            requestForLoan: values.requestForLoan,
            requestForTransfer: values.requestForTransfer,
            toWatch: values.toWatch,
            fileReport: values.fileReport,
          });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="d-flex flex-column justify-content-start align-items-baseline">
              <div className="d-flex flex-row justify-content-start">
                <div className="d-flex flex-column justify-content-around align-items-start">
                  <Typography className="p-4" color="textPrimary">
                    {t("playerAction.urgent")}
                  </Typography>
                  <Typography className="p-4" color="textPrimary">
                    {t("playerAction.loan")}
                  </Typography>
                  <Typography className="p-4" color="textPrimary">
                    {t("playerAction.transfer")}
                  </Typography>
                  <Typography className="p-4" color="textPrimary">
                    {t("playerAction.watch")}
                  </Typography>
                  <Typography className="p-4" color="textPrimary">
                    {t("playerAction.report")}
                  </Typography>
                </div>
                <div className="d-flex flex-column justify-content-around align-items-start">
                  <BlueSwitch
                    checked={values.isUrgent}
                    onChange={handleChange}
                    disabled={isSubmitting}
                    name="isUrgent"
                  />

                  <BlueSwitch
                    checked={values.requestForLoan}
                    onChange={handleChange}
                    disabled={isSubmitting}
                    name="requestForLoan"
                  />

                  <BlueSwitch
                    checked={values.requestForTransfer}
                    onChange={handleChange}
                    disabled={isSubmitting}
                    name="requestForTransfer"
                  />

                  <BlueSwitch
                    checked={values.toWatch}
                    onChange={handleChange}
                    disabled={isSubmitting}
                    name="toWatch"
                  />

                  <BlueSwitch
                    checked={values.fileReport}
                    onChange={handleChange}
                    disabled={isSubmitting}
                    name="fileReport"
                  />
                </div>
              </div>
              <div className="p-4">
                <Fab
                  type="submit"
                  variant="extended"
                  className="ml-4"
                  size="small"
                  style={{
                    background: "linear-gradient(#01B3F4, #044CAB)",
                    color: "white",
                  }}
                >
                  <span style={{ fontSize: 12 }}>
                    &nbsp;{t("playerAction.save")}&nbsp;
                  </span>
                </Fab>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}

const BlueSwitch = withStyles({
  switchBase: {
    //   color: blue[300],
    "&$checked": {
      color: blue[500],
    },
    "&$checked + $track": {
      backgroundColor: blue[500],
    },
  },
  checked: {},
  track: { backgroundColor: "#FFF" },
})(Switch);

export default PlayerActions;
