import './playerListItem.css';

import React, { useContext, useState, useEffect } from "react";

import { IPlayerWrapper } from './playerList';
import { IComposition } from '../../../pages/BoardPage/board';
import { BoardContext } from '../../../pages/BoardPage/boardProvider';
import { storage, auth } from 'firebase';
import FlagService from '../../../../core/service/flag.service';
import PlayerService from '../../../../core/service/player.service';

type PlayerListItemProps = {
  wrapper : IPlayerWrapper,
  index : number,
  setSelectedPlayer : Function,
  composition : IComposition | null,
  setComposition : Function,
  handleRemovePlayer : Function,
  className : string
}

const PlayerListItem = (props : PlayerListItemProps) => {
  
  const { index, setSave } = useContext(BoardContext);

  function getGradeImage(grade : number) : string
  {
    switch (grade)
    {
      case 0.5 : return ("/stars/0-5.png");
      case 1 : return ("/stars/1.png");
      case 1.5 : return ("/stars/1-5.png");
      case 2 : return ("/stars/2.png");
      case 2.5 : return ("/stars/2-5.png");
      case 3 : return ("/stars/3.png");
      case 3.5 : return ("/stars/3-5.png");
      case 4 : return ("/stars/4.png");
      case 4.5 : return ("/stars/4-5.png");
      case 5 : return ("/stars/5.png");
    }

    return ("");
  }

  function handleRemovePlayer()
  {
    props.handleRemovePlayer(props.composition?.players[index].selected[props.index]);
    setSave(true);
    props.setSelectedPlayer(null);
  }

  return (
    <div className="player-list-item-wrapper">
      <button className="player-list-item-remove" onClick={ () => { handleRemovePlayer() }}>X</button>
      <div className={`player-list-item-wrapper-box ${ props.className }`} onClick={ () => { props.setSelectedPlayer(props.wrapper) }}>
        <img className="profile" src={ props.wrapper.player["_avatar"] ? "https://firebasestorage.googleapis.com/v0/b/invister-1efa6.appspot.com/o/avatars%2F" + props.wrapper.player!["_avatar"] + "?alt=media" : "/unknown.png" } />
        <div className="wrapper">
          <div className="player-list-item-flag">
            <p style={{ fontSize : 12, color : "white" }}>{ props.wrapper.player!["_lastName"] }</p>
            <img src={FlagService.getFlag(props.wrapper.player!["_citizenship"]) !== "Error" ? "https://www.countryflags.io/" + FlagService.getFlag(props.wrapper.player!["_citizenship"]) + "/flat/24.png" : ""} />
          </div>
          <p style={{ fontSize : 11, color : "white" }}>{ PlayerService.getPlayerAge(props.wrapper) }</p>
          <p style={{ fontSize : 11, color : "white" }}>{ props.wrapper.player!["_post"] === "" ? "N/A" : props.wrapper.player!["_post"] }</p>
          <p style={{ fontSize : 11, color : "white" }}>{ props.wrapper.player!["_club"] === "" ? "N/A" : props.wrapper.player!["_club"] }</p>
        </div>
        <div className="wrapper-right">
          <p style={{ fontSize : 12, color : "#09FF00" }}>{ (props.wrapper.player!["_grade"] >= 0 && props.wrapper.player!["_grade"] <= 5) ? props.wrapper.player!["_grade"] : "N/A" }</p>
          <img className="stars" src={getGradeImage(props.wrapper.player!["_grade"])} />
          <p style={{ fontSize : 12, color : "grey" }}>{ props.wrapper.player!["_marketValue"] === "" ? "N/A" : props.wrapper.player!["_marketValue"] }</p>
        </div>
        <div className="index-player-wrapper">
          { props.index + 1 }
        </div>
      </div>
    </div>
  );
}

export default PlayerListItem;