import React, { useEffect, useState, useContext } from "react";
import {
  Typography,
  IconButton,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@material-ui/core";
import Player from "../../../../core/models/Player";
import { storage, firestore } from "firebase";
import moment from "moment";
import {
  Delete,
  Star,
  Description,
  VolumeUp,
  PersonOutline,
  ArrowForward,
  StarBorder,
  AccountBox,
} from "@material-ui/icons";

import { ReactComponent as Pen } from "../../../../assets/options/pen.svg";
import { ReactComponent as Eye } from "../../../../assets/options/eye.svg";
import { ReactComponent as Question } from "../../../../assets/options/question.svg";
import { ReactComponent as Siren } from "../../../../assets/options/siren.svg";
import { ReactComponent as Transfer } from "../../../../assets/options/transfer.svg";

import Wishlist from "../../../../core/models/Wishlist";
import { Redirect } from "react-router";
import { StoreUserContext } from "../../../../core/providers/StoreUserProvider";
import ColorService from "../../../../core/service/color.service";
import { useTranslation } from "react-i18next";

type PlayerCardProps = {
  player: Player;
  wishlists: Wishlist[];
};

function PlayerCard({ player, wishlists }: PlayerCardProps) {
  const { t } = useTranslation();

  const [avatarUrl, setAvatarUrl] = useState<string | null>(null);
  const [redirect, setRedirect] = useState<boolean>(false);
  const [redirectNotes, setRedirectNotes] = useState<boolean>(false);
  const [redirectAudios, setRedirectAudios] = useState<boolean>(false);
  const [redirectEvaluation, setRedirectEvaluation] = useState<boolean>(false);

  const me = useContext(StoreUserContext);
  const [isNew, setIsNew] = useState(false);

  let age: number;
  if (player.dateOfBirth) {
    const date = player.dateOfBirth.toDate();
    age = moment().diff(date, "years");
  } else {
    age = 0;
  }

  const [borderColor, setBorderColor] = useState("");

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  
  const handleOnClick = () => {
    if (isNew && me) {
      const newPlayers = me.notifications.newPlayers.filter(
        (p: String) => p !== player.uid
      );
      me.notifications.newPlayers = newPlayers;

      firestore()
        .collection("users")
        .doc(me.uid)
        .update({ notifications: me.notifications });
    }
    setRedirect(true);
  };

  const handleAudiosOnClick = () => {
    if (isNew && me) {
      const newPlayers = me.notifications.newPlayers.filter(
        (p: String) => p !== player.uid
      );
      me.notifications.newPlayers = newPlayers;

      firestore()
        .collection("users")
        .doc(me.uid)
        .update({ notifications: me.notifications });
    }
    setRedirectAudios(true);
  };

  const handleNotesOnClick = () => {
    if (isNew && me) {
      const newPlayers = me.notifications.newPlayers.filter(
        (p: String) => p !== player.uid
      );
      me.notifications.newPlayers = newPlayers;

      firestore()
        .collection("users")
        .doc(me.uid)
        .update({ notifications: me.notifications });
    }
    setRedirectNotes(true);
  };

  const handleEvaluationOnClick = () => {
    if (isNew && me) {
      const newPlayers = me.notifications.newPlayers.filter(
        (p: String) => p !== player.uid
      );
      me.notifications.newPlayers = newPlayers;

      firestore()
        .collection("users")
        .doc(me.uid)
        .update({ notifications: me.notifications });
    }
    setRedirectEvaluation(true);
  };

  const [openWishlist, setOpenWishlist] = useState(false);
  const [isFavorite, setIsFavorite] = useState<Wishlist | null>(null);

  const handleCloseWishlist = () => {
    setOpenWishlist(false);
  };

  const handleClickWishlist = () => {
    if (isFavorite) {
      let newPlayers = isFavorite.players.filter((p) => p !== player.uid);
      firestore()
        .collection("wishlists")
        .doc(isFavorite.uid)
        .update({ players: newPlayers });
    } else {
      setOpenWishlist(true);
    }
  };

  useEffect(() => {
    if (!player.avatar) return;
    let avatarRef = storage().ref(`avatars/${player.avatar}`);
    avatarRef.getDownloadURL().then((url) => {
      setAvatarUrl(url);
    });
  }, [player.avatar]);

  useEffect(() => {
    if (!me) return;

    if (me.notifications != null && me.notifications.newPlayers) {
      setIsNew(me.notifications.newPlayers.includes(player.uid));
    }
  }, [me, player.uid]);

  useEffect(() => {
    let wishlist = wishlists.find((wishlist) =>
      wishlist.players.includes(player.uid)
    );

    if (wishlist !== undefined) setIsFavorite(wishlist);
    else setIsFavorite(null);
  }, [wishlists, player.uid]);

  const [position, setPosition] = useState("");

  useEffect(() => {
    if (!player.position) return;
    firestore()
      .collection("methodologies")
      .doc(player.position)
      .get()
      .then((doc) => {
        const data = doc.data();
        if (data) setPosition(data.name);
      });
  }, [player.position]);

  useEffect(() => {
    setBorderColor(ColorService.getColorFromGrade(player.grade));
  }, [player]);

  const handleDeletePlayer = () => {
    firestore().collection("players").doc(player.uid).delete();
  };

  const handleAddPlayerToWishlist = (wishlist: Wishlist) => {
    firestore()
      .collection("wishlists")
      .doc(wishlist.uid)
      .update({ players: [player.uid, ...wishlist.players] });
  };

  if (redirect) {
    return <Redirect push to={"/player/" + player.uid + "/identity"} />;
  }
  if (redirectAudios) {
    return <Redirect push to={"/player/" + player.uid + "/audios"} />;
  }
  if (redirectNotes) {
    return <Redirect push to={"/player/" + player.uid + "/notes"} />;
  }
  if (redirectEvaluation) {
    return <Redirect push to={"/player/" + player.uid + "/evaluation"} />;
  }

  return (
    <div
      style={{
        backgroundColor: "#151515",
        borderRadius: "12px",
        borderTop: "2px solid " + borderColor,
        borderLeft: "1px solid #dee2e6",
        borderRight: "1px solid #dee2e6",
        borderBottom: "1px solid #dee2e6",
        boxShadow: isNew ? "0px 0px 15px 1px rgba(247,40,40,0.81)" : "",
      }}
    >
      {/* Première ligne */}

      <div className="d-flex justify-content-between">
        <div onClick={handleOnClick} style={{ cursor: "pointer" }}>
          {avatarUrl ? (
            <img
              src={avatarUrl}
              width="100"
              height="95"
              alt=""
              style={{ borderRadius: "9px" }}
            ></img>
          ) : (
            <AccountBox
              style={{ width: "100px", height: "95px", fill: "grey" }}
            ></AccountBox>
          )}
        </div>
        <div
          className="d-flex flex-column align-items-start pt-2"
          onClick={handleOnClick}
          style={{ cursor: "pointer", width: '150px' }}
        >
          <Typography
            variant="body2"
            color="textPrimary"
            style={{
              fontWeight: "bold",
              fontSize: 16,
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
              maxWidth: "90%",
            }}
          >
            {player.firstName + " " + player.lastName}
          </Typography>
          <Typography
            variant="body2"
            color="textPrimary"
            style={{
              maxWidth: "90%",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
          >
            {age === 0
              ? t("playerCard.undefinedAge")
              : age + t("playerCard.years")}
          </Typography>
          <Typography
            variant="body2"
            color="textPrimary"
            style={{
              maxWidth: "90%",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
          >
            {position !== "" ? position : t("playerCard.undefinedPosition")}
          </Typography>
          <Typography
            variant="body2"
            color="textPrimary"
            style={{
              maxWidth: "90%",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
          >
            {player.club ? player.club : t("playerCard.undefinedClub")}
          </Typography>
        </div>

        <div className="d-flex align-items-start justify-content-end">
          <IconButton
            aria-label="delete"
            className=""
            style={{ color: "white" }}
            onClick={() => setOpen(true)}
          >
            <Delete />
          </IconButton>
          <IconButton
            aria-label="delete"
            className=""
            style={{ color: isFavorite ? "#FFEE58" : "white" }}
            onClick={handleClickWishlist}
          >
            {isFavorite ? <Star /> : <StarBorder />}
          </IconButton>
        </div>
      </div>

      {/* Deuxième ligne */}

      <div className="d-flex p-2">
        <div
          className="border flex-fill col-4 pt-1 mr-3 py-2"
          style={{ borderRadius: "12px", cursor: "pointer" }}
          onClick={handleNotesOnClick}
        >
          <Description
            style={{ color: "white" }}
            fontSize="large"
          ></Description>
          <Typography
            variant="caption"
            color="textPrimary"
            style={{ whiteSpace: "nowrap" }}
          >
            {t("playerCard.write")}
          </Typography>
        </div>
        <div
          className="border flex-fill col-4 pt-1 mr-3 py-2"
          style={{ borderRadius: "12px", cursor: "pointer" }}
          onClick={handleAudiosOnClick}
        >
          <VolumeUp style={{ color: "white" }} fontSize="large"></VolumeUp>
          <Typography
            variant="caption"
            color="textPrimary"
            style={{ whiteSpace: "nowrap" }}
          >
            {t("playerCard.audio")}
          </Typography>
        </div>
        <div
          className="border flex-fill col-4 pt-1 py-2"
          style={{ borderRadius: "12px", cursor: "pointer" }}
          onClick={handleEvaluationOnClick}
        >
          <PersonOutline
            style={{ color: "white" }}
            fontSize="large"
          ></PersonOutline>
          <Typography
            variant="caption"
            color="textPrimary"
            style={{ whiteSpace: "nowrap" }}
          >
            {t("playerCard.assessment")}
          </Typography>
        </div>
      </div>

      <div className="d-flex justify-content-around my-2">
        <Question
          style={{ fill: player.requestForTransfer ? "#ffffff" : "rgb(255, 255, 255, 0.1)", height : 30, width : 30 }}
          fontSize="large"
        ></Question>
        <Transfer
          style={{ fill: player.requestForLoan ? "#ffffff" : "rgb(255, 255, 255, 0.1)", height : 30, width : 30 }}
          fontSize="large"
        ></Transfer>
        <Siren
          style={{ fill: player.isUrgent ? "#ffffff" : "rgb(255, 255, 255, 0.1)", height : 30, width : 30 }}
          fontSize="large"
        ></Siren>
        <Eye
          style={{ fill: player.toWatch ? "#ffffff" : "rgb(255, 255, 255, 0.1)", height : 30, width : 30 }}
          fontSize="large"
        ></Eye>
        <Pen
          style={{ fill: player.fileReport ? "#ffffff" : "rgb(255, 255, 255, 0.1)", height : 30, width : 30 }}
          fontSize="large"
        ></Pen>
      </div>

      {/* Delete player confirmation dialog */}

      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            backgroundColor: "black",
            boxShadow: "0px 0px 15px 2px rgba(255,252,255,1)",
          },
        }}
      >
        <DialogContent>
          <DialogContentText color="textPrimary">
            {t("playerCard.confirmDeletion")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} style={{ color: "white" }}>
            Non
          </Button>
          <Button
            onClick={() => {
              handleDeletePlayer();
              handleClose();
            }}
            style={{ color: "white" }}
          >
            {t("playerCard.yes")}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Add player to wishlist dialog */}

      <Dialog
        open={openWishlist}
        onClose={handleCloseWishlist}
        PaperProps={{
          style: {
            backgroundColor: "black",
            boxShadow: "0px 0px 15px 2px rgba(255,252,255,1)",
          },
        }}
      >
        <DialogContent>
          <DialogContentText color="textPrimary" align="center">
            {t("playerCard.selectList")}
          </DialogContentText>
          {wishlists.length === 0
            ? t("playerCard.noList")
            : wishlists.map((wishlist) => (
                <Button
                  onClick={() => {
                    handleAddPlayerToWishlist(wishlist);
                    handleCloseWishlist();
                  }}
                  key={wishlist.uid}
                >
                  {wishlist.name}
                </Button>
              ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseWishlist} style={{ color: "white" }}>
            {t("playerCard.cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default PlayerCard;
