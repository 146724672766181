import AudioNote from "../models/AudioNote";
import { storage } from "firebase";

export default class AudioService {
  private audio: HTMLAudioElement;

  constructor() {
    this.audio = new Audio();
  }

  public async play(note: AudioNote) {
    try {
      const ref = storage().ref(`audios/${note.path}`);
      this.audio.pause();

      const url = await ref.getDownloadURL();
      this.audio.src = url;

      this.audio.play();
    } catch (e) {
      console.log(e);
    }
  }

  public async playFromPath(path: string) {
    try {
      const ref = storage().ref(`audios/${path}`);
      this.audio.pause();

      const url = await ref.getDownloadURL();
      this.audio.src = url;

      this.audio.play();
    } catch (e) {
      console.warn(e);
    }
  }

  public stop(note: AudioNote) {
    this.audio.pause();
  }

  public stopFromPath(path: string) {
    this.audio.pause();
  }
}
