import { firestore, storage } from "firebase";
import ModelFactory from "./model.factory";
import Player from "../models/Player";
import { IPlayerWrapper } from "../../ui/components/Player/PlayerBoard/playerList";
import moment from "moment";

export default class PlayerService
{
  public static async getPlayer(uid: string)
  {
    let playerRef = await firestore().collection("players").doc(uid).get();

    if (playerRef.data() === undefined)
    {
      return null;
    }

    return ModelFactory.CreatePlayerFromFirestore(playerRef.data());
  }

  public static async updatePlayer(player: Player)
  {
    let res = await firestore()
      .collection("players")
      .doc(player.uid)
      .update(ModelFactory.SerializePlayerToFirestore(player));
    return res;
  }

  public static async getUrlAvatar(avatr: string)
  {
    var res = await storage().ref().child("avatars/" + avatr).getDownloadURL();
    return res;
  }

  public static async listPlayers(treePath: string)
  {
    let playersRef = await firestore()
      .collection("players")
      .where("owner", ">=", treePath)
      .where("owner", "<=", treePath + "~")
      .get();

    let players: Player[] = [];

    playersRef.forEach((ref) => {
      players.push(ModelFactory.CreatePlayerFromFirestore(ref.data()));
    });

    return players;
  }

  public static getPlayerAge(player : IPlayerWrapper)
  {
    let age: number = 0;

    if (player.player.dateOfBirth)
    {
      const date = player.player.dateOfBirth.toDate();
      age = moment().diff(date, "years");
    }
    else
    {
      age = 0;
    }

    return (age);
  }

  public static getPlayerAgeFromPlayer(player : Player)
  {
    let age: number;

    if (player.dateOfBirth)
    {
      const date = player.dateOfBirth.toDate();
      age = moment().diff(date, "years");
    }
    else
    {
      age = 0;
    }

    return (age);
  }
}
