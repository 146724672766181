import './commentaireItem.css';

import React from "react";

import {ReactComponent as Delete } from '../../../assets/delete.svg';
import { IComment } from '../../../core/models/Comment';

type CommentaireItemProps = {
  comment : IComment,
  removeComment : Function
}

const CommentaireItem = (props : CommentaireItemProps) => {
  return (
    <div className="commentaire-item-wrapper">
      <button onClick={ () => { props.removeComment(props.comment.id) }}><Delete/></button>
      <p>{ props.comment.message }</p>
    </div>
  );
}

export default CommentaireItem;