import { Icon, IconButton } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";

export default function FlagIcon() {
  const { i18n } = useTranslation();

  const changeLanguage = () => {
    if (i18n.language === "fr") i18n.changeLanguage("en");
    else i18n.changeLanguage("fr");
  };

  return (
    <IconButton className="ml-2" onClick={changeLanguage}>
      <Icon
        className={`flag-icon ${
          i18n.language === "fr" ? "flag-icon-fr" : "flag-icon-gb"
        }`}
      />
    </IconButton>
  );
}
