import './badgeItemDroppable.css';

import React from "react";
import Badge from '../../../core/models/Badge';
import BadgesFactory from '../../pages/BoardPage/badges.factory';

type BadgeItemDroppable = {
    badge : Badge
}

const BadgeItemDroppable = (props : BadgeItemDroppable) => {

  const dragStart = (event : any) => {
    event.dataTransfer.setData("badge", JSON.stringify(props.badge));
  }
  
  const dragOver = (event : any) => {
    event.stopPropagation();
  }

  return (
    <div className="badges-item-droppable-wrapper" draggable={true} onDragStart={dragStart} onDragOver={dragOver}>
      { BadgesFactory.CreateBadgeFromObject(props.badge) }
      <p>{ props.badge.name }</p>
    </div>
  );
}

export default BadgeItemDroppable;