import React, { useEffect, useState } from "react";
import { firestore, functions } from "firebase";
import ModelFactory from "../../../core/service/model.factory";
import User from "../../../core/models/User";
import { Typography, Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";

function Admin() {
  const { t } = useTranslation();

  const [users, setUsers] = useState<User[]>([]);

  const approveUser = (user: User) => {
    const approve = functions().httpsCallable("approveUser");
    approve({
      userId: user.uid,
    });
  };

  useEffect(() => {
    const unsubscribe = firestore()
      .collection("users")
      .where("approved", "==", false)
      .onSnapshot((docs) => {
        const u: User[] = [];

        docs.forEach((doc) => {
          let userData: any = doc.data();
          userData.uid = doc.id;
          let user = ModelFactory.CreateUserFromFirestore(userData);
          u.push(user);
        });

        setUsers([...u]);
      });
    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, []);

  return (
    <div
      style={{ backgroundColor: "black", height: "100%" }}
      className="pt-5 px-5"
    >
      <div
        className="d-inline-flex flex-column px-4 pt-4 border rounded mt-3 float-left"
        id="methodology-list"
      >
        {users.length === 0 ? (
          <Typography
            variant="body1"
            color="textPrimary"
            className="mb-4 flex-grow-1"
            align="left"
          >
            {t("admin.novalidate")}
          </Typography>
        ) : null}

        {users.map((user) => (
          <div className="d-flex align-items-center mb-4" key={user.uid}>
            <Typography
              variant="body1"
              color="textPrimary"
              className="mr-5 flex-grow-1"
              align="left"
            >
              {`${user.firstName} ${user.lastName} (${
                user.club !== "" ? user.club : "n/a"
              })`}
            </Typography>
            <Button
              className="ml-5"
              style={{
                background: "linear-gradient(#01B3F4, #044CAB)",
                color: "white",
              }}
              onClick={() => approveUser(user)}
            >
              {t("admin.tovalidate")}
            </Button>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Admin;
