import React, { useEffect, useState, Fragment } from "react";
import Player from "../../../../core/models/Player";
import { Chip, Typography } from "@material-ui/core";
import { firestore } from "firebase";
import ModelFactory from "../../../../core/service/model.factory";
import PlayerClassification from "../../../../core/models/PlayerClassification";
import Methodology from "../../../../core/models/Methodology";
import { useTranslation } from "react-i18next";

type PlayerClassificationsPros = {
  player: Player | null;
};

function PlayerClassifications({ player }: PlayerClassificationsPros) {
  const { t } = useTranslation();

  const [
    classification,
    setClassifications,
  ] = useState<PlayerClassification | null>(null);

  const [methodology, setMethodology] = useState<Methodology | null>(null);

  useEffect(() => {
    if (!player) return;

    setClassifications(new PlayerClassification(player.uid, {}));

    const subscription = firestore()
      .collection("players_classifications")
      .doc(player.uid)
      .onSnapshot((doc) => {
        const data = doc.data();
        if (data) {
          data.uid = doc.id;
          const c = ModelFactory.CreatePlayerClassificationFromFirestore(data);
          setClassifications(c);
        }
      });

    return () => {
      subscription();
    };
  }, [player]);

  useEffect(() => {
    if (!player || !player.position) return;

    firestore()
      .collection("methodologies")
      .doc(player.position)
      .get()
      .then((doc) => {
        const data = doc.data();
        if (data) {
          const m = ModelFactory.CreateMethodologyFromFirestore(data);
          setMethodology(m);
        }
      });
  }, [player]);

  const handleClickCriteria = (item: string, criterion: string) => {
    if (!player || !classification) return;
    classification.classifications[item] = criterion;

    firestore()
      .collection("players_classifications")
      .doc(player.uid)
      .set(
        { classifications: classification.classifications },
        { merge: true }
      );
  };

  if (!player) return <Fragment />;

  if (!player.position)
    return (
      <div className="d-inline-flex float-left flex-column p-4">
        <Typography variant="body2" color="textPrimary">
          {t("playerClassification.select")}
        </Typography>
      </div>
    );

  if (!methodology || methodology.classification.length === 0)
    return (
      <div className="d-inline-flex float-left flex-column p-4">
        <Typography variant="body2" color="textPrimary">
          {t("playerClassification.empty")}
        </Typography>
      </div>
    );

  return (
    <div className="d-inline-flex float-left flex-column p-4">
      {methodology.classification.map((item) => (
        <div className="mb-4" key={item.title}>
          <Typography
            variant="body2"
            color="textPrimary"
            className="mb-2"
            style={{ textAlign: "left" }}
          >
            {item.title}
          </Typography>
          {item.criteria.map((criterion) => (
            <Chip
              key={criterion}
              label={criterion}
              onClick={() => handleClickCriteria(item.title, criterion)}
              variant={
                classification?.classifications[item.title] === criterion
                  ? "default"
                  : "outlined"
              }
              style={{
                color:
                  classification?.classifications[item.title] === criterion
                    ? "black"
                    : "white",
                border: "1px solid rgba(255, 255, 255)",
              }}
              className="float-left mr-1"
            />
          ))}
        </div>
      ))}
    </div>
  );
}

export default PlayerClassifications;
