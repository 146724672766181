import React, { useContext } from "react";
import "./App.css";
import { UserContext } from "./core/providers/UserProvider";
import Scaffold from "./ui/components/Scaffold/Scaffold";
import ScaffoldLanding from "./ui/components/ScaffoldLanding/ScaffoldLanding";

function App() {
  const user = useContext(UserContext);

  return (
    <div className="App">
      {user ? <Scaffold></Scaffold> : <ScaffoldLanding></ScaffoldLanding>}
    </div>
  );
}

export default App;
