
import { firestore } from "firebase";
import React, { useState, useEffect, useContext } from "react";

import Badge from '../models/Badge';
import { StoreUserContext } from "../providers/StoreUserProvider";
import ModelFactory from "../service/model.factory";

const useBadges = (badgeList : Badge[]) => {

    const [badges, setBadges] = useState<Badge[]>(badgeList);

    const user = useContext(StoreUserContext);

    useEffect(() => {
        setBadges(badgeList);
    }, [badgeList, user]);

    const createNewBadges = async (color : string, shape : string, name : string) => {

        if (!user) return;

        var tmp : Badge = new Badge(user.uid, shape, color, name, "empty");

        await firestore().collection("badges").add(ModelFactory.SerializeBadgeToFirestore(tmp, user)).then((data) => {
            var tst : Badge = new Badge(user.uid, shape, color, name, data.id);
            setBadges(badges => [...badges, tst]);
        }).catch((error) => {
            console.log(error);
        });
    }

    const deleteBadges = async (badge : Badge) => {

        if (!user) return;

        await firestore().collection("badges").doc(badge.uid).delete().then(() => {
            setBadges(badges.filter(tmp => tmp.uid !== badge.uid));
        }).catch((error) => {
            console.log(error);
        });
    }

    return { badges, setBadges, createNewBadges, deleteBadges };
}

export default useBadges;