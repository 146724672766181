import firebase from "firebase";

export default class Note {
  private _uid: string;

  // Ownership
  private _owner: string;
  private _ownerName: string;

  private _createdAt: firebase.firestore.Timestamp;
  private _text: string;

  constructor(
    uid: string,
    owner: string,
    ownerName: string,
    createdAt: firebase.firestore.Timestamp,
    text: string,
  ) {
    this._uid = uid;
    this._owner = owner;
    this._ownerName = ownerName;
    this._createdAt = createdAt;
    this._text = text;
  }

  get uid(): string {
    return this._uid;
  }

  set uid(value: string) {
    this._uid = value;
  }

  get owner(): string {
    return this._owner;
  }

  set owner(value: string) {
    this._owner = value;
  }

  get ownerName(): string {
    return this._ownerName;
  }

  set ownerName(value: string) {
    this._ownerName = value;
  }

  get createdAt(): firebase.firestore.Timestamp {
    return this._createdAt;
  }

  set createdAt(value: firebase.firestore.Timestamp) {
    this._createdAt = value;
  }

  get text(): string {
    return this._text;
  }

  set text(value: string) {
    this._text = value;
  }
}
