import './playerButtonAdd.css';

import React, { useContext, useState } from "react";

import AddPlayer from '../../../../assets/addPlayer.png';
import PopupAdd from '../../../pages/BoardPage/popupAdd';
import { useTranslation } from 'react-i18next';
import { IComposition } from '../../../pages/BoardPage/board';
import Player from '../../../../core/models/Player';
import { BoardContext } from '../../../pages/BoardPage/boardProvider';

type PlayerButtonAddProps = {
  who : string,
  handleAddPlayer : Function,
  composition : IComposition | null
}

const PlayerButtonAdd = (props : PlayerButtonAddProps) => {

  const [open, setOpen] = useState(false);

  const { index } = useContext(BoardContext);
  
  var { t } = useTranslation();
  
  return (
    <div className="player-button-add-wrapper">
      <img src={AddPlayer} onClick={() => (props.composition && index != -1) && setOpen(!open)}/>
      <p>{t("boardPage.add")}</p>
      { open && <PopupAdd who={props.who} handleAddPlayer={props.handleAddPlayer} setOpen={setOpen}/> }
    </div>
  );
}

export default PlayerButtonAdd;