import React, { useState } from "react";

const BoardContextProps = {
  index : -1,
  setIndex : (index : number) => {},
  save : false,
  setSave : (value : boolean) => {}
}

type BoardProviderProps = {
  children : JSX.Element | JSX.Element[];
}

export const BoardContext = React.createContext(BoardContextProps);

const BoardProvider = (props : BoardProviderProps) => {
  
  const [index, setIndex] = useState(-1);
  const [save, setSave] = useState(false);

  return (
    <BoardContext.Provider value={{ index, setIndex, save, setSave }}>
      { props.children }
    </BoardContext.Provider>
  );
}

export default BoardProvider;