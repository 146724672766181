
export default class Badge {

    private _shape : string;
    private _color : string;
    private _name : string;
    private _user : string;
    private _uid : string;

  constructor(user : string, shape : string, color : string, name : string, uid : string) {
    this._shape = shape;
    this._color = color;
    this._name = name;
    this._user = user;
    this._uid = uid;
  }

  get shape(): string {
    return this._shape;
  }

  set shape(value: string) {
    this._shape = value;
  }

  get user(): string {
    return this._user;
  }

  set user(value: string) {
    this._user = value;
  }

  get color(): string {
    return this._color;
  }

  set color(value: string) {
    this._color = value;
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get uid(): string {
    return this._uid;
  }

  set uid(value: string) {
    this._uid = value;
  }
}
