export default class PlayerClassification {
  private _uid: string;
  private _classifications: any;

  //   Map<String, String> classifications;

  constructor(uid: string, classifications: any) {
    this._uid = uid;
    this._classifications = classifications;
  }

  get uid(): string {
    return this._uid;
  }

  set uid(value: string) {
    this._uid = value;
  }

  get classifications(): any {
    return this._classifications;
  }

  set classifications(value: any) {
    this._classifications = value;
  }
}
