import './archiveItem.css';

import React, { useState } from "react";
import { IComposition } from '../../pages/BoardPage/board';
import { IPlayerDisplay, IPlayerWrapper } from '../Player/PlayerBoard/playerList';
import PlayerService from '../../../core/service/player.service';
import Player from '../../../core/models/Player';
import { firestore } from 'firebase';

type ArchiveItemProps = {
  handleSetComposition : Function,
  composition : IComposition | null,
  archive : IComposition,
  setArchives : Function,
  archives : IComposition[] | null,
  setComposition : Function
}

const ArchiveItem = (props : ArchiveItemProps) => {

  var [isLoading, setIsLoading] = useState(false);
  var [isDeleting, setIsDeleting] = useState(false);


  async function handle(archive : IComposition)
  {
    setIsLoading(true);

    if (props.composition && props.composition.id === props.archive.id)
    {
      setIsLoading(false);
      return;
    }

    if (archive.loaded === true)
    {
      setIsLoading(false);
      props.handleSetComposition(archive);
      return;
    }

    for (var player of archive.players)
    {
      let select : IPlayerWrapper[] = [];

      for (const p of player.selected)
      {
        let badgesListTmp : any[] = [];

        await PlayerService.getPlayer(p.player).then( async (t : Player | null) => {
          if (t)
          {
            t.uid = p.player;

            if (t.position)
            {
              await firestore().collection("methodologies").doc(t.position).get().then((data) => {
                let c : any = data.data();

                if (c)
                {
                  t.post = c.name;
                }
                
                const display : IPlayerDisplay = { age : true, badges : true, origine : true, valeur : true };
              
                select.push({ badges : badgesListTmp, player : t, index : p.index, display : display } as IPlayerWrapper);


              }).catch((error) => {
                console.log(error);
              });
            }
            else
            {
              const display : IPlayerDisplay = { age : true, badges : true, origine : true, valeur : true };
              
              select.push({ badges : badgesListTmp, player : t, index : p.index, display : display } as IPlayerWrapper);

            }
          }
        });

      }
      player.selected = select;
    }

    setIsLoading(false);

    archive.loaded = true;

    props.handleSetComposition(archive);
  }

  async function handleRemoveArchive()
  {
    try
    {
      setIsDeleting(true);

      await firestore().collection("archives").doc(props.archive.id).delete().then(() => {
        props.setArchives(props.archives?.filter((archive : IComposition) => archive.id !== props.archive.id));
        setIsDeleting(false);
        props.setComposition(null);
      });
    }
    catch (error) {
      console.log(error);
      setIsDeleting(false);
    };
  }

  return (
    <div className="archive-item-wrapper">
      <button onClick={ () => { handleRemoveArchive() } }>X</button>
      <div className="archive-item-title-wrapper">
        <p>{ props.archive.name }</p>
        { isLoading && <div className="loader"></div> }
        { isDeleting && <div className="loaderDelete"></div> }
      </div>
      <img src={ props.archive.archive } onClick={ () => { handle(props.archive) }}/>
      <div className="archive-item-footer-wrapper">
        <p>{ props.archive.user }</p>
        <p>{ props.archive.time }</p>
      </div>
    </div>
  );
}

export default ArchiveItem;