import Category from "./Category";

export default class PlayerEvaluation {
    private _uid: string;
    private _categories: Category[];

    // Ownership
    private _ownerName: string;
    private _createdAt: firebase.firestore.Timestamp;
    private _grade: number;


	constructor(
        uid: string,
        categories: Category[],
        ownerName: string,
        createdAt: firebase.firestore.Timestamp,
        grade: number,
        ) {
		this._uid = uid;
        this._categories = categories;
        this._ownerName = ownerName;
        this._createdAt = createdAt;
        this._grade = grade;
	}
    

    /**
     * Getter categories
     * @return {Category[]}
     */
	public get categories(): Category[] {
		return this._categories;
	}

    /**
     * Setter categories
     * @param {Category[]} value
     */
	public set categories(value: Category[]) {
		this._categories = value;
	}


    /**
     * Getter uid
     * @return {string}
     */
	public get uid(): string {
		return this._uid;
	}

    /**
     * Setter uid
     * @param {string} value
     */
	public set uid(value: string) {
		this._uid = value;
    }
    
    get ownerName(): string {
      return this._ownerName;
    }
    
    set ownerName(value: string) {
      this._ownerName = value;
    }
    
    get createdAt(): firebase.firestore.Timestamp {
      return this._createdAt;
    }
    
    set createdAt(value: firebase.firestore.Timestamp) {
      this._createdAt = value;
    }

    get grade(): number {
      return this._grade;
    }
  
    set grade(value: number) {
      this._grade = value;
    }
}
