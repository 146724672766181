import { firestore } from "firebase";
import { IComposition } from "../../ui/pages/BoardPage/board";
import ModelFactory from "./model.factory";

export default class CompositionService
{
    public static async handleSaveButtonClick(composition : IComposition | null, compositioName : string, save : boolean, setSave : Function, user : any)
    {
       
        if (!user) return;
    
        if (!composition || save === false) {
          return;
        }
    
        if (composition?.id === "") {
    
          if (compositioName !== "")
          {
            composition.name = compositioName;
          }
    
          await firestore().collection("archives").add(ModelFactory.SerializeCompositionToFirestore(composition, user)).then((data) => {
            if (composition) {
              composition.id = data.id;
            }
            setSave(false);
          }).catch((e) => {
            console.log(e);
          });
        } else {
          
          if (compositioName !== "")
          {
            composition.name = compositioName;
          }
          
          // change user here by archive user creator // 
          await firestore().collection("archives").doc(composition?.id).set(ModelFactory.SerializeCompositionToFirestore(composition, user)).catch((e) => {
            console.log(e);
          });
          setSave(false);
        }
      }
}