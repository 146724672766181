export default class Wishlist {
  private _uid: string;
  private _name: string;
  private _description: string;
  private _players: string[];
  private _user: string;

  constructor(
    uid: string,
    name: string,
    description: string,
    players: string[],
    user: string
  ) {
    this._uid = uid;
    this._name = name;
    this._description = description;
    this._players = players;
    this._user = user;
  }

  get uid(): string {
    return this._uid;
  }

  set uid(value: string) {
    this._uid = value;
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get description(): string {
    return this._description;
  }

  set description(value: string) {
    this._description = value;
  }

  get players(): string[] {
    return this._players;
  }

  set players(value: string[]) {
    this._players = value;
  }

  get user(): string {
    return this._user;
  }

  set user(value: string) {
    this._user = value;
  }
}
