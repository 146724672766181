import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "bootstrap/dist/css/bootstrap.css";
import "flag-icon-css/css/flag-icon.min.css";
import UserProvider from "./core/providers/UserProvider";
import firebase from "firebase";
import {
  createMuiTheme,
  ThemeProvider,
  ThemeOptions,
  CircularProgress,
} from "@material-ui/core";
import StoreUserProvider from "./core/providers/StoreUserProvider";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import "./core/service/i18n.service";
import { lightBlue } from "@material-ui/core/colors";

const config = {
  apiKey: "AIzaSyCrlvPc5VrjR2CffSNYQg9MVy2SkirsHow",
  authDomain: "invister-1efa6.firebaseapp.com",
  databaseURL: "https://invister-1efa6.firebaseio.com",
  projectId: "invister-1efa6",
  storageBucket: "invister-1efa6.appspot.com",
  messagingSenderId: "659069885754",
  appId: "1:659069885754:web:9ccb2c4e0e2d6511b60403",
  measurementId: "G-VN1QX2EWN7",
};

firebase.initializeApp(config);

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#000",
      contrastText: "#fff",
    },
    secondary: {
      main: "#00C2FF",
      contrastText: "#fff",
    },
    text: {
      primary: "#fff",
    },
  },

  overrides: {
    MuiDivider: {
      light: {
        backgroundColor: "white",
      },
    },

    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: lightBlue.A200,
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        // backgroundColor: lightBlue.A200,
        color: lightBlue.A200,
      },
    },
    MuiPickersDay: {
      day: {
        color: lightBlue.A700,
      },
      daySelected: {
        backgroundColor: lightBlue["400"],
      },
      dayDisabled: {
        color: lightBlue["100"],
      },
      current: {
        color: lightBlue["900"],
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: lightBlue["400"],
      },
    },
  },
} as ThemeOptions);

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <UserProvider>
        <StoreUserProvider>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Suspense fallback={<CircularProgress />}>
              <App />
            </Suspense>
          </MuiPickersUtilsProvider>
        </StoreUserProvider>
      </UserProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
