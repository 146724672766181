import './commentaireList.css';

import React, { useState, useEffect } from "react";
import useComposition from '../../../core/utils/useComposition';
import CommentaireItem from './commentaireItem';
import CommentaireInput from './commentaireInput';
import useComment from '../../../core/utils/useComment';
import { IComposition } from '../../pages/BoardPage/board';
import { IComment } from '../../../core/models/Comment';
import { useTranslation } from 'react-i18next';

type CommentaireListProps = {
  list : IComposition | null
}

const CommentaireList = (props : CommentaireListProps) => {

  var [reload, setReload] = useState(false);
  var { composition } = useComposition(props.list);

  var { comments, addComment, removeComment } = useComment(props?.list?.id??"empty");

  var { t } = useTranslation();
  
  return (
    <div className="commentaire-list-wrapper">
      <div className="commentaire-list-title-wrapper">
      <p>{t("boardPage.comment")}</p>
      </div>
      <div className="commentaire-list-item-wrapper">
        { (comments && props.list) && comments.map((comment : IComment, index : number) => <CommentaireItem removeComment={removeComment} key={index} comment={comment}/>) }
      </div>
      <CommentaireInput addComment={addComment} composition={composition} setReload={setReload} reload={reload}/>
    </div>
  );
}

export default CommentaireList;