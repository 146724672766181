import './playerAddItem.css';

import React, { useState, useEffect } from "react";
import Player from '../../../../core/models/Player';

import Add from '../../../../assets/addPlayer.png';
import Checked from '../../../../assets/checked.png';
import FlagService from '../../../../core/service/flag.service';
import PlayerService from '../../../../core/service/player.service';

type PlayerAddItemProps = {
  player : Player,
  addToList : Function,
  list : Player[],
  reference : any | null
}

const PlayerAddItem = (props : PlayerAddItemProps) => {

  var [selected, setSelected] = useState(false);
  
  function add(player : Player)
  {
    if (selected === false)
    {
      props.addToList((list : Player[]) => [...list, player]);
    }
    else
    {
      props.addToList(props.list.filter((item : Player) => item.uid !== player.uid));
    }

    setSelected(!selected);
  }

  function getGradeImage(grade : number) : string
  {
    switch (grade)
    {
      case 0.5 : return ("/stars/0-5.png");
      case 1 : return ("/stars/1.png");
      case 1.5 : return ("/stars/1-5.png");
      case 2 : return ("/stars/2.png");
      case 2.5 : return ("/stars/2-5.png");
      case 3 : return ("/stars/3.png");
      case 3.5 : return ("/stars/3-5.png");
      case 4 : return ("/stars/4.png");
      case 4.5 : return ("/stars/4-5.png");
      case 5 : return ("/stars/5.png");
    }

    return ("");
  }

  return (
    <div className="player-add-item-wrapper" ref={props.reference}>
     <div className={`player-add-item-info-wrapper ${ selected && "player-add-item-info-wrapper-selected" }`}>
      <img className="profile-item" src={ props.player.avatar ? "https://firebasestorage.googleapis.com/v0/b/invister-1efa6.appspot.com/o/avatars%2F" + props.player.avatar + "?alt=media" : "/unknown.png" } />
      <p>{ props.player.firstName }</p>
      <p>{ props.player.lastName }</p>
      <p>{ PlayerService.getPlayerAgeFromPlayer(props.player) }</p>
      <p>{ props.player.post }</p>
      <p>{ props.player.club }</p>
      <img src={ FlagService.getFlag(props.player.citizenship) !== "Error" ? "https://www.countryflags.io/" + FlagService.getFlag(props.player.citizenship) + "/flat/24.png" : "" } />
      { props.player.grade > 0 ? <img className="grade" src={getGradeImage(props.player.grade)} /> : "" }
     </div>
     <div className="player-add-item-button-wrapper">
       <button onClick={ () => { add(props.player) }}><img src={ selected ? Checked : Add } /></button>
     </div>
    </div>
  );
}

export default PlayerAddItem;