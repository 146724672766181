import PlayerItem from "./PlayerItem";

export default class Category {
    private _type: string;
    private _items: PlayerItem[];


	constructor(type: string, items: PlayerItem[]) {
		this._type = type;
		this._items = items;
	}


    /**
     * Getter type
     * @return {string}
     */
	public get type(): string {
		return this._type;
	}

    /**
     * Setter type
     * @param {string} value
     */
	public set type(value: string) {
		this._type = value;
	}

    /**
     * Getter items
     * @return {PlayerItem[]}
     */
	public get items(): PlayerItem[] {
		return this._items;
	}

    /**
     * Setter items
     * @param {PlayerItem[]} value
     */
	public set items(value: PlayerItem[]) {
		this._items = value;
	}
    
}