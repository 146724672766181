export interface IComment
{
    id : string,
    message : string
}

export default class Comment implements IComment
{
    id : string;
    message : string;

    constructor(idd : string, messagee : string)
    {
        this.id = idd;
        this.message = messagee;
    }
}