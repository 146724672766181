import './popupPdfForm.css';

import React, { useContext, useEffect, useRef, useState } from "react";
import { IComposition } from './board';
import BoardServicePDF from '../../../core/service/pdf.board';
import useScreenshot from 'use-screenshot-hook';

import { browserName } from 'react-device-detect';

import chrome from "../../../assets/chrome.png";
import { useTranslation } from 'react-i18next';
import { StoreUserContext } from '../../../core/providers/StoreUserProvider';

type PopupPDFFormProps = {
  composition : IComposition | null,
  setOpen : Function,
  reff : any
}

const PopupPDFForm = (props : PopupPDFFormProps) => {

  const [name, setName] = useState("");

  const { t } = useTranslation();

  var ref : any = useRef(null);
  
  const { image, takeScreenshot, clear } = useScreenshot({ ref: props.reff });

  const user = useContext(StoreUserContext);

  useEffect(() => {

    if (image)
    {
      BoardServicePDF.GenerateBoadPDF(props.composition!, name, image, user);
      clear();
    }

  }, [image]);

  useEffect(() => {

    document.addEventListener("mousedown", handleClickOutSide);
    return () => { document.removeEventListener("mousedown", handleClickOutSide); }
    
  }, []);

  function handleClickOutSide(event : any)
  {
    if (ref && !ref.current.contains(event.target))
    {
      props.setOpen(false);
    }
  }

  async function dl()
  {
    var elem = document.getElementById("formm");
    var input = document.getElementById("inputt");

    if (elem && input )
    {
      elem.style.display = "none";
      input.style.color = "black";
    }

    await takeScreenshot("png");

    if (input)
    {
      input.style.color = "white";
    }

    props.setOpen(false);
  }

  return (
    <div className="pdf-form-wrapper" ref={ref} id="formm">
      { browserName === "Chrome" ? <div>
        <input placeholder={t("PDF.Name")} value={name} onChange={ (event) => setName(event.target.value) }></input>
        <input placeholder={t("PDF.Comment")}></input>
        <button className={ name === "" ? "button-desable" : "button-enable" } onClick={ () => { (props.composition && name !== "") && dl() }}>Generate</button>
      </div> : <div className="notChrome">
        <p>{t("PDF.Error")}</p>
        <img src={chrome}/>
      </div> }
    </div>
  );
}

export default PopupPDFForm;