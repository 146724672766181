import './badgesList.css';

import React, { useState, useEffect } from "react";

import Badge from '../../../core/models/Badge';
import BadgeItem from './badgeItem';

import useBadges from '../../../core/utils/useBadges';

import { ReactComponent as Square } from '../../../assets/square.svg';
import { ReactComponent as Triangle } from '../../../assets/bleach.svg';
import { ReactComponent as Circle } from '../../../assets/dry-clean.svg';
import { ReactComponent as Diamond } from '../../../assets/diamonds.svg';
import { ReactComponent as Selected } from '../../../assets/selected.svg';

import { useTranslation } from 'react-i18next';

type BadgesListProps = {
  badges : Badge[],
  setBadges : Function
}

const BadgesList = (props : BadgesListProps) => {

  const { badges, createNewBadges, deleteBadges } = useBadges(props.badges);

  const [name, setName] = useState("");
  const [shape, setShape] = useState("");
  const [color, setColor] = useState("");

  const [enable, setEnable] = useState(false);

  var { t } = useTranslation();

  useEffect(() => {
    props.setBadges(badges);
  }, [badges]);

  useEffect(() => {

    if (name === "" || shape === "" || color === "")
    {
      setEnable(false);
    }
    else
    {
      setEnable(true);
    }

  }, [name, shape, color]);

  function handleAddButton()
  {
    if (enable === false)
    {
      return;
    }

    createNewBadges(color, shape, name);

    setColor("");
    setShape("");
    setName("");
  }

  return (
    <div className="badges-list-wrapper">
      <div className="badges-list-title-wrapper">
      <p>{t("boardPage.badges")}</p>
      </div>
      <div className="badges-list-badges-wrapper">
        { badges && badges.map((badge : Badge, index : number) => <BadgeItem key={index} badge={badge} deleteBadges={deleteBadges}/> )}
      </div>
      <div className="badges-create-badge-wrapper">
      <p>{t("boardPage.make")}</p>
        <div className="badges-choice-wrapper">
          <p>{t("boardPage.shape")}</p>
          <div className="badges-choice-item-wrapper">
            <Square style={{ fill : shape === "square" ? "#14FE00" : "#1D50AE" }} onClick={ () => { setShape("square") }} />
            <Circle style={{ fill : shape === "circle" ? "#14FE00" : "#1D50AE" }}  onClick={ () => { setShape("circle") }} />
            <Triangle style={{ fill : shape === "triangle" ? "#14FE00" : "#1D50AE" }} onClick={ () => { setShape("triangle") }} />
            <Diamond style={{ fill : shape === "diamond" ? "#14FE00" : "#1D50AE" }} onClick={ () => { setShape("diamond") }} />
          </div>
        </div>
        <div className="badges-choice-wrapper">
          <p>{t("boardPage.color")}</p>
          <div className="badges-choice-item-wrapper">
              <Circle style={{ fill : color === "red" ? "#14FE00" : "red" }} onClick={ () => { setColor("red") }} />
              <Circle style={{ fill : color === "green" ? "#14FE00" : "green" }} onClick={ () => { setColor("green") }} />
              <Circle style={{ fill : color === "#0350AE" ? "#14FE00" : "#0350AE" }} onClick={ () => { setColor("#0350AE") }} />
              <Circle style={{ fill : color === "yellow" ? "#14FE00" : "yellow" }} onClick={ () => { setColor("yellow") }} />
              <Circle style={{ fill : color === "#FF00CC" ? "#14FE00" : "#FF00CC" }} onClick={ () => { setColor("#FF00CC") }} />
              <Circle style={{ fill : color ===  "#00B9F8" ? "#14FE00" : "#00B9F8" }} onClick={ () => { setColor("#00B9F8") }} />
          </div>
        </div>
      </div>
      <div className="badges-legende-wraper">
        <div className="badget-legende-box-wraper">
          <input placeholder="Légende" value={name} onChange={ (e) => { setName(e.target.value) }}></input>
          <button className={ !enable ? "badget-legende-box-button-disable" : "badget-legende-box-button-enable" } onClick={ () =>  handleAddButton() }>{t("boardPage.button")}</button>
        </div>
      </div>
    </div>
  );
}

export default BadgesList;
