import './menuContainer.css';

import React, { useEffect, useRef } from "react";

import { menuInitialState } from './menu';
import CompositionList from '../Composition/compositionList';
import CommentaireList from '../Comment/commentaireList';
import ArchiveList from '../Archive/archiveList';
import Badge from '../../../core/models/Badge';
import BadgesList from '../Badge/badgesList';
import { IComposition } from '../../pages/BoardPage/board';


type menyContainerProps = {
  isOpen : boolean,
  state : typeof menuInitialState,
  handleCompositionChange : Function,
  Composition : IComposition | null,
  badges : Badge[],
  setComposition : Function,
  setBadges : Function,
  dispatch : Function,
}

const MenuContainer = (props : menyContainerProps) => {

  useEffect(() => {

    if (props.isOpen == true)
    {
      document.addEventListener("click", handleClose);
    }

    return () => { document.removeEventListener("click", handleClose); }
  });

  function handleClose(event : any)
  {
    event.preventDefault();
    
    var element = event.target;
    var parents : string[] = [];

    for ( ; element && element !== document; element = element.parentNode )
    {
      parents.push(element.className);
    }

    if (parents.indexOf("menu-wrapper menu-wrapper-open") !== -1)
    {
      return;
    }

    props.dispatch("openState");

    event.preventDefault();
  }

  return (
    <div className={`menu-container-wrapper ${props.isOpen ? "menu-container-wrapper-show" : "menu-container-wrapper-hide" }`}>
      { props.state.archiveState && <ArchiveList setComposition={props.setComposition} composition={props.Composition}/> }
      { props.state.compoState && <CompositionList handleCompositionChange={props.handleCompositionChange}/> }
      { props.state.comsState && <CommentaireList list={props.Composition}/> }
      { props.state.badgesState && <BadgesList setBadges={props.setBadges} badges={props.badges}/> }
    </div>
  );
}

export default MenuContainer;