import React, { Fragment, useState, useEffect } from "react";
import Player from "../../../core/models/Player";
import { storage } from "firebase";
import { AccountCircle } from "@material-ui/icons";

type AvatarProps = {
  player: Player;
};

export const Avatar = ({ player }: AvatarProps) => {
  const [avatarUrl, setAvatarUrl] = useState<string>("");

  useEffect(() => {
    if (player.avatar) {
      const avatarRef = storage().ref(`avatars/${player.avatar}`);
      avatarRef.getDownloadURL().then((url) => {
        setAvatarUrl(url);
      });
    }
  }, [player.avatar]);

  if (!player) return <Fragment />;

  return avatarUrl ? (
    <img
      src={avatarUrl}
      width="80"
      height="80"
      alt=""
      style={{
        borderRadius: "50%",
      }}
    ></img>
  ) : (
    <AccountCircle
      style={{ width: "80px", height: "80px", fill: "grey" }}
    ></AccountCircle>
  );
};
