import React, { useContext, useEffect, useState, useRef } from "react";
import {
  Typography,
  Fab,
  Dialog,
  DialogActions,
  Button,
  DialogContent,
  DialogTitle,
  MenuItem,
} from "@material-ui/core";
import { Add } from "@material-ui/icons";
import PlayerCard from "../../components/Player/PlayerCard/PlayerCard";
import Player from "../../../core/models/Player";
import Wishlist from "../../../core/models/Wishlist";
import { Redirect } from "react-router";
import { firestore } from "firebase";
import ModelFactory from "../../../core/service/model.factory";
import { StoreUserContext } from "../../../core/providers/StoreUserProvider";
import CustomTextField from "../../components/CustomTextField/CustomTextField";
import { ErrorMessage, Formik } from "formik";
import Methodology from "../../../core/models/Methodology";
import { useTranslation } from "react-i18next";

function DashboardPage() {
  const { t } = useTranslation();

  const fsUser = useContext(StoreUserContext);

  const [players, setPlayers] = useState<Player[] | null>(null);
  const [wishlists, setWishlists] = useState<Wishlist[] | null>(null);
  const [uidPlayer, setUidPlayer] = useState<string | null>(null);
  const [filterAgeAsc, setFilterAgeAsc] = useState<boolean | null>(null);
  const [filterScoreAsc, setFilterScoreAsc] = useState<boolean | null>(null);
  const [methodologies, setMethodologies] = useState<Methodology[]>([]);

  const allPlayers = useRef<Player[]>([]);
  const [actionFilter, setActionFilter] = useState("");
  const [positionFilter, setPositionFilter] = useState("");

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleFilterPositionChange = (filter: any) => {
    setPositionFilter(filter);
  };

  const handleFilterActionChange = (filter: any) => {
    setActionFilter(filter);
  };

  useEffect(() => {
    const filterPlayers: Player[] = [];
    allPlayers.current.forEach((p) => {
      if (positionFilter !== "" && positionFilter !== p.position) return;
      if (actionFilter === "") filterPlayers.push(p);
      else if (
        (actionFilter === "isUrgent" && p.isUrgent) ||
        (actionFilter === "requestForLoan" && p.requestForLoan) ||
        (actionFilter === "requestForTransfer" && p.requestForTransfer) ||
        (actionFilter === "toWatch" && p.toWatch) ||
        (actionFilter === "fileReport" && p.fileReport)
      )
        filterPlayers.push(p);
    });
    setPlayers(filterPlayers);
  }, [actionFilter, positionFilter]);

  const handleSortChange = (value: any) => {
    if (value === "age") {
      setFilterAgeAsc(filterAgeAsc !== null ? !filterAgeAsc : true);
      setFilterScoreAsc(null);
    } else if (value === "score") {
      setFilterScoreAsc(filterScoreAsc !== null ? !filterScoreAsc : true);
      setFilterAgeAsc(null);
    } else {
      setFilterAgeAsc(null);
      setFilterScoreAsc(null);
    }
  };

  useEffect(() => {
    if (!fsUser) return;

    const treePath =
      fsUser.parent === "none"
        ? `${fsUser.uid}`
        : `${fsUser.parent}_${fsUser.uid}`;

    const hsxId: string =
      fsUser.parent === "none" ? fsUser.uid : fsUser.parent.split("_")[0];

    let unsubscribe = firestore()
      .collection("players")
      .where("owner", ">=", treePath)
      .where("owner", "<=", treePath + "~")
      .onSnapshot((data) => {
        let p: Player[] = [];

        data.forEach((doc) => {
          let player: any = doc.data();
          player.uid = doc.id;
          p.push(ModelFactory.CreatePlayerFromFirestore(player));
        });

        setPlayers([...p]);
        allPlayers.current = p;
      });

    let unsubscribeWishlist = firestore()
      .collection("wishlists")
      .where("user", "==", fsUser.uid)
      .onSnapshot((data) => {
        let w: Wishlist[] = [];

        data.forEach((doc) => {
          let wishlist: any = doc.data();
          wishlist.uid = doc.id;
          w.push(ModelFactory.CreateWishlistFromFirestore(wishlist));
        });

        setWishlists([...w]);
      });

    let unsubscribeMethodology = firestore()
      .collection("methodologies")
      .where("owner", "==", hsxId)
      .onSnapshot((data) => {
        let m: Methodology[] = [];

        data.forEach((doc) => {
          let methodology: any = doc.data();
          methodology.uid = doc.id;
          m.push(ModelFactory.CreateMethodologyFromFirestore(methodology));
        });

        setMethodologies([...m]);
      });

    return () => {
      unsubscribe();
      unsubscribeWishlist();
      unsubscribeMethodology();
    };

  }, [fsUser]);

  if (uidPlayer !== null) {
    return <Redirect push to={"/player/" + uidPlayer + "/identity"} />;
  }

  if (filterAgeAsc !== null) {
    if (!filterAgeAsc) {
      players?.sort((a, b) => a.dateOfBirth.seconds - b.dateOfBirth.seconds);
    } else {
      players?.sort((a, b) => b.dateOfBirth.seconds - a.dateOfBirth.seconds);
    }
  }

  if (filterScoreAsc !== null) {
    if (!filterScoreAsc) {
      players?.sort((a, b) => a.grade - b.grade);
    } else {
      players?.sort((a, b) => b.grade - a.grade);
    }
  }

  players?.sort((a, b) => {
    if (!fsUser?.notifications?.newPlayers) return 0;

    const newPlayers = fsUser.notifications.newPlayers;
    for (const newPlayer of newPlayers) {
      if (a.uid === newPlayer) return -1;
      else if (b.uid === newPlayer) return 1;
    }

    return 0;
  });

  return (
    <div
      style={{ backgroundColor: "black", height: "100%" }}
      className="pt-5 px-5"
    >
      <div className="d-flex align-items-center">
        <Typography variant="h6" color="textPrimary" align="left">
          {t("dashboard.myplayers")}
        </Typography>
        <Fab
          variant="extended"
          className="ml-4"
          size="small"
          style={{
            background: "linear-gradient(#01B3F4, #044CAB)",
            color: "white",
          }}
          onClick={handleClickOpen}
        >
          <Add />
          <span className="ml-1" style={{ fontSize: 12 }}>
            &nbsp;{t("dashboard.addplayer")}&nbsp;
          </span>
        </Fab>

        <div className="d-flex justify-content-end flex-grow-1">
          <div className="mr-2">
            <CustomTextField
              select
              label={t("dashboard.filterbyaction")}
              onChange={(event) => {
                handleFilterActionChange(event.target.value);
              }}
              style={{ width: "120px" }}
            >
              <MenuItem value="" style={{ color: "black" }}>
                {t("dashboard.none")}
              </MenuItem>
              <MenuItem
                key="isUrgent"
                value="isUrgent"
                style={{ color: "black" }}
              >
                {t("dashboard.isUrgent")}
              </MenuItem>
              <MenuItem
                key="toWatch"
                value="toWatch"
                style={{ color: "black" }}
              >
                {t("dashboard.toWatch")}
              </MenuItem>
              <MenuItem
                key="requestForLoan"
                value="requestForLoan"
                style={{ color: "black" }}
              >
                {t("dashboard.requestForLoan")}
              </MenuItem>
              <MenuItem
                key="requestForTransfer"
                value="requestForTransfer"
                style={{ color: "black" }}
              >
                {t("dashboard.requestForTransfer")}
              </MenuItem>
              <MenuItem
                key="fileReport"
                value="fileReport"
                style={{ color: "black" }}
              >
                {t("dashboard.fileReport")}
              </MenuItem>
            </CustomTextField>
          </div>

          <div className="mr-2">
            <CustomTextField
              select
              label={t("dashboard.filterbyposition")}
              onChange={(event) => {
                handleFilterPositionChange(event.target.value);
              }}
              style={{ width: "120px" }}
            >
              <MenuItem value="" style={{ color: "black" }}>
                {t("dashboard.none")}
              </MenuItem>
              {methodologies.map((option) => (
                <MenuItem
                  key={option.uid}
                  value={option.uid}
                  style={{ color: "black" }}
                >
                  {option.name}
                </MenuItem>
              ))}
            </CustomTextField>
          </div>

          <div className="mr-2">
            <CustomTextField
              select
              label={t("dashboard.sortby")}
              onChange={(event) => {
                handleSortChange(event.target.value);
              }}
              style={{ width: "120px" }}
            >
              <MenuItem value="" style={{ color: "black" }}>
                {t("dashboard.none")}
              </MenuItem>
              <MenuItem value="age" style={{ color: "black" }}>
                {t("dashboard.age")}
              </MenuItem>
              <MenuItem value="score" style={{ color: "black" }}>
                {t("dashboard.score")}
              </MenuItem>
            </CustomTextField>
          </div>
        </div>
      </div>

      <div
        className="d-inline-flex flex-row flex-wrap px-4 pb-4 rounded mt-3 float-left col-12"
        style={{ border: "1px solid rgb(50,50,50)" }}
      >
        {wishlists ? (
          players?.map((player) => (
            <div className="col-3 mt-4" key={player.uid}>
              <PlayerCard player={player} wishlists={wishlists}></PlayerCard>
            </div>
          ))
        ) : (
          <div></div>
        )}
      </div>

      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            backgroundColor: "black",
            boxShadow: "0px 0px 15px 2px rgba(255,252,255,1)",
          },
        }}
      >
        <Formik
          initialValues={{ lastName: "", firstName: "" }}
          validate={(values) => {
            const errors: any = {};
            if (!values.firstName) {
              errors.firstName = "Champs obligatoire";
            }
            if (!values.lastName) {
              errors.lastName = "Champs obligatoire";
            }
            return errors;
          }}
          onSubmit={async (values, { setSubmitting }) => {
            if (!fsUser) return;

            const owner =
              fsUser.parent === "none"
                ? fsUser.uid
                : fsUser.parent + "_" + fsUser.uid;

            const playerDoc = await firestore().collection("players").add({
              firstName: values.firstName,
              lastName: values.lastName,
              owner: owner,
            });

            await firestore()
              .collection("playerEvaluations")
              .doc(playerDoc.id)
              .set({
                categories: [
                  { type: "mind", items: [] },
                  { type: "athletic", items: [] },
                  { type: "technic", items: [] },
                  { type: "tactic", items: [] },
                ],
              });

            setUidPlayer(playerDoc.id);

            handleClose();
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <DialogTitle>{t("dashboard.addplayer")}</DialogTitle>
              <DialogContent>
                <CustomTextField
                  label={t("dashboard.playerlastname")}
                  type="text"
                  name="lastName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                  disabled={isSubmitting}
                  value={values.lastName}
                />

                <ErrorMessage
                  name="lastName"
                  render={(msg) => <span style={{ color: "red" }}>{msg}</span>}
                />

                <CustomTextField
                  label={t("dashboard.playerfirstname")}
                  type="text"
                  name="firstName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                  disabled={isSubmitting}
                  value={values.firstName}
                  className="mt-2"
                />

                <ErrorMessage
                  name="firstName"
                  render={(msg) => <span style={{ color: "red" }}>{msg}</span>}
                />
              </DialogContent>
              <DialogActions className="justify-content-center mt-3 mb-4">
                <Button
                  type="submit"
                  variant="contained"
                  style={{
                    background: "linear-gradient(#01B3F4, #044CAB)",
                    color: "white",
                    borderRadius: "30px",
                  }}
                  size="large"
                >
                  <div className="mx-3">{t("dashboard.submit")}</div>
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
}

export default DashboardPage;
