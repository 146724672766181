import './menuItem.css';

import React from "react";

import { SvgIconProps } from '@material-ui/core';

type MenuItemProps = {
  icon : SvgIconProps,
  dispatch : Function,
  itemStateValue : boolean,
  stateData : [string, string]
}

const MenuItem = (props : MenuItemProps) => {

  const handleItemClick = () => {

    if (props.itemStateValue == true) {
      props.dispatch(props.stateData[1]);
      return;
    }

    props.dispatch(props.stateData[0]);
  }

  return (
    <div className={`menu-item-wrapper ${props.itemStateValue ? "menu-item-selected" : ""}`} onClick={handleItemClick}>
      { props.icon }
      { props.stateData[0] }
    </div>
  );
}

export default MenuItem;