import { storage } from "firebase";
import { firestore } from "firebase";
import { v4 as uuidv4 } from "uuid";
import Document from "../models/Document";
import Player from "../models/Player";

export default class DocumentService {

  public async upload(files: File[], player: Player | null) {
    try {
      files.forEach((file) => {
        var storageRef = storage().ref();
        const filename = uuidv4()+'.'+file.name.split('.').pop();

        var documentsRef = storageRef.child('attachments/'+filename);

        documentsRef.put(file).then(async (snapshot) => {
            this.addDocumentToPlayer(filename, file.name, player);
        });
      });
    } catch (e) {
      console.warn(e);
    }
  }

  public async download(document: Document) {
    try {
        var storageRef = storage().ref();

        return await storageRef.child('attachments/' + document.uid).getDownloadURL();

      } catch (e) {
        console.warn(e);
      }
  }

  public async getDownloadsUrl(documents: Document[]) {
    try {
        var storageRef = storage().ref();

        let urls: any[] = [];

        documents.forEach(async (document) => {
            urls.push(await storageRef.child('attachments/'+document.uid).getDownloadURL());
        });

        return urls;

      } catch (e) {
        console.warn(e);
      }
  }

  public async addDocumentToPlayer(path: string, realName: string, player: Player | null) {
    try {
      if (player !== null) {
        firestore().collection('players').doc(player.uid).collection('attachments').doc(path).set({realName: realName});
      }
    } catch(e) {
      console.warn(e);
    }
  }
}

