export default class User {
  private _uid: string;

  private _lastName: string;
  private _firstName: string;
  private _phone: string;

  private _isNotified: boolean;

  private _club: string;
  private _sport: string;

  private _parent: string;

  private _approved: boolean;

  private _notifications: any;

  constructor(
    uid: string,
    lastName: string,
    firstName: string,
    phone: string,
    isNotified: boolean,
    club: string,
    sport: string,
    parent: string,
    approved: boolean,
    notifications: any,
  ) {
    this._uid = uid;
    this._lastName = lastName;
    this._firstName = firstName;
    this._phone = phone;
    this._isNotified = isNotified;
    this._club = club;
    this._sport = sport;
    this._parent = parent;
    this._approved = approved;
    this._notifications = notifications;
  }

  get uid(): string {
    return this._uid;
  }

  set uid(value: string) {
    this._uid = value;
  }

  get lastName(): string {
    return this._lastName;
  }

  set lastName(value: string) {
    this._lastName = value;
  }

  get firstName(): string {
    return this._firstName;
  }

  set firstName(value: string) {
    this._firstName = value;
  }

  get phone(): string {
    return this._phone;
  }

  set phone(value: string) {
    this._phone = value;
  }

  get isNotified(): boolean {
    return this._isNotified;
  }

  set isNotified(value: boolean) {
    this._isNotified = value;
  }

  get club(): string {
    return this._club;
  }

  set club(value: string) {
    this._club = value;
  }

  get sport(): string {
    return this._sport;
  }

  set sport(value: string) {
    this._sport = value;
  }

  get parent(): string {
    return this._parent;
  }

  set parent(value: string) {
    this._parent = value;
  }

  get approved(): boolean {
    return this._approved;
  }

  set approved(value: boolean) {
    this._approved = value;
  }

  get notifications(): any {
    return this._notifications;
  }

  set notifications(value: any) {
    this._notifications = value;
  }
}
