import './compositionItem.css';

import React from "react";

type CompositonItemProps = {
  handleCompositionChange : Function,
  index : number,
  image : string
}

const CompositionItem = (props : CompositonItemProps) : JSX.Element => {
  return (
    <div className="composition-item-wrapper" onClick={ () => { props.handleCompositionChange(props.index) }}>
      <img src={props.image}/>
    </div>
  );
}

export default CompositionItem;