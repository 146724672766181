import React from 'react';
import Category from "../../../../core/models/Category";
import PlayerCriteriaComponent from "./PlayerCriteriaComponent";
import { Typography } from '@material-ui/core';

type PlayerCriteriasPros = {
    category: Category | null;
}

function PlayerCriteriasComponent({category}: PlayerCriteriasPros) {
    return <div className="d-flex flex-column justify-content-start">
        { 
            category?.items.map((item, index1) => {
                return <div key={index1 + item.title}>
                    <div className="d-flex flex-row justify-content-start pb-1">
                        <Typography variant="h6" color="textPrimary">{item.title}</Typography>
                    </div>
                    <div className="d-flex flex-row justify-content-start">
                    {
                        item.criterias.map((criteria, index2) => {
                            return <div className="p-4" key={index2 + criteria.title + item.title + index1}><PlayerCriteriaComponent criteria={criteria} /></div>
                        })
                    }
                    </div>
                </div>
            })
        }
    </div>;
}

export default PlayerCriteriasComponent;