import './dropZone.css';

import React, { DragEvent, useContext, useState, useEffect } from "react";
import { BoardContext } from '../../pages/BoardPage/boardProvider';
import { IComposition } from '../../pages/BoardPage/board';
import { IPlayerWrapper } from '../Player/PlayerBoard/playerList';
import Badge from '../../../core/models/Badge';
import BadgesFactory from '../../pages/BoardPage/badges.factory';
import FlagService from '../../../core/service/flag.service';
import PlayerService from '../../../core/service/player.service';

type DropZoneProps = {
  player : any,
  composition : IComposition | null,
  setComposition : Function,
  index : number
}

type PlayerDropItemProps = {
  composition : IComposition | null,
  playerIndex : number
  index : number,
  players : IPlayerWrapper[],
  setPlayer : Function,
  item : IPlayerWrapper
}

type PlayerPopupInformationProps = {
  wrapper : IPlayerWrapper,
}

function getGradeImage(grade : number) : string
{
  switch (grade)
  {
    case 0.5 : return ("/stars/0-5.png");
    case 1 : return ("/stars/1.png");
    case 1.5 : return ("/stars/1-5.png");
    case 2 : return ("/stars/2.png");
    case 2.5 : return ("/stars/2-5.png");
    case 3 : return ("/stars/3.png");
    case 3.5 : return ("/stars/3-5.png");
    case 4 : return ("/stars/4.png");
    case 4.5 : return ("/stars/4-5.png");
    case 5 : return ("/stars/5.png");
  }

  return ("");
}

const PlayerPopupInformation = (props : PlayerPopupInformationProps) : JSX.Element => {

  return (
    <div className="player-popup-information-wrapper">
      <div className="player-popup-information-top-wrapper">
        <div className="player-popup-information-column-wrapper">
          <p>{ props.wrapper.player["_marketValue"] === "" ? "N/A" : props.wrapper.player["_marketValue"] }</p>
          <img src={FlagService.getFlag(props.wrapper.player!["_citizenship"]) !== "Error" ? "https://www.countryflags.io/" + FlagService.getFlag(props.wrapper.player!["_citizenship"]) + "/flat/24.png" : ""} />
        </div>
        <img className="player-popup-information-profile" src={ props.wrapper.player.avatar ? "https://firebasestorage.googleapis.com/v0/b/invister-1efa6.appspot.com/o/avatars%2F" + props.wrapper.player.avatar + "?alt=media" : "/unknown.png" }/>
        <div className="player-popup-information-column-wrapper">
          <img className="player-popup-information-column-start" src={ getGradeImage(props.wrapper.player["_grade"]) }/>
          <p style={{ fontSize : 10, color : "#09FF00" }}>{ (props.wrapper.player!["_grade"] >= 0 && props.wrapper.player!["_grade"] <= 5) ? props.wrapper.player!["_grade"] : "N/A" }</p>
        </div>
      </div>
      <div className="player-popup-information-bottom-wrapper">
      <table>
        <tbody>
          <tr>
            <td className="player-popup-information-bottom-left">Nom Prénom</td>
            <td className="player-popup-information-bottom-right">{ props.wrapper.player["_lastName"] } { props.wrapper.player["_firstName"] }</td>
          </tr>
          <tr>
            <td className="player-popup-information-bottom-left-little">Age</td>
            <td className="player-popup-information-bottom-right">{ PlayerService.getPlayerAge(props.wrapper) }</td>
          </tr>
          <tr>
            <td className="player-popup-information-bottom-left-little">Poste</td>
            <td className="player-popup-information-bottom-right">{ props.wrapper.player!["_post"] === "" ? "N/A" : props.wrapper.player!["_post"] }</td>
          </tr>
          <tr>
            <td className="player-popup-information-bottom-left-little">Club</td>
            <td className="player-popup-information-bottom-right">{ props.wrapper.player["_club"] === "" ? "N/A" : props.wrapper.player!["_club"] }</td>
          </tr>
        </tbody>
      </table>
      </div>
    </div>
  );
}

const PlayerDropItem = (props : PlayerDropItemProps) : JSX.Element => {

  const { setIndex, setSave } = useContext(BoardContext);
  const [more, setMore] = useState(false);

  const drop = (event : DragEvent) : void => {

    event.preventDefault();

    if (event.dataTransfer.types[0] === "wrappper")
    {
      return;
    }

    var badge : Badge = JSON.parse(event.dataTransfer.getData("badge"));
    var tmp : IPlayerWrapper[] = Object.assign([], props.players);

    if (tmp[props.index].badges.find((element : Badge) => element["_uid"] === badge["_uid"]) !== undefined)
    {
      return;
    }

    tmp[props.index]["badges"].push(badge);
    props.composition!.players[props.playerIndex]!.selected[props.item.index]["badges"]!.push(badge);

    event.dataTransfer.clearData("badges");

    tmp[props.index]["badges"] = removeDublicatedBadge(tmp[props.index]["badges"]);
    props.composition!.players[props.playerIndex]!.selected[props.item.index]["badges"] = removeDublicatedBadge(props.composition!.players[props.playerIndex]!.selected[props.item.index]["badges"]);
    
    setSave(true);
    props.setPlayer(tmp);
    setIndex(props.playerIndex);
  }

  function removeDublicatedBadge(badgesList : Badge[]) : Badge[]
  {
    return (badgesList.filter((item : Badge, index : number) => badgesList.indexOf(item) === index));
  }

  const dragOver = (event : DragEvent) : void => {

    event.preventDefault();
  }

  const handleClass = () : string => {

    switch (props.index)
    {
      case 0 : return (props.players.length > 1 ? "drop-zone-result-item-more" : "drop-zone-result-item");
      case 1 : return (props.players.length  === 2 ? "drop-zone-result-item-2 last" : "drop-zone-result-item-2 center");
      case 2 : return ("drop-zone-result-item-2 last");
    }

    return ("");
  }
  
  return (
    <div className={handleClass()} onDrop={drop} onDragOver={dragOver} onMouseEnter={ () => setMore(true)} onMouseLeave={ () => setMore(false) }>
      <div className={`${ props.index === 0 ? "profile-first" : "profile-second" }`}>
        <img src={ props.item!.player["_avatar"] ? "https://firebasestorage.googleapis.com/v0/b/invister-1efa6.appspot.com/o/avatars%2F" + props.item!.player["_avatar"] + "?alt=media" : "/unknown.png" } />
      </div>
      { (props.index === 0 && props.item.player["_grade"]) && <img className="grade-class-dropzone" src={ getGradeImage(props.item.player["_grade"]) } />}
      <div className="drop-zone-player-size">
        { props.item.display.age === true && <p style={{ color : "white" }}>{ PlayerService.getPlayerAge(props.item) }</p> }
        { props.item.display.origine === true && <img src={FlagService.getFlag(props.item?.player!["_citizenship"]) !== "Error" ? "https://www.countryflags.io/" + FlagService.getFlag(props.item?.player!["_citizenship"]) + "/flat/16.png" : ""} /> }
      </div>
      <div className="drop-zone-player-badges">
        <div className="drop-zone-badges-list-name">
          <p style={{ color : "white" }}>{ props.item?.player["_lastName"]! }</p>
        </div>
        <div className="drop-zone-badges-list">
          { (props.item && props.item?.display?.badges === true) && props.item!["badges"]?.map((badge : Badge, index : number) => index < 3 && <div key={index}>{ BadgesFactory.CreateBadgeFromObject(badge) }</div> )}
        </div>
      </div>
      <div className="drop-zone-player-size">
        <p style={{ color : "#09FF00" } }>{ (props.item?.player["_grade"]! >= 0 && props.item?.player["_grade"]! <= 5) ? props.item?.player["_grade"]! : "N/A" }</p>
        { props.item.display.valeur === true && <p style={{ color : "white" }}>{ props.item?.player["_marketValue"]! }</p> }
      </div>
      { more && <PlayerPopupInformation wrapper={props.item}/> }
    </div>
  );
}

const DropZone = (props : DropZoneProps) => {

  const { index, setIndex } = useContext(BoardContext);
  const [players, setPlayers] = useState<IPlayerWrapper[]>(props.player.selected);

  useEffect(() => {
    setPlayers(props.composition!.players[props.index]["selected"]);
  }, [props.player.selected, props.composition]);

  return (
    <div style={{ height : 30,  width : 30, gridColumnStart : props.player.x, gridRowStart : props.player.y, display : "flex", justifyContent : "center", alignItems : "center" }}>
      <div className={`drop-zone-wrapper ${ index === props.player.index ? "drop-zone-wrapper-enable" : "drop-zone-wrapper-desable" }`} onClick={ () => setIndex(props.player.index) }>
        <div className="drop-zone-result-wrapper">
          { players && players.map((item : IPlayerWrapper, indexx : number) => (indexx < 2) && <PlayerDropItem players={players} setPlayer={setPlayers} index={indexx} item={item} composition={props.composition} key={indexx} playerIndex={props.index} /> )}
        </div>
      </div>
    </div>
  );
}

export default DropZone;