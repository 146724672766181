import React, { useEffect, useState } from "react";
import Player from "../../../../core/models/Player";
import { firestore } from "firebase";
import ModelFactory from "../../../../core/service/model.factory";
import Note from "../../../../core/models/Note";
import { Divider, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import CustomTextField from "../../CustomTextField/CustomTextField";

type PlayerNotesPros = {
  player: Player | null;
};

function PlayerNoteList({ player }: PlayerNotesPros) {
  const { t } = useTranslation();

  const [notes, setNotes] = useState<Note[]>([]);
  const [selectedNote, setSelectedNote] = useState<Note | null>(null);
  const [richText, setRichText] = useState<string>("");

  useEffect(() => {
    let unsubscribe = firestore()
      .collection("players/" + player?.uid + "/notes")
      .onSnapshot((data) => {
        let n: Note[] = [];

        data.forEach((doc) => {
          let note: any = doc.data();
          note.uid = doc.id;
          n.push(ModelFactory.CreateNoteFromFirestore(note));
        });

        setNotes([...n]);
      });

    return () => {
      unsubscribe();
    };
  }, [player]);

  const handleNoteClick = (note: Note) => {
    setSelectedNote(note);
    setRichText(note.text);
  };

  const handleChangeRichText = (value: any) => {
    setRichText(value);
    handleUpdateNote(value);
  };

  const handleUpdateNote = (value: string) => {
    if (!selectedNote) return;

    firestore()
      .collection("players/" + player?.uid + "/notes")
      .doc(selectedNote?.uid)
      .update({
        text: value,
      });
  };

  if (notes.length === 0) {
    return (
      <Typography
        className="pt-3 justify-content-start align-items-start"
        color="textPrimary"
      >
        {t("playerNoteList.noReport")}
      </Typography>
    );
  }

  let textVal = richText;

  return (
    <div className="d-flex flex-column align-items-start p-5">
      {notes.map((note) => {
        return (
          <div className="p-3" key={note.uid}>
            <Typography
              style={{ cursor: "pointer" }}
              onClick={() => {
                handleNoteClick(note);
              }}
              className="pb-2"
              color={
                note.uid === selectedNote?.uid ? "secondary" : "textPrimary"
              }
            >
              {t("playerNoteList.reportOf")}&nbsp;
              {note.createdAt.toDate().toLocaleDateString()} -{" "}
              {note.createdAt.toDate().toLocaleTimeString()} -{" "}
              {t("playerNoteList.by")}&nbsp;
              {note.ownerName}
            </Typography>
            <Divider light color="#ffffff" />
          </div>
        );
      })}

      <div className="p-3 pt-3">
        <CustomTextField
          value={textVal}
          onChange={(value) => handleChangeRichText(value.target.value)}
          rowsMax={20}
          fullWidth={true}
          multiline={true}
          size="medium"
          style={{ width: "550px" }}
        />
      </div>
    </div>
  );
}

export default PlayerNoteList;
