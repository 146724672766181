import firebase from "firebase";

export default class AudioNote {
    // Ownership
    private _owner: string;
    private _ownerFullName: string;

    private _createdAt: firebase.firestore.Timestamp;
    private _path: string;


    constructor(owner: string, ownerFullName: string, createdAt: firebase.firestore.Timestamp, path: string) {
        this._owner = owner;
        this._ownerFullName = ownerFullName;
        this._createdAt = createdAt;
        this._path = path;
    }


    get owner(): string {
        return this._owner;
    }

    set owner(value: string) {
        this._owner = value;
    }

    get ownerFullName(): string {
        return this._ownerFullName;
    }

    set ownerFullName(value: string) {
        this._ownerFullName = value;
    }

    get createdAt(): firebase.firestore.Timestamp {
        return this._createdAt;
    }

    set createdAt(value: firebase.firestore.Timestamp) {
        this._createdAt = value;
    }

    get path(): string {
        return this._path;
    }

    set path(value: string) {
        this._path = value;
    }
}