import Player from "../models/Player";
import { jsPDF } from "jspdf";
import User from "../models/User";
import moment from "moment";
import ColorService from "./color.service";
import { firestore, storage } from "firebase";
import ModelFactory from "./model.factory";
import Methodology from "../models/Methodology";
import PlayerClassification from "../models/PlayerClassification";
import PlayerEvaluation from "../models/PlayerEvaluation";
import Note from "../models/Note";
import FontService from "./font.service";

export default class PdfService {
  public static async downloadPdfFromPlayer(
    player: Player,
    owner: User,
    positionName: string,
    evaluation: PlayerEvaluation
  ) {
    // Default export is a4 paper, portrait, using millimeters for units
    const doc = new jsPDF({ filters: ["ASCIIHexEncode"] });

    const amiriRegular = FontService.getOpenSansFont();
    doc.addFileToVFS("Amiri-Regular.ttf", amiriRegular);
    doc.addFont("Amiri-Regular.ttf", "Amiri", "normal");
    doc.setFont("Amiri"); // set font

    const pageHeight = doc.internal.pageSize.height;
    const pageWidth = doc.internal.pageSize.width;

    const logo = new Image();
    logo.src = "/logo_invister.jpg";

    const currentDate = moment().format("DD/MM/YYYY");

    let age: number;
    if (player != null && player.dateOfBirth) {
      const date = player.dateOfBirth.toDate();
      age = moment().diff(date, "years");
    } else {
      age = 0;
    }

    const sex = player.sex === "male" ? "Homme" : "Femme";

    let methodology: Methodology | null = null;
    const methodologyDoc = await firestore()
      .collection("methodologies")
      .doc(player.position)
      .get();
    let data = methodologyDoc.data();
    if (data) {
      data.uid = methodologyDoc.id;
      methodology = ModelFactory.CreateMethodologyFromFirestore(data);
    }

    let classification: PlayerClassification | null = null;
    const classificationDoc = await firestore()
      .collection("players_classifications")
      .doc(player.uid)
      .get();
    data = classificationDoc.data();
    if (data) {
      data.uid = classificationDoc.id;
      classification = ModelFactory.CreatePlayerClassificationFromFirestore(
        data
      );
    }

    let playerNotes: Note[] = [];
    const docs = await firestore()
      .collection("players")
      .doc(player.uid)
      .collection("notes")
      .get();

    docs.forEach((doc) => {
      data = doc.data();
      if (data) {
        data.uid = doc.id;
        playerNotes.push(ModelFactory.CreateNoteFromFirestore(data));
      }
    });

    let avatarUrl = "/placeholder_man.jpg";
    if (
      player.avatar !== null &&
      player.avatar !== undefined &&
      player.avatar !== ""
    ) {
      const avatarRef = storage().ref(`avatars/${player.avatar}`);
      avatarUrl = await avatarRef.getDownloadURL();
    }

    ////////////////
    // PREMIERE PAGE
    ////////////////

    doc.addImage(logo, pageWidth / 2 - 32 + 3, pageHeight / 2 - 30, 64, 25);

    doc.setFontSize(10);
    doc.text(
      `Rapport du ${currentDate} - Par: ${owner.firstName} ${owner.lastName}`,
      pageWidth / 2,
      pageHeight / 2,
      {
        align: "center",
      }
    );

    doc.setFontSize(20);
    doc.text(
      `${player.firstName} ${player.lastName}`,
      pageWidth / 2,
      pageHeight / 2 + 30,
      {
        align: "center",
      }
    );

    ////////////////
    // DEUXIEME PAGE
    ////////////////

    doc.addPage();

    doc.addImage(logo, pageWidth / 2 - 32 + 3, 10, 64, 25);

    doc.setFontSize(10);
    doc.text(
      `Rapport du ${currentDate} - Par: ${owner.firstName} ${owner.lastName}`,
      pageWidth / 2,
      37,
      {
        align: "center",
      }
    );

    doc.line(13, 43, pageWidth - 13, 43);

    doc.setFontSize(25);
    doc.text("Identité", 13, 70);

    const lineColor = ColorService.getColorFromGrade(player.grade);
    doc.setDrawColor(lineColor);
    doc.setLineWidth(2);
    doc.line(13, 80, 13 + 40, 80);

    const img = new Image();
    img.src = avatarUrl;
    doc.addImage(img, 13, 80, 40, 40);

    doc.setFontSize(13);
    doc.text(`${player.firstName} ${player.lastName}`, 13 + 40 + 5, 80 + 5);
    doc.setFontSize(10);
    doc.text(`${age}`, 13 + 40 + 5, 80 + 10);
    doc.text(`${positionName}`, 13 + 40 + 5, 80 + 15);
    doc.text(`${player.club}`, 13 + 40 + 5, 80 + 20);

    doc.setTextColor(lineColor);
    doc.setFontSize(20);
    if (player.grade === -1) {
      doc.text(`?`, 13 + 40 + 5, 80 + 40);
    } else {
      let grade = Math.round(player.grade * 100) / 100;
      doc.text(`${grade}`, 13 + 40 + 5, 80 + 40);
    }
    doc.setTextColor("#000");
    doc.setFontSize(10);
    doc.text(`/5`, 13 + 40 + 19, 80 + 40);

    doc.setFontSize(10);
    doc.text(`Prénom: ${player.firstName}`, 13, 120 + 20);
    doc.text(`Nom: ${player.lastName}`, 13, 120 + 30);
    doc.text(`Lieu de naissance: ${player.placeOfbirth}`, 13, 120 + 40);
    doc.text(`Âge: ${age}`, 13, 120 + 50);
    doc.text(`Sexe: ${sex}`, 13, 120 + 60);
    doc.text(`Taille: ${player.size}`, 13, 120 + 70);
    doc.text(`Poids: ${player.weight}`, 13, 120 + 80);
    doc.text(`Nationalité: ${player.citizenship}`, 13, 120 + 90);
    doc.text(`Club: ${player.club}`, 13, 120 + 100);
    doc.text(`Championnat: ${player.championship}`, 13, 120 + 110);
    doc.text(`Poste: ${positionName}`, 13, 120 + 120);
    doc.text(`Pied fort: ${player.strongFoot}`, 13, 120 + 130);
    doc.text(`Blessure: ${player.injuries}`, 13, 120 + 140);

    /////////////////
    // TROISIEME PAGE
    /////////////////

    doc.addPage();

    doc.addImage(logo, 13, 2, 48, 19);

    doc.setFontSize(10);
    doc.text(
      `| Rapport du ${currentDate} - Par: ${owner.firstName} ${owner.lastName}`,
      62,
      14
    );

    doc.setDrawColor("#000");
    doc.line(13, 21, pageWidth - 13, 21);

    doc.setFontSize(25);
    doc.text("Classifications", 13, 40);
    doc.setFillColor("#000");

    if (methodology !== null && classification !== null) {
      let verticalMarginCount = 55;

      methodology.classification.forEach((item) => {
        doc.setFontSize(13);
        doc.text(item.title, 13, verticalMarginCount);

        verticalMarginCount += 8;

        let horizontalMarginCount = 15;

        item.criteria.forEach((criterion) => {
          doc.setFontSize(10);
          const textWidth = doc.getTextWidth(criterion);
          const textHeight = 6;

          if (classification?.classifications[item.title] === criterion) {
            doc.setTextColor("#fff");
            doc.roundedRect(
              horizontalMarginCount - 2,
              verticalMarginCount - textHeight + 2,
              textWidth + 4,
              textHeight,
              3,
              3,
              "F"
            );
            doc.text(criterion, horizontalMarginCount, verticalMarginCount);
            doc.setTextColor("#000");
          } else {
            doc.setTextColor("#000");
            doc.roundedRect(
              horizontalMarginCount - 2,
              verticalMarginCount - textHeight + 2,
              textWidth + 4,
              textHeight,
              3,
              3,
              "S"
            );
            doc.text(criterion, horizontalMarginCount, verticalMarginCount);
          }

          horizontalMarginCount += textWidth + 5;
        });

        if (verticalMarginCount > 265) {
          doc.addPage();
          verticalMarginCount = 10;
        }

        verticalMarginCount += 13;
      });
    }

    /////////////////
    // QUATRIEME PAGE
    /////////////////

    doc.addPage();

    doc.addImage(logo, 13, 2, 48, 19);

    doc.setFontSize(10);
    doc.text(
      `| Rapport du ${currentDate} - Par: ${owner.firstName} ${owner.lastName}`,
      62,
      14
    );

    doc.setDrawColor("#000");
    doc.line(13, 21, pageWidth - 13, 21);

    doc.setFontSize(25);
    doc.text("Renseignements", 13, 40);

    doc.setFontSize(10);
    doc.text(`Contrat: ${player.contract}`, 13, 40 + 15);
    doc.text(`Valeur marchande: ${player.marketValue}`, 13, 40 + 25);
    doc.text(`Salaire: ${player.salary}`, 13, 40 + 35);
    doc.text(`Agent: ${player.agent}`, 13, 40 + 45);

    /////////////////
    // CINQUIEME PAGE
    /////////////////

    doc.addPage();

    doc.addImage(logo, 13, 2, 48, 19);

    doc.setFontSize(10);
    doc.text(
      `| Rapport du ${currentDate} - Par: ${owner.firstName} ${owner.lastName}`,
      62,
      14
    );

    doc.setDrawColor("#000");
    doc.line(13, 21, pageWidth - 13, 21);

    doc.setFontSize(25);
    doc.text("Évaluations", 13, 40);

    if (methodology !== null) {
      let verticalMarginCount = 55;

      const categoriesTitle = ["Mental", "Athlétique", "Technique", "Tactique"];

      categoriesTitle.forEach((categoryTitle, i) => {
        doc.setFontSize(15);
        doc.text(categoryTitle, 13, verticalMarginCount);

        const textWidth = doc.getTextWidth(categoryTitle);
        doc.line(
          13,
          verticalMarginCount + 0.5,
          13 + textWidth + 0.5,
          verticalMarginCount + 0.5
        );

        if (verticalMarginCount > 265) {
          doc.addPage();
          verticalMarginCount = 10;
        }
        verticalMarginCount += 10;

        evaluation?.categories[i].items.forEach((item) => {
          doc.setFontSize(13);
          doc.text(item.title, 13 + 3, verticalMarginCount);

          if (verticalMarginCount > 250) {
            doc.addPage();
            verticalMarginCount = 10;
          }
          verticalMarginCount += 10;

          doc.setFontSize(10);

          item.criterias.forEach((criterion, y) => {
            if (y % 2 === 0) {
              doc.text(criterion.title, 13 + 3, verticalMarginCount);
              doc.line(
                13 + 3,
                verticalMarginCount + 3,
                pageWidth / 2 - 6,
                verticalMarginCount + 3
              );
              PdfService.addImageAccordingToGrade(
                doc,
                criterion.grade,
                13 + 3,
                verticalMarginCount + 6
              );
              if (y === item.criterias.length - 1) {
                if (verticalMarginCount > 250) {
                  doc.addPage();
                  verticalMarginCount = 10;
                }
                verticalMarginCount += 25;
              }
            } else {
              doc.text(criterion.title, pageWidth / 2 + 7, verticalMarginCount);
              doc.line(
                pageWidth / 2 + 7,
                verticalMarginCount + 3,
                pageWidth - 13,
                verticalMarginCount + 3
              );
              PdfService.addImageAccordingToGrade(
                doc,
                criterion.grade,
                pageWidth / 2 + 7,
                verticalMarginCount + 6
              );
              if (verticalMarginCount > 250) {
                doc.addPage();
                verticalMarginCount = 10;
              }
              verticalMarginCount += 25;
            }
          });
        });
      });
    }

    /////////////////
    // SIXIEME PAGE
    /////////////////

    doc.addPage();

    doc.addImage(logo, 13, 2, 48, 19);

    doc.setFontSize(10);
    doc.text(
      `| Rapport du ${currentDate} - Par: ${owner.firstName} ${owner.lastName}`,
      62,
      14
    );

    doc.setDrawColor("#000");
    doc.line(13, 21, pageWidth - 13, 21);

    doc.setFontSize(25);
    doc.text("Observations écrites", 13, 40);

    let verticalMarginCount = 55;

    doc.setFontSize(10);
    if (playerNotes != null) {
      let text = [] as any[];

      playerNotes.forEach((note) => {
        let editDate = moment(note.createdAt.toDate()).format("DD/MM/YYYY");

        text = text.concat("Rapport du " + editDate + ": ");

        const splitNote = doc.splitTextToSize(note.text, pageWidth - 13 * 2);
        splitNote.forEach((n: string) => text.push(n));

        text.push("");
      });

      doc.text(text, 13, verticalMarginCount);
    }

    ///////
    // FINI
    ///////

    doc.save(`rapport_${player.firstName}_${player.lastName}.pdf`);
  }

  private static addImageAccordingToGrade(
    doc: jsPDF,
    grade: number,
    x: number,
    y: number
  ) {
    const star = new Image();

    switch (grade) {
      case 0.5:
        star.src = "/stars/0-5.png";
        doc.addImage(star, x, y, 3, 6);
        break;

      case 1:
        star.src = "/stars/1.png";
        doc.addImage(star, x, y, 6, 6);
        break;

      case 1.5:
        star.src = "/stars/1-5.png";
        doc.addImage(star, x, y, 10.2, 6);
        break;

      case 2:
        star.src = "/stars/2.png";
        doc.addImage(star, x, y, 12.6, 6);
        break;

      case 2.5:
        star.src = "/stars/2-5.png";
        doc.addImage(star, x, y, 16.8, 6);
        break;

      case 3:
        star.src = "/stars/3.png";
        doc.addImage(star, x, y, 19.8, 6);
        break;

      case 3.5:
        star.src = "/stars/3-5.png";
        doc.addImage(star, x, y, 23.4, 6);
        break;

      case 4:
        star.src = "/stars/4.png";
        doc.addImage(star, x, y, 26.4, 6);
        break;

      case 4.5:
        star.src = "/stars/4-5.png";
        doc.addImage(star, x, y, 30, 6);
        break;

      case 5:
        star.src = "/stars/5.png";
        doc.addImage(star, x, y, 33, 6);
        break;
    }
  }
}
