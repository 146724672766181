import React from "react";
import "./LandingPage.css";
import "./LandingPage_mobile.css";
import { Button, Typography, Fab } from "@material-ui/core";
import { Link } from "react-router-dom";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { ReactComponent as Logo } from "../../../assets/logo_invister_name.svg";
import whiteLogo from "../../../assets/logo_invister_blanc.svg";
import FlagIcon from "../../components/FlagButton/FlagIcon";
import { useTranslation } from "react-i18next";
import { CgvuLink } from "../../components/Cgvu/CgvuLink";

const LandingPage = () => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <img src="/LandingPage.png" id="bg" alt=""></img>
      <img src="/landingpage_mobile.png" id="bg_mobile" alt=""></img>
      <div className="mt-8">
        <div className="d-flex flex-row-reverse pt-2 pr-2 pt-sm-4 pr-sm-4 justify-content-between justify-content-sm-start">
          <FlagIcon />
          <Link
            to="/login"
            style={{ color: "white", textDecoration: "none" }}
            className="d-sm-block d-none"
          >
            <Button
              className="ml-2 my-2"
              variant="outlined"
              style={{
                color: "white",
                textTransform: "capitalize",
                border: "1px solid rgba(255, 255, 255, 1)",
                borderRadius: "30px",
              }}
            >
              <div className="mx-3"> {t("login.Se_connecter")}</div>
            </Button>
          </Link>
          <Button
            className="d-sm-block d-none"
            style={{
              color: "white",
              textTransform: "capitalize",
              textDecoration: "underline",
            }}
          >
            {t("landing.Contactez-nous")}
          </Button>
          <img
            src={whiteLogo}
            alt=""
            width="170"
            className="d-block d-sm-none pl-4"
          ></img>
        </div>

        <div className="d-flex flex-column">
          <Logo
            style={{
              top: "-3em",
              marginTop: "-1.4rem",
              marginLeft: "-7rem",
            }}
            width="50rem"
            height="5rem"
            className="d-sm-block d-none"
          />

          <p
            className="text-left font-weight-bold d-sm-block d-none"
            style={{
              fontSize: "13px",
              top: "-3em",
              marginTop: "-1.4rem",
              marginLeft: "11.9rem",
            }}
          >
            working for tomorrow
          </p>
        </div>

        <div
          className="row justify-content-end"
          style={{ marginTop: "11%", marginLeft: "2%", marginRight: "15px" }}
        >
          <div className="d-flex flex-column align-items-start col-sm-6">
            <Typography
              className="d-sm-block d-none"
              variant="h3"
              color="textPrimary"
              style={{
                fontWeight: "bold",
                fontStyle: "italic",
                textAlign: "start",
              }}
            >
              {t("landing.title1")}
            </Typography>
            <Typography
              className="d-sm-block d-none"
              variant="h3"
              color="textPrimary"
              style={{
                fontWeight: "bold",
                fontStyle: "italic",
                textAlign: "start",
              }}
            >
              {t("landing.title2")}
            </Typography>

            <Typography
              className="d-block d-sm-none"
              variant="h5"
              color="textPrimary"
              style={{
                fontWeight: "bold",
                fontStyle: "italic",
                textAlign: "start",
              }}
            >
              {t("landing.subtitle1")}
            </Typography>

            <Typography
              variant="body2"
              color="textPrimary"
              style={{ textAlign: "start" }}
            >
              {t("landing.subtitle2")}
            </Typography>

            <Link
              to="/signup"
              style={{ color: "white", textDecoration: "none" }}
              className="d-sm-block d-none"
            >
              <Fab
                variant="extended"
                className="mt-4"
                size="small"
                style={{
                  background: "linear-gradient(#01B3F4, #044CAB)",
                  color: "white",
                }}
              >
                <span className="ml-1">
                  &nbsp;{t("landing.bulletp1")}&nbsp;
                </span>
              </Fab>
            </Link>

            <Typography
              variant="body2"
              color="textPrimary"
              style={{ textAlign: "start" }}
              className="mt-3 d-block d-sm-none"
            >
              <CheckCircleOutlineIcon />
              &nbsp;{t("landing.bulletp2")}
            </Typography>

            <Typography
              variant="body1"
              color="textPrimary"
              style={{ textAlign: "start" }}
              className="mt-3 d-block d-sm-none"
            >
              <CheckCircleOutlineIcon />
              &nbsp;{t("landing.bulletp3")}
            </Typography>

            <Typography
              variant="body1"
              color="textPrimary"
              style={{ textAlign: "start" }}
              className="mt-3 d-block d-sm-none"
            >
              <CheckCircleOutlineIcon />
              &nbsp;{t("landing.bulletp4")}
            </Typography>

            <Typography
              variant="body1"
              color="textPrimary"
              style={{ textAlign: "start" }}
              className="mt-3 d-block d-sm-none"
            >
              <CheckCircleOutlineIcon />
              &nbsp;{t("landing.bulletp5")}
            </Typography>

            <Link
              to="/signup"
              style={{ color: "white", textDecoration: "none" }}
              className="d-block d-sm-none"
            >
              <Fab
                variant="extended"
                className="mt-4"
                size="small"
                style={{
                  background: "linear-gradient(#01B3F4, #044CAB)",
                  color: "white",
                  textTransform: "initial",
                }}
              >
                <span className="ml-1">{t("landing.bulletp1")}</span>
              </Fab>
            </Link>

            <Typography
              variant="body1"
              color="textPrimary"
              style={{ textAlign: "start" }}
              className="mt-4 ml-4 d-sm-block d-none"
            >
              <CheckCircleOutlineIcon />
              &nbsp;{t("landing.bulletp2")}
            </Typography>

            <Typography
              variant="body1"
              color="textPrimary"
              style={{ textAlign: "start" }}
              className="mt-2 ml-4 d-sm-block d-none"
            >
              <CheckCircleOutlineIcon />
              &nbsp;{t("landing.bulletp3")}
            </Typography>

            <Typography
              variant="body1"
              color="textPrimary"
              style={{ textAlign: "start" }}
              className="mt-2 ml-4 d-sm-block d-none"
            >
              <CheckCircleOutlineIcon />
              &nbsp;{t("landing.bulletp4")}
            </Typography>

            <Typography
              variant="body1"
              color="textPrimary"
              style={{ textAlign: "start" }}
              className="mt-2 ml-4 d-sm-block d-none"
            >
              <CheckCircleOutlineIcon />
              &nbsp;{t("landing.bulletp5")}
            </Typography>
          </div>
        </div>
      </div>
      <CgvuLink />
    </React.Fragment>
  );
};
export default LandingPage;
