import firebase, { firestore } from "firebase";
import jsPDF from "jspdf";
import moment from "moment";
import { IComposition } from "../../ui/pages/BoardPage/board";
import Player from "../models/Player";
import User from "../models/User";

import Square from '../../assets/square.svg';

import Canvg from 'canvg';

export default class BoardServicePDF
{
  public static async GenerateBoadPDF(composition : IComposition, name : string, image : string | undefined, user : User | null)
  {
    var doc : jsPDF = new jsPDF({ filters: ["ASCIIHexEncode"], orientation: 'l' });

    const pageHeight = doc.internal.pageSize.height;
    const pageWidth = doc.internal.pageSize.width;

    const currentDate = moment().format("DD/MM/YYYY");

    const logo = new Image();
    const index = new Image();

    logo.src = "./logo_invister.jpg";
    index.src = "./index.png";

    doc.rect(15, 15, pageWidth - 20, pageHeight - 44, 'D');
    doc.addImage(logo, pageWidth - 45, 3, 40, 10);

    var x : number = 15;
    var y : number = 15;

    doc.setFontSize(10);

    doc.text(`Réalisé par ${ user?.lastName }`, pageWidth / 2 + 40, 7);
    doc.text(`Le ${currentDate}`, pageWidth / 2 + 40, 12);
    doc.text(name, pageWidth / 3, 7);

    doc.setFontSize(5);

    composition.players.forEach(function(playerList)
    {
      doc.rect(x, y, (pageWidth - 20) / 11, (pageHeight - 44) / 12, 'D');
      doc.text(playerList.name, x + 2, y + ((pageHeight - 44) / 12) / 2);

      x += (pageWidth - 20) / 11;
    });

    x = 15;
    y += (pageHeight - 44) / 12;

    const max : number = BoardServicePDF.getMaxPlayerInColumn(composition.players);

    var tmp = doc;

    for (var column : number = 0; column < max; column++)
    {
      if (column == 11)
      {
        tmp = tmp.addPage();

        x = 15;
        y = 15;
        
        doc.setFontSize(10);

        tmp.text(`Réalisé par ${ user?.lastName }`, pageWidth / 2 + 40, 7);
        tmp.text(`Le ${currentDate}`, pageWidth / 2 + 40, 12);
        tmp.addImage(logo, pageWidth - 45, 3, 40, 10);
        tmp.rect(15, 15, pageWidth - 20, pageHeight - 44, 'D');

        continue;
      }

      tmp.setFontSize(10);
      tmp.setTextColor("white");
      tmp.addImage(index, 2, y + ((pageHeight - 44) / 12) / 2 - 4, 10, 10);
      tmp.text((column + 1).toString(), 6, y + ((pageHeight - 44) / 12) / 2 + 2);
      tmp.setFontSize(5);

      for (var row : number = 0; row < composition.players.length; row++)
      {
        if (composition.players[row].selected[column])
        {
          tmp.setFillColor("#1C1C1C");
        }
        else
        {
          tmp.setFillColor("white");
        }

        tmp.rect(x, y, (pageWidth - 20) / 11, (pageHeight - 44) / 12, 'FD');

        var positionDocument : firebase.firestore.DocumentSnapshot | undefined = undefined;

        if (composition.players[row].selected[column])
        {
          if (composition.players[row].selected[column].player.position)
          {
            positionDocument = await firestore().collection("methodologies").doc(composition.players[row].selected[column].player.position).get();
          }
        }

        if (composition.players[row].selected[column])
        {
          const pl : Player = composition.players[row].selected[column].player;

          tmp.setTextColor("white");
          pl.lastName ? doc.text(pl.lastName, x, y + ((pageHeight - 44) / 12) - 12) : tmp.text("N/A", x, y + ((pageHeight - 44) / 12) - 12)
          tmp.text(BoardServicePDF.getPlayerAge(pl), x, y + ((pageHeight - 44) / 12) - 8);
          pl.club ? doc.text(pl.club, x, y + ((pageHeight - 44) / 12) - 1) : tmp.text("N/A", x, y + ((pageHeight - 44) / 12) - 1)
          pl.grade ? (pl.grade < 0 || pl.grade > 5) ? tmp.text("N/A", x + (pageWidth - 20) / 11 - 4, y + ((pageHeight - 44) / 12) - 12) : tmp.text(pl.grade.toString(), x + (pageWidth - 20) / 11 - 4, y + ((pageHeight - 44) / 12) - 12) : tmp.text("N/A", x + (pageWidth - 20) / 11 - 4, y + ((pageHeight - 44) / 12) - 12)
          pl.marketValue ? doc.text(pl.marketValue,  x + (pageWidth - 20) / 11 - 4, y + ((pageHeight - 44) / 12) - 1) : tmp.text("N/A",  x + (pageWidth - 20) / 11 - 4, y + ((pageHeight - 44) / 12) - 1)

          if (positionDocument != undefined)
          {
            var data = positionDocument.data();

            if (data)
            {
              tmp.setTextColor("white");
              data.name ? doc.text(data.name, x, y + ((pageHeight - 44) / 12) - 5) : doc.text("N/A", x, y + ((pageHeight - 44) / 12) - 5)
              tmp.setTextColor("black");
            }
            else
            {
              tmp.text("N/A", x, y + ((pageHeight - 44) / 12) - 5);
            }
          }
          else
          {
            tmp.text("N/A", x, y + ((pageHeight - 44) / 12) - 5);
            tmp.setTextColor("black");
          }
        }

        x += (pageWidth - 20) / 11;
      }

      x = 15;
      y += (pageHeight - 44) / 12;
    }

    tmp = tmp.addPage();

    if (image !== undefined)
    {
      tmp.addImage(image, 0, 30, 290, 150);

      tmp.setTextColor("black");
      tmp.setFontSize(10);

      tmp.text(`Réalisé par ${ user?.lastName }`, pageWidth / 2 + 40, 7);
      tmp.text(`Le ${currentDate}`, pageWidth / 2 + 40, 12);
      tmp.addImage(logo, pageWidth - 45, 3, 40, 10);
      tmp.text(name, pageWidth / 3, 7);
    }
    
    tmp = tmp.addPage();
    
    doc = tmp;

    doc.save(`composition_${composition.name}.pdf`);
  }

  private static getMaxPlayerInColumn(players : any[]) : number
  {
    var max : number = 0;

    players.forEach(function(playerList)
    {

      if (playerList.selected.length > max)
      {
        max = playerList.selected.length;
      }

    });

    return (max);
  }

  private static getPlayerAge(player : Player) : string
  {
    var b : Date = player.dateOfBirth.toDate();
    var n : Date = new Date();

    return (n.getFullYear() - b.getFullYear()).toString();
  }
}
  