import React from "react";
import { Link } from "react-router-dom";
import { Typography } from "@material-ui/core";

export const CgvuLink = () => {
  return (
    <Link to="/cgvu">
      <Typography
        variant="body2"
        color="textPrimary"
        style={{
          position: "fixed",
          textAlign: "end",
          right: "10px",
          bottom: "10px",
          color: "rgb(255,255,255,0.5)",
        }}
      >
        CGvU - Mentions légales - All rights reserved - 2022
      </Typography>
    </Link>
  );
};
