import React, { useState, useRef } from "react";
import PlayerCriteria from "../../../../core/models/PlayerCriteria";
import { Divider, Typography, TextField } from "@material-ui/core";
import StarRatingComponent from "react-star-rating-component";
import AudioService from "../../../../core/service/audio.service";
import { Description, PlayCircleOutline, PauseCircleOutline } from "@material-ui/icons";
import ColorService from "../../../../core/service/color.service";

type PlayerCriteriaPros = {
  criteria: PlayerCriteria | null;
};

function PlayerCriteriaComponent({ criteria }: PlayerCriteriaPros) {
  const audioService = useRef(new AudioService());
  const [audioPlaying, setAudioPlaying] = useState<string | null>(null);

  const handleClickAudioNote = (path: string) => {
    if (audioPlaying !== path) playAudioNote(path);
    else stopAudioNote(path);
  };

  const playAudioNote = (path: string) => {
    audioService.current.playFromPath(path);
    setAudioPlaying(path);
  };

  const stopAudioNote = (path: string) => {
    audioService.current.stopFromPath(path);
    setAudioPlaying(null);
  };

  let starColor: string;

  if (criteria !== null) {
    if (criteria.grade !== null) {        
      starColor = ColorService.getColorFromGrade(criteria.grade);
    } else {
      starColor = "#ffffff";
    }
  } else {
    starColor = "#ffffff";
  }

  return (
    <div className="d-flex flex-column justify-content-start">
      <Typography variant="h6" color="textPrimary">
        {criteria?.title}
      </Typography>
      <div className="pt-2 pb-2">
        <Divider variant="fullWidth" light color="#ffffff" />
      </div>
      <div className="d-flex flex-row justify-content-center pb-1">
        <StarRatingComponent
          name="rate1"
          starCount={5}
          editing={false}
          value={criteria?.grade ? criteria.grade : 0}
          starColor={starColor}
          emptyStarColor="#ffffffff"
        />
      </div>
      <div className="d-flex flex-row justify-content-start">
        {criteria?.textNote !== null ? (
          <Description fontSize="small" color="secondary" />
        ) : null}
        {criteria?.audioNote !== null ? (
          <div
            style={{ cursor: "pointer" }}
            onClick={() =>
              handleClickAudioNote(
                criteria?.audioNote ? criteria.audioNote : ""
              )
            }
          >
            {audioPlaying === criteria?.audioNote ? (
              <PauseCircleOutline fontSize="small" style={{ color: "white" }} />
            ) : (
              <PlayCircleOutline fontSize="small" style={{ color: "white" }} />
            )}
          </div>
        ) : null}
      </div>
      {criteria?.textNote !== null ? (
        <TextField
          style={{
            backgroundColor: "rgba(110, 110, 110, 0.6)",
            border: "1px solid #707070",
            borderRadius: "0.6rem",
          }}
          multiline={true}
          rowsMax={4}
          size="medium"
          value={criteria?.textNote ? criteria.textNote : ""}
          className="flex-grow-1 mt-3 float-left"
        />
      ) : null}
    </div>
  );
}

export default PlayerCriteriaComponent;
