import './board.css';

import React, { useState, useEffect, useContext } from "react";

import Menu from '../../components/Menu/menu';
import SoccerField from './soccerField';
import PlayerList from '../../components/Player/PlayerBoard/playerList';

import Composition from '../../components/Composition/composition.json';
import BoardProvider from './boardProvider';
import { firestore } from 'firebase';
import Badge from '../../../core/models/Badge';
import { StoreUserContext } from '../../../core/providers/StoreUserProvider';
import ModelFactory from '../../../core/service/model.factory';

import chrome from "../../../assets/chrome.png";

import { browserName } from 'react-device-detect';

export interface IComposition {
  name : string,
  index : number,
  image : string,
  archive : string,
  players : any[],
  id : string,
  user : string,
  time : string,
  loaded : boolean
}

const Board = () => {

  var [composition, setComposition] = useState<IComposition | null>(null);
  var [badges, setBadges] = useState<Badge[]>([]);

  const user = useContext(StoreUserContext);

  const [openDisclamer, setOpenDisclamer] = useState(browserName !== "Chrome" ? true : false);

  useEffect(() => {
    if (!user) return;

    firestore().collection("badges").where("user", "==", user.uid).get().then((data) => {
      let tmp : Badge[] = [];

      data.forEach((doc) => {
        let badge : any = doc.data();
        tmp.push(ModelFactory.CreateBadgeFromFirestore(badge, doc.id));
      });

      setBadges([...tmp]);
    });

  }, [user]);

  const handleCompositionChange = (index : number) => {

    setComposition(null);

    const tmp = Composition[index];

    setComposition(JSON.parse(JSON.stringify(tmp)));
  }

  return (
    <div className="board-wrapper">
      <BoardProvider>
        <SoccerField composition={composition} setComposition={setComposition} badges={badges}/>
        <PlayerList composition={composition} setComposition={setComposition}/>
      </BoardProvider>
      <Menu handleCompositionChange={handleCompositionChange} composition={composition} badges={badges} setBadges={setBadges} setComposition={setComposition}/>
      { openDisclamer && <div className="not-chrome-disclamer">
        <div>
          <img src={chrome}/>
          <p>Merci d'utiliser chrome pour eviter les problemes de compatibilité.</p>
          <button onClick={ () => setOpenDisclamer(false) }>Continuer</button>
        </div>
      </div> }
    </div>
  );
}

export default Board;