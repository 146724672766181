import React, { useContext } from "react";
import MyAccount from "../../components/Profile/MyAccount";
import ScoutManagement from "../../components/Profile/ScoutManagement";
import { StoreUserContext } from "../../../core/providers/StoreUserProvider";

function Profile() {
  const me = useContext(StoreUserContext);

  return (
    <div
      style={{ backgroundColor: "black", height: "100%" }}
      className="d-flex flex-column pt-5 px-5"
    >
      <MyAccount />

      {me && me.parent === "none" ? <ScoutManagement /> : null}
    </div>
  );
}

export default Profile;
