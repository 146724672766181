import './compositionList.css';

import React from "react";

import Composition from './composition.json';
import CompositionItem from './compositionItem';
import { useTranslation } from 'react-i18next';

type CompositonListProps = {
  handleCompositionChange : Function
}

const CompositionList = (props : CompositonListProps) => {

  var { t } = useTranslation();
  
  return (
    <div className="composition-list-wrapper">
      <div className="composition-list-title-wrapper">
      <p>{t("boardPage.choice")}</p>
      </div>
      { Composition.map((composition, index : number) => <CompositionItem key={index} handleCompositionChange={props.handleCompositionChange} index={composition["index"]} image={composition["image"]}/>) }
    </div>
  );
}

export default CompositionList;