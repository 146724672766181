import './playerListContainer.css';

import React, { useContext, useEffect, useState } from "react";

import { ReactComponent as Button } from '../../../../assets/buttonPlayer.svg';
import { ReactComponent as RapportEcrit } from '../../../../assets/rapportEcrit.svg';
import { ReactComponent as RapportAudio } from '../../../../assets/rapportAudio.svg';
import { ReactComponent as Evaluation } from '../../../../assets/evaluation.svg';
import { ReactComponent as Enable } from '../../../../assets/disable.svg';
import { ReactComponent as Disable } from '../../../../assets/enable.svg';

import { IPlayerWrapper } from './playerList';
import Badge from '../../../../core/models/Badge';
import BadgeItem from '../../Badge/badgeItem';
import { IComposition } from '../../../pages/BoardPage/board';
import { BoardContext } from '../../../pages/BoardPage/boardProvider';
import FlagService from '../../../../core/service/flag.service';
import PlayerService from '../../../../core/service/player.service';
import { Link } from 'react-router-dom';
import CompositionService from '../../../../core/service/composition.service';
import { StoreUserContext } from '../../../../core/providers/StoreUserProvider';

import { ReactComponent as Ask } from '../../../../assets/options/question.svg';
import { ReactComponent as Transfer } from '../../../../assets/options/transfer.svg';
import { ReactComponent as Siren } from '../../../../assets/options/siren.svg';
import { ReactComponent as Eye } from '../../../../assets/options/eye.svg';
import { ReactComponent as Pen } from '../../../../assets/options/pen.svg';

type PlayerListContainerProps = {
  wrapper : IPlayerWrapper,
  setSelectedPlayer : Function,
  composition : IComposition | null,
  setComposition : Function,
}

const PlayerListContainer = (props : PlayerListContainerProps) => {

  const { index, setSave, save } = useContext(BoardContext);
  const user = useContext(StoreUserContext);

  const deleteBadges = (badge : Badge) => {

    var tmp : IComposition = Object.assign({ }, props.composition);

    tmp.players[index].selected[props.wrapper.index]["badges"] = tmp.players[index].selected[props.wrapper.index]["badges"].filter((item : Badge) => item["_uid"] !== badge["_uid"]);

    setSave(true);
    props.setComposition(tmp);
  }

  function getGradeImage(grade : number) : string
  {
    switch (grade)
    {
      case 0.5 : return ("/stars/0-5.png");
      case 1 : return ("/stars/1.png");
      case 1.5 : return ("/stars/1-5.png");
      case 2 : return ("/stars/2.png");
      case 2.5 : return ("/stars/2-5.png");
      case 3 : return ("/stars/3.png");
      case 3.5 : return ("/stars/3-5.png");
      case 4 : return ("/stars/4.png");
      case 4.5 : return ("/stars/4-5.png");
      case 5 : return ("/stars/5.png");
    }

    return ("");
  }

  function handleSave()
  {
    CompositionService.handleSaveButtonClick(props.composition, props.composition!.name, save, setSave, user)
  }

  return (
    <div className="player-list-container-wrapper">
      <div className="player-list-container-profile">
        <div className="player-list-container-profile-size">
          <p>{ props.wrapper["player"]["_marketValue"] }</p>
          <img src={FlagService.getFlag(props.wrapper.player!["_citizenship"]) !== "Error" ? "https://www.countryflags.io/" + FlagService.getFlag(props.wrapper.player!["_citizenship"]) + "/flat/64.png" : ""} />
        </div>
        <img className="profile-container" src={ props.wrapper.player["_avatar"] ? "https://firebasestorage.googleapis.com/v0/b/invister-1efa6.appspot.com/o/avatars%2F" + props.wrapper.player!["_avatar"] + "?alt=media" : "/unknown.png" } />
        <div className="player-list-container-profile-size">
          <p>{ (props.wrapper["player"]["_grade"] >= 0 && props.wrapper["player"]["_grade"] <= 5) ? props.wrapper["player"]["_grade"] : "N/A" }</p>
          <img className="player-profile-stars" src={getGradeImage(props.wrapper.player!["_grade"])} />
        </div>
      </div>
      <div className="player-list-container-information">
       <table>
         <tbody>
          <tr>
            <td className="player-list-container-information-left">Nom Prénom</td>
            <td className="player-list-container-information-right">{ props.wrapper.player["_lastName"] } { props.wrapper.player["_firstName"] }</td>
          </tr>
          <tr>
            <td className="player-list-container-information-left">Age</td>
            <td className="player-list-container-information-right">{ PlayerService.getPlayerAge(props.wrapper) }</td>
          </tr>
          <tr>
            <td className="player-list-container-information-left">Poste</td>
            <td className="player-list-container-information-right">{ props.wrapper.player!["_post"] === "" ? "N/A" : props.wrapper.player!["_post"] }</td>
          </tr>
          <tr>
            <td className="player-list-container-information-left">Club</td>
            <td className="player-list-container-information-right">{ props.wrapper.player["_club"] === "" ? "N/A" : props.wrapper.player!["_club"] }</td>
          </tr>
        </tbody>
       </table>
      </div>
      <div className="player-list-container-button">
        <Link onClick={() => handleSave() } to={"/player/" + props.wrapper.player.uid + "/notes"}><RapportEcrit /></Link>
        <Link onClick={() => handleSave() } to={"/player/" + props.wrapper.player.uid + "/audios"}><RapportAudio /></Link>
        <Link onClick={() => handleSave() } to={"/player/" + props.wrapper.player.uid + "/evaluation"}><Evaluation /></Link>
      </div>
      <div className="player-list-container-utils">
        <Ask style={{ width : 25, height : 25, fill : props.wrapper["player"]["_requestForLoan"] === true ? "#ffffff" : "#ffffff" }} />
        <Transfer style={{ width : 25, height : 25, fill : props.wrapper["player"]["_requestForTransfer"] === true ? "#ffffff" : "rgb(255, 255, 255, 0.1)" }} />
        <Siren style={{ width : 25, height : 25, fill : props.wrapper["player"]["_isUrgent"] === true ? "#ffffff" : "rgb(255, 255, 255, 0.1)" }} />
        <Eye style={{ width : 25, height : 25, fill : props.wrapper["player"]["_toWatch"] === true ? "#ffffff" : "rgb(255, 255, 255, 0.1)" }} />
        <Pen style={{ width : 25, height : 25, fill : props.wrapper["player"]["_fileReport"] === true ? "#ffffff" : "rgb(255, 255, 255, 0.1)" }} />
      </div>
      <div className="player-list-container-badges">
        <p>Badges</p>
        <div className="player-list-container-badges-list">
          { props.wrapper && props.wrapper.badges.map((badge : Badge, index : number) => <div key={index} className="badge-container"><BadgeItem key={index} badge={badge} deleteBadges={deleteBadges} /></div> )}
        </div>
      </div>
      <div className="player-list-container-display">
        <p>Afficher les Infos du joueur sur le Lineup</p>
        <div className="player-list-container-display-wrapper-button">
          <ButtonDisplay sentence="Âge" wrapper={props.wrapper} composition={props.composition} setComposition={props.setComposition} />
          <ButtonDisplay sentence="Origine" wrapper={props.wrapper} composition={props.composition} setComposition={props.setComposition} />
          <ButtonDisplay sentence="Badges" wrapper={props.wrapper} composition={props.composition} setComposition={props.setComposition} />
          <ButtonDisplay sentence="Valeur" wrapper={props.wrapper} composition={props.composition} setComposition={props.setComposition} />
        </div>
      </div>
      <button className="button-selected-close" onClick={ () => { props.setSelectedPlayer(null) }}><Button/></button>
    </div>
  );
}

type ButtonDisplayProps =  {
  sentence : string,
  wrapper : IPlayerWrapper,
  composition : IComposition | null,
  setComposition : Function
}

function ButtonDisplay(props : ButtonDisplayProps) : JSX.Element
{
  const { index } = useContext(BoardContext);

  var [enable, setEnable] = useState(true);

  useEffect(() => {
    
    switch (props.sentence)
    {
      case "Âge" : setEnable(props.composition!.players[index].selected[props.wrapper.index].display.age); break;
      case "Origine" : setEnable(props.composition!.players[index].selected[props.wrapper.index].display.origine); break;
      case "Badges" : setEnable(props.composition!.players[index].selected[props.wrapper.index].display.badges); break;
      case "Valeur" : setEnable(props.composition!.players[index].selected[props.wrapper.index].display.valeur); break;
    }

  }, []);

  function handleClick()
  {
    var tmp : IComposition = Object.assign({ }, props.composition);

    switch (props.sentence)
    {
      case "Âge" : {

        if (tmp.players[index].selected[props.wrapper.index])
        {
          tmp.players[index].selected[props.wrapper.index].display.age = !enable;
        }

      } break;
      case "Origine" : {

        if (tmp.players[index].selected[props.wrapper.index])
        {
          tmp.players[index].selected[props.wrapper.index].display.origine = !enable;
        }

      } break;
      case "Badges" : {

        if (tmp.players[index].selected[props.wrapper.index])
        {
          tmp.players[index].selected[props.wrapper.index].display.badges = !enable;
        }
        
      } break;
      case "Valeur" : {

        if (tmp.players[index].selected[props.wrapper.index])
        {
          tmp.players[index].selected[props.wrapper.index].display.valeur = !enable;
        }
        
      } break;
    }

    props.setComposition(tmp);
    setEnable(!enable);
  }

  return (
    <div className="button-display-wrapper">
      <button onClick={ () => { handleClick() } }>{ enable ? <Enable /> : <Disable /> }</button>
      <a>{ props.sentence }</a>
    </div>
  );
}

export default PlayerListContainer;