import React, { useState, useEffect, useContext, Fragment } from "react";
import Player from "../../../../core/models/Player";
import { firestore } from "firebase";
import { Formik } from "formik";
import CustomTextField from "../../CustomTextField/CustomTextField";
import { Fab, MenuItem } from "@material-ui/core";
import Methodology from "../../../../core/models/Methodology";
import ModelFactory from "../../../../core/service/model.factory";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { StoreUserContext } from "../../../../core/providers/StoreUserProvider";

type PlayerIdentityPros = {
  player: Player | null;
};

function PlayerIdentity({ player }: PlayerIdentityPros) {
  const { t } = useTranslation();

  const me = useContext(StoreUserContext);

  const [methodologies, setMethodologies] = useState<Methodology[] | null>(
    null
  );

  useEffect(() => {
    if (!me) return;

    const treePath = me!.parent === "none" ? `${me!.uid}` : `${me!.parent}_${me!.uid}`;

    const hsxId: string =
      me.parent === "none" ? me.uid : me.parent.split("_")[0];

      console.log(me.parent)

    firestore()
      .collection("methodologies")
      .where("owner", ">=", treePath).where("owner", "<=", treePath + "~")
      .get()
      .then((data) => {
        let m: Methodology[] = [];

        data.forEach((doc) => {
          let methodology: any = doc.data();
          console.log(methodology);
          methodology.uid = doc.id;
          m.push(ModelFactory.CreateMethodologyFromFirestore(methodology));
        });

        setMethodologies([...m]);
      });
  }, [me]);

  if (!player || !methodologies) return <Fragment></Fragment>;

  return (
    <div>
      <Formik
        enableReinitialize={true}
        initialValues={{
          lastName: player.lastName,
          firstName: player.firstName,
          injuries: player.injuries,
          club: player.club,
          championship: player.championship,
          citizenship: player.citizenship,
          placeOfbirth: player.placeOfbirth,
          position: player.position,
          sex: player.sex,
          size: player.size,
          strongFoot: player.strongFoot,
          weight: player.weight,
          dateOfBirth: moment(player.dateOfBirth.toDate()).format("YYYY-MM-DD"),
        }}
        onSubmit={async (values, { setSubmitting }) => {
          let dateOfBirth;

          if (values.dateOfBirth && values.dateOfBirth !== "")
            dateOfBirth = firestore.Timestamp.fromDate(
              new Date(values.dateOfBirth)
            );
          else dateOfBirth = firestore.Timestamp.fromDate(new Date());

          await firestore().collection("players").doc(player?.uid).update({
            lastName: values.lastName,
            firstName: values.firstName,
            injuries: values.injuries,
            club: values.club,
            championship: values.championship,
            citizenship: values.citizenship,
            placeOfBirth: values.placeOfbirth,
            position: values.position,
            sex: values.sex,
            size: values.size,
            strongFoot: values.strongFoot,
            weight: values.weight,
            dateOfBirth: dateOfBirth,
          });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="d-flex flex-column justify-content-start align-items-baseline">
              <div className="d-flex flex-row justify-content-start">
                <div className="d-flex flex-column align-items-start">
                  <div className="p-4">
                    <CustomTextField
                      label={t("playerIdentity.firstName")}
                      type="text"
                      name="firstName"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={isSubmitting}
                      value={values.firstName}
                    />
                  </div>
                  <div className="p-4">
                    <CustomTextField
                      label={t("playerIdentity.lastName")}
                      type="text"
                      name="lastName"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={isSubmitting}
                      value={values.lastName}
                    />
                  </div>
                  <div className="p-4">
                    <CustomTextField
                      label={t("playerIdentity.POB")}
                      type="text"
                      name="placeOfbirth"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={isSubmitting}
                      value={values.placeOfbirth}
                    />
                  </div>
                  <div className="p-4">
                    <CustomTextField
                      label={t("playerIdentity.DOB")}
                      type="date"
                      name="dateOfBirth"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={isSubmitting}
                      value={values.dateOfBirth}
                    />
                  </div>
                  <div className="p-4">
                    <CustomTextField
                      select
                      label={t("playerIdentity.sex")}
                      name="sex"
                      value={values.sex}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={isSubmitting}
                    >
                      <MenuItem
                        key="male"
                        value="male"
                        style={{ color: "black" }}
                      >
                        {t("playerIdentity.male")}
                      </MenuItem>
                      <MenuItem
                        key="female"
                        value="female"
                        style={{ color: "black" }}
                      >
                        {t("playerIdentity.female")}
                      </MenuItem>
                    </CustomTextField>
                  </div>
                  <div className="p-4">
                    <CustomTextField
                      label={t("playerIdentity.size")}
                      type="text"
                      name="size"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={isSubmitting}
                      value={values.size}
                    />
                  </div>
                  <div className="p-4">
                    <CustomTextField
                      label={t("playerIdentity.weight")}
                      type="text"
                      name="weight"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={isSubmitting}
                      value={values.weight}
                    />
                  </div>
                </div>
                <div className="d-flex flex-column align-items-start">
                  <div className="p-4">
                    <CustomTextField
                      label={t("playerIdentity.citizenship")}
                      type="text"
                      name="citizenship"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={isSubmitting}
                      value={values.citizenship}
                    />
                  </div>
                  <div className="p-4">
                    <CustomTextField
                      label={t("playerIdentity.club")}
                      type="text"
                      name="club"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={isSubmitting}
                      value={values.club}
                    />
                  </div>
                  <div className="p-4">
                    <CustomTextField
                      label={t("playerIdentity.championship")}
                      type="text"
                      name="championship"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={isSubmitting}
                      value={values.championship}
                    />
                  </div>
                  <div className="p-4">
                    <CustomTextField
                      select
                      label={t("playerIdentity.position")}
                      name="position"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={isSubmitting}
                      value={values.position}
                    >
                      {methodologies.map((option) => (
                        <MenuItem
                          key={option.uid}
                          value={option.uid}
                          style={{ color: "black" }}
                        >
                          {option.name}
                        </MenuItem>
                      ))}
                    </CustomTextField>
                  </div>
                  <div className="p-4">
                    <CustomTextField
                      label={t("playerIdentity.strongFoot")}
                      type="text"
                      name="strongFoot"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={isSubmitting}
                      value={values.strongFoot}
                    />
                  </div>
                  <div className="p-4">
                    <CustomTextField
                      label={t("playerIdentity.injuries")}
                      type="text"
                      name="injuries"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={isSubmitting}
                      value={values.injuries}
                    />
                  </div>
                </div>
              </div>
              <div className="p-4">
                <Fab
                  type="submit"
                  variant="extended"
                  className="ml-4"
                  size="small"
                  style={{
                    background: "linear-gradient(#01B3F4, #044CAB)",
                    color: "white",
                  }}
                >
                  <span style={{ fontSize: 12 }}>
                    &nbsp;{t("playerIdentity.save")}&nbsp;
                  </span>
                </Fab>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}

export default PlayerIdentity;
