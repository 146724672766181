import React, { useState } from "react";
import Wishlist from "../../../core/models/Wishlist";
import {
  Typography,
  IconButton,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import { firestore } from "firebase";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

type WishlistItemProps = {
  wishlist: Wishlist;
};

function WishlistItem({ wishlist }: WishlistItemProps) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteWishlist = () => {
    firestore().collection("wishlists").doc(wishlist.uid).delete();
  };

  return (
    <Link
      to={`/wishlist/${wishlist.uid}`}
      className="d-flex justify-content-between mt-4 ml-4 col-3"
      style={{
        backgroundColor: "#151515",
        borderRadius: "12px",
        border: "1px solid #dee2e6",
        textDecoration: "none",
      }}
    >
      <div className="d-flex flex-column">
        <Typography
          variant="body2"
          color="textPrimary"
          align="left"
          style={{ fontWeight: "bold" }}
          className="mt-3 mb-1"
        >
          {wishlist.name}
        </Typography>
        <Typography
          variant="body2"
          color="textPrimary"
          className="mb-3"
          align="left"
        >
          {wishlist.description}
        </Typography>
      </div>

      <IconButton
        aria-label="delete"
        className=""
        style={{ color: "white" }}
        onClick={(e) => {
          e.preventDefault();
          setOpen(true);
        }}
      >
        <Delete />
      </IconButton>

      {/* Delete wishlist confirmation dialog */}

      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            backgroundColor: "black",
            boxShadow: "0px 0px 15px 2px rgba(255,252,255,1)",
          },
        }}
      >
        <DialogContent>
          <DialogContentText color="textPrimary">
            {t("wishlistItem.confirm")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(e) => {
              e.preventDefault();
              handleClose();
            }}
            style={{ color: "white" }}
          >
            {t("wishlistItem.no")}
          </Button>
          <Button
            onClick={(e) => {
              e.preventDefault();
              handleDeleteWishlist();
              handleClose();
            }}
            style={{ color: "white" }}
          >
            {t("wishlistItem.yes")}
          </Button>
        </DialogActions>
      </Dialog>
    </Link>
  );
}

export default WishlistItem;
