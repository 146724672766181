import './soccerField.css';
import React, { useContext, useState, useEffect, useRef } from "react";
import useComposition from '../../../core/utils/useComposition';
import DropZone from '../../components/DropZone/dropZone';
import SoccerFieldInput from './soccerFieldInput';
import Badge from '../../../core/models/Badge';
import useBadges from '../../../core/utils/useBadges';
import BadgeItemDroppable from '../../components/Badge/badgeItemDroppable';
import { StoreUserContext } from '../../../core/providers/StoreUserProvider';
import { IComposition } from './board';
import { useTranslation } from 'react-i18next';
import { BoardContext } from './boardProvider';
import CompositionService from '../../../core/service/composition.service';

type SoccerFieldProps = {
  composition : IComposition | null,
  badges : Badge[],
  setComposition : Function
}

const SoccerField = (props : SoccerFieldProps) => {
  
  var { composition } = useComposition(props.composition);
  var { badges } = useBadges(props.badges);

  var [compositioName, setCompositionName] = useState("");
  const user = useContext(StoreUserContext);

  var { save, setSave, setIndex } = useContext(BoardContext);

  var { t } = useTranslation();

  var ref = useRef(null);

  useEffect(() => {
    
    if (composition)
    {
      composition.name = compositioName;
    }

  }, [compositioName]);

  const handleNewButtonClick = () => {
    setIndex(-1);
    setSave(false);
    props.setComposition(null);
  }

  return (
    <div className="soccer-field-wrapper">
      <div className="soccer-field-top-wrapper" ref={ref}>
        <SoccerFieldInput reff={ref} composition={props.composition} compositioName={compositioName} setCompositionName={setCompositionName} />
        <div className="soccer-field-grid-wrapper">
          { composition?.players && composition?.players.map((player : any, index : number) => <DropZone index={index} setComposition={props.setComposition} key={index} player={player} composition={composition}/>) }
        </div>
      </div>
      <div className="soccer-field-bottom-wrapper">
        <div className="soccer-field-bottom-badges-wrapper">
          <div className="soccer-field-bottom-badges-list-wrapper">
            {  badges &&  badges?.map((badge : Badge, index : number) => <BadgeItemDroppable key={index} badge={badge}/>) }
          </div>
        </div>
        <button className={ save === false ? "not-save-button" : "save-button" } onClick={ () => { CompositionService.handleSaveButtonClick(composition, compositioName, save, setSave, user) }}>{t("boardPage.save")}</button>
        <button className="new-button" onClick={ () => { handleNewButtonClick() }}>{t("boardPage.new")}</button>
      </div>
    </div>
  );
}

export default SoccerField;