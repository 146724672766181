import './menu.css';

import React, { useReducer } from "react";

import { ReactComponent as ArchiveSVG } from '../../../assets/archive.svg';
import { ReactComponent as CompoSVG } from '../../../assets/compo.svg';
import { ReactComponent as ComsSVG } from '../../../assets/coms.svg';
import { ReactComponent as BadgesSVG } from '../../../assets/badges.svg';

import MenuItem from './menuItem';
import MenuContainer from './menuContainer';
import Badge from '../../../core/models/Badge';
import { IComposition } from '../../pages/BoardPage/board';

export const menuInitialState = {
  archiveState : false,
  compoState : false,
  comsState : false,
  badgesState : false,
  openState : false,
}

const menuReducer = (state : any, action: string) => {
  switch (action) {
    case "Archives" : return {archiveState: true, openState: true, badgesState: false, compoState: false, comsState : false};
    case "archiveClose" : return {archiveState: false, openState: false, badgesState: false, compoState: false, comsState : false};
    case "Compo" : return {archiveState: false, openState: true, badgesState: false, compoState: true, comsState : false};
    case "compoClose" : return {archiveState: false, openState: false, badgesState: false, compoState: false, comsState : false};
    case "Coms" : return {archiveState: false, openState: true, badgesState: false, compoState: false, comsState : true};
    case "comsClose" : return {archiveState: false, openState: false, badgesState: false, compoState: false, comsState : false};
    case "Badges" : return {archiveState: false, openState: true, badgesState: true, compoState: false, comsState : false};
    case "badgesClose" : return {archiveState: false, openState: false, badgesState: false, compoState: false, comsState : false};
    case "openState" : return {archiveState: false, openState: false, badgesState: false, compoState: false, comsState : false};
    default : return state;
  }
}

type MenuProps = {
  handleCompositionChange : Function,
  composition : IComposition | null,
  badges : Badge[],
  setComposition : Function,
  setBadges : Function
}

const Menu = (props : MenuProps) => {

  var [state, dispatch] = useReducer(menuReducer, menuInitialState);

  return (
    <div className={`menu-wrapper ${state.openState ? "menu-wrapper-open" : "menu-wrapper-close"}`}>
      <ul>
        <MenuItem icon={<ArchiveSVG width={47} height={50}/>} dispatch={dispatch} itemStateValue={state.archiveState} stateData={["Archives", "archiveClose"]} />
        <MenuItem icon={<CompoSVG width={38} height={46}/>} dispatch={dispatch} itemStateValue={state.compoState} stateData={["Compo", "compoClose"]} />
        <MenuItem icon={<ComsSVG width={44} height={38}/>} dispatch={dispatch} itemStateValue={state.comsState} stateData={["Coms", "comsClose"]} />
        <MenuItem icon={<BadgesSVG width={31} height={47}/>} dispatch={dispatch} itemStateValue={state.badgesState} stateData={["Badges", "badgesClose"]} />
      </ul>
      <MenuContainer isOpen={state.openState} dispatch={dispatch} setBadges={props.setBadges} state={state} handleCompositionChange={props.handleCompositionChange} Composition={props.composition} badges={props.badges} setComposition={props.setComposition} />
    </div>
  );
}

export default Menu;