import { useState, useEffect } from "react";

import { firestore } from "firebase";
import ModelFactory from "../service/model.factory";
import { IComment } from "../models/Comment";
import Comment from '../models/Comment';

const useComment = (compositionId : string) => {

    var [comments, setComments] = useState<IComment[]>([]);

    useEffect(() => {

        if (compositionId === "" || compositionId === "empty") {
            return;
        }

        firestore().collection("comments").where("composition", "==", compositionId).get().then((data) => {
            let tmp : IComment[] = [];

            data.forEach((doc) => {
                let comment : any = doc.data();
                comment.id = doc.id;

                tmp.push(ModelFactory.CreateCommentFromFirestore(comment));
            });

            setComments([...tmp]);
        });
        
    }, [compositionId]);

    const addComment = async (data : string) => {

        if (compositionId === "" || compositionId === "empty") {
            return;
        }

        let comment : IComment = new Comment(compositionId, data);

        await firestore().collection("comments").add(ModelFactory.SerializeCommentToFirestore(comment)).then((data) => {
            comment.id = data.id;
        }).catch((error) => {
            console.log(error);
        });

        setComments((comments) => [...comments, comment]);
    }

    const removeComment = async (id : string) => {
        
        if (compositionId === "" || compositionId === "empty") {
            return;
        }

        await firestore().collection("comments").doc(id).delete().catch((error) => {
            console.log(error);
        });

        setComments(comments.filter(comment => comment.id !== id));
    }

    return { comments, addComment, removeComment };
}

export default useComment;