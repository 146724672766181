import React, { useContext, useEffect, useState } from "react";
import {
  Typography,
  Fab,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { firestore } from "firebase";
import ModelFactory from "../../../core/service/model.factory";
import { StoreUserContext } from "../../../core/providers/StoreUserProvider";
import { ErrorMessage, Formik } from "formik";
import CustomTextField from "../../components/CustomTextField/CustomTextField";
import Wishlist from "../../../core/models/Wishlist";
import WishlistItem from "../../components/Wishlist/WishlistItem";
import { useTranslation } from "react-i18next";

function WishlistPage() {
  const { t } = useTranslation();

  const fsUser = useContext(StoreUserContext);

  const [wishlists, setWishlists] = useState<Wishlist[] | null>(null);

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (!fsUser) return;

    let unsubscribe = firestore()
      .collection("wishlists")
      .where("user", "==", fsUser.uid)
      .onSnapshot((data) => {
        let w: Wishlist[] = [];

        data.forEach((doc) => {
          let wishlist: any = doc.data();
          wishlist.uid = doc.id;
          w.push(ModelFactory.CreateWishlistFromFirestore(wishlist));
        });

        setWishlists([...w]);
      });

    return () => {
      unsubscribe();
    };
  }, [fsUser]);

  return (
    <div
      style={{ backgroundColor: "black", height: "100%" }}
      className="pt-5 px-5"
    >
      <div className="d-flex align-items-center">
        <Typography variant="h6" color="textPrimary" align="left">
          {t("wishlist.My_favorites")}
        </Typography>
        <Fab
          variant="extended"
          className="ml-4"
          size="small"
          style={{
            background: "linear-gradient(#01B3F4, #044CAB)",
            color: "white",
          }}
          onClick={handleClickOpen}
        >
          <Add />
          <span className="ml-1" style={{ fontSize: 12 }}>
            &nbsp;{t("wishlist.Add_a_list")}&nbsp;
          </span>
        </Fab>
      </div>

      <div
        className="d-inline-flex flex-row flex-wrap pr-4 pb-4 rounded mt-3 float-left col-9"
        style={{ border: "1px solid rgb(50,50,50)" }}
      >
        {wishlists?.map((wishlist) => (
          <WishlistItem wishlist={wishlist} key={wishlist.uid}></WishlistItem>
        ))}
      </div>

      {/* Add wishlist confirmation dialog */}

      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            backgroundColor: "black",
            boxShadow: "0px 0px 15px 2px rgba(255,252,255,1)",
          },
        }}
      >
        <Formik
          initialValues={{ wishlistTitle: "", description: "" }}
          validate={(values) => {
            const errors: any = {};
            if (!values.wishlistTitle) {
              errors.wishlistTitle = t("wishlist.Mandatory_field");
            }
            if (!values.description) {
              errors.description = t("wishlist.Mandatory_field");
            }
            return errors;
          }}
          onSubmit={async (values, { setSubmitting }) => {
            await firestore().collection("wishlists").add({
              name: values.wishlistTitle,
              description: values.description,
              players: [],
              user: fsUser?.uid,
            });

            handleClose();
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <DialogTitle>{t("methodology.addlist")}</DialogTitle>
              <DialogContent>
                <div className="d-flex flex-column">
                  <CustomTextField
                    label={t("wishlist.Name_of_the_list*")}
                    type="text"
                    name="wishlistTitle"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={isSubmitting}
                    value={values.wishlistTitle}
                  />
                  <ErrorMessage
                    name="wishlistTitle"
                    render={(msg) => (
                      <span style={{ color: "red" }}>{msg}</span>
                    )}
                  />

                  <CustomTextField
                    label={t("wishlist.Description*")}
                    type="text"
                    name="description"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={isSubmitting}
                    value={values.description}
                    className="mt-3"
                  />
                  <ErrorMessage
                    name="description"
                    render={(msg) => (
                      <span style={{ color: "red" }}>{msg}</span>
                    )}
                  />
                </div>
              </DialogContent>
              <DialogActions className="justify-content-center mt-3 mb-4">
                <Button
                  type="submit"
                  variant="contained"
                  style={{
                    background: "linear-gradient(#01B3F4, #044CAB)",
                    color: "white",
                    borderRadius: "30px",
                  }}
                  size="large"
                >
                  <div className="mx-3">{t("wishlist.Submit")}</div>
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
}

export default WishlistPage;
