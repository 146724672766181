import React from "react";

import { Route, Switch, BrowserRouter } from "react-router-dom";
import LoginPage from "../../pages/Login/LoginPage";
import SignupPage from "../../pages/Signup/SignupPage";
import LandingPage from "../../pages/Landing/LandingPage";
import ForgottenPasswordPage from "../../pages/ForgottenPassword/ForgottenPasswordPage";
import { Cgvu } from "../Cgvu/Cgvu";

function ScaffoldLanding() {
  return (
    <React.Fragment>
      <BrowserRouter>
        <Switch>
          <Route path="/login">
            <LoginPage />
          </Route>
          <Route path="/signup">
            <SignupPage />
          </Route>
          <Route path="/forgotpassword">
            <ForgottenPasswordPage />
          </Route>
          <Route path="/cgvu">
            <Cgvu />
          </Route>
          <Route exact path="/">
            <LandingPage />
          </Route>
        </Switch>
      </BrowserRouter>
    </React.Fragment>
  );
}

export default ScaffoldLanding;
