import React, { useState, useEffect } from "react";
import {
  Typography,
  Button,
  withStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { TreeView, TreeItem } from "@material-ui/lab";
import Methodology from "../../../../core/models/Methodology";
import { useParams, Link, useHistory } from "react-router-dom";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { firestore } from "firebase";
import ModelFactory from "../../../../core/service/model.factory";
import { AddCircleOutlined } from "@material-ui/icons";
import { Formik, ErrorMessage } from "formik";
import CustomTextField from "../../../components/CustomTextField/CustomTextField";
import { Module } from "../../../../core/enums/module";
import Item from "../../../../core/models/Item";
import { useTranslation } from "react-i18next";

const StyledTreeItem = withStyles({
  iconContainer: {
    width: "0px",
  },
})(TreeItem);

function MethodologyDetail() {
  const { t } = useTranslation();

  // methodology

  let { methodologyId } = useParams();

  const [methodology, setMethodology] = useState<Methodology | null>(null);
  const [methodologyName, setMethodologyName] = useState<string | null>(null);

  const history = useHistory();

  useEffect(() => {
    let unsubscribe = firestore()
      .collection("methodologies")
      .doc(methodologyId)
      .onSnapshot((doc) => {
        let m: any = doc.data();
        m.uid = doc.id;
        ModelFactory.CreateMethodologyFromFirestore(m);
        setMethodology(m);
        setMethodologyName(m.name);
      });

    return () => {
      unsubscribe();
    };
  }, [methodologyId]);

  const handleSubmitMethodology = async () => {
    if (!methodology || !methodologyName) return;

    methodology.name = methodologyName;

    await firestore()
      .collection("methodologies")
      .doc(methodologyId)
      .update(ModelFactory.SerializeMethodologyToFirestore(methodology));

    history.push("/methodology");
  };

  const handleChangeMethodologyName = (newName: string) => {
    if (methodology) {
      setMethodologyName(newName);
    }
  };

  // tree view

  const [expanded, setExpanded] = React.useState<string[]>([]);
  const [selected, setSelected] = React.useState<string[]>([]);

  const handleToggle = (event: React.ChangeEvent<{}>, nodeIds: string[]) => {
    setExpanded(nodeIds);
  };

  const handleSelect = (event: React.ChangeEvent<{}>, nodeIds: string[]) => {
    setSelected(nodeIds);
  };

  // add item dialog

  const [open, setOpen] = useState(false);
  const [selectedModule, setSelectedModule] = useState<Module>(Module.TAC);

  const handleClickOpen = (module: Module) => {
    setSelectedModule(module);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // add criteria dialog

  const [openCriteria, setOpenCriteria] = useState(false);
  const [selectedModuleCriteria, setSelectedModuleCriteria] = useState<Module>(
    Module.TAC
  );
  const [selectedItemIndex, setSelectedItemIndex] = useState<number>(0);

  const handleClickOpenCriteria = (module: Module, index: number) => {
    setSelectedItemIndex(index);
    setSelectedModuleCriteria(module);
    setOpenCriteria(true);
  };

  const handleCloseCriteria = () => {
    setOpenCriteria(false);
  };

  if (!methodology || !methodologyName)
    return <React.Fragment></React.Fragment>;

  return (
    <div
      style={{ backgroundColor: "black", height: "100%" }}
      className="pt-5 pl-5"
    >
      <div className="d-flex align-items-center">
        <Typography variant="h6" color="textPrimary" align="left">
          {t("methodoDetail.methodParam")}
        </Typography>
      </div>

      <div
        className="d-inline-flex flex-column px-4 py-4 border rounded mt-3 float-left col-6"
        id="methodology-details"
      >
        <CustomTextField
          type="text"
          name="criteria"
          className="mb-5 ml-1"
          onChange={(change: React.ChangeEvent<HTMLInputElement>) =>
            handleChangeMethodologyName(change.target.value)
          }
          value={methodologyName}
        />

        <TreeView
          style={{ color: "white", textAlign: "start" }}
          className=""
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
          expanded={expanded}
          selected={selected}
          onNodeToggle={handleToggle}
          onNodeSelect={handleSelect}
        >
          {/* TACTIQUE */}

          <TreeItem nodeId="100" label="Tactique">
            {methodology?.tactic.map((item, index) => (
              <TreeItem
                nodeId={index.toString() + "itemTactic"}
                label={item.title}
                className="ml-5 pl-4"
              >
                {item.criteria.map((c, index2) => (
                  <TreeItem
                    nodeId={
                      index.toString() + index2.toString() + "criteriaTactic"
                    }
                    label={c}
                    onDoubleClick={() => console.log("toto")}
                    className="ml-5 pl-4"
                  ></TreeItem>
                ))}
                <TreeItem
                  nodeId={index.toString() + "createTactic"}
                  label={
                    <Button
                      style={{ color: "white" }}
                      size="small"
                      startIcon={<AddCircleOutlined />}
                      onClick={() => handleClickOpenCriteria(Module.TAC, index)}
                    >
                      {t("methodoDetail.newcriteria")}
                    </Button>
                  }
                  className="ml-5 pl-4"
                ></TreeItem>
              </TreeItem>
            ))}
            <StyledTreeItem
              nodeId="996"
              label={
                <Button
                  style={{ color: "white", left: "-9px" }}
                  size="small"
                  startIcon={<AddCircleOutlined />}
                  onClick={() => handleClickOpen(Module.TAC)}
                >
                  {t("methodoDetail.newitem")}
                </Button>
              }
              className="ml-5 pl-4"
            ></StyledTreeItem>
          </TreeItem>

          {/* TECHNIQUE */}

          <TreeItem nodeId="200" label="Techique">
            {methodology?.technic.map((item, index) => {
              return (
                <TreeItem
                  nodeId={index.toString() + "itemTechnic"}
                  label={item.title}
                  className="ml-5 pl-4"
                >
                  {item.criteria.map((c, index2) => (
                    <TreeItem
                      nodeId={
                        index.toString() + index2.toString() + "criteriaTechnic"
                      }
                      label={c}
                      className="ml-5 pl-4"
                    ></TreeItem>
                  ))}
                  <TreeItem
                    nodeId={index.toString() + "createTechnic"}
                    label={
                      <Button
                        style={{ color: "white" }}
                        size="small"
                        startIcon={<AddCircleOutlined />}
                        onClick={() =>
                          handleClickOpenCriteria(Module.TEC, index)
                        }
                      >
                        {t("methodoDetail.newcriteria")}
                      </Button>
                    }
                    className="ml-5 pl-4"
                  ></TreeItem>
                </TreeItem>
              );
            })}
            <StyledTreeItem
              nodeId="997"
              label={
                <Button
                  style={{ color: "white", left: "-9px" }}
                  size="small"
                  startIcon={<AddCircleOutlined />}
                  onClick={() => handleClickOpen(Module.TEC)}
                >
                  {t("methodoDetail.newitem")}
                </Button>
              }
              className="ml-5 pl-4"
            ></StyledTreeItem>
          </TreeItem>

          {/* ATHLETIQUE */}

          <TreeItem nodeId="300" label="Athlétique">
            {methodology?.athletic.map((item, index) => {
              return (
                <TreeItem
                  nodeId={index.toString() + "itemAthletic"}
                  label={item.title}
                  className="ml-5 pl-4"
                >
                  {item.criteria.map((c, index2) => (
                    <TreeItem
                      nodeId={
                        index.toString() +
                        index2.toString() +
                        "criteriaAthletic"
                      }
                      label={c}
                      className="ml-5 pl-4"
                    ></TreeItem>
                  ))}
                  <TreeItem
                    nodeId={index.toString() + "createAthletic"}
                    label={
                      <Button
                        style={{ color: "white" }}
                        size="small"
                        startIcon={<AddCircleOutlined />}
                        onClick={() =>
                          handleClickOpenCriteria(Module.ATHL, index)
                        }
                      >
                        {t("methodoDetail.newcriteria")}
                      </Button>
                    }
                    className="ml-5 pl-4"
                  ></TreeItem>
                </TreeItem>
              );
            })}
            <StyledTreeItem
              nodeId="998"
              label={
                <Button
                  style={{ color: "white", left: "-9px" }}
                  size="small"
                  startIcon={<AddCircleOutlined />}
                  onClick={() => handleClickOpen(Module.ATHL)}
                >
                  {t("methodoDetail.newitem")}
                </Button>
              }
              className="ml-5 pl-4"
            ></StyledTreeItem>
          </TreeItem>

          {/* MENTAL */}

          <TreeItem nodeId="400" label="Mental">
            {methodology?.mind.map((item, index) => {
              return (
                <TreeItem
                  nodeId={index.toString() + "itemMind"}
                  label={item.title}
                  className="ml-5 pl-4"
                >
                  {item.criteria.map((c, index2) => (
                    <TreeItem
                      nodeId={
                        index.toString() + index2.toString() + "criteriaMind"
                      }
                      label={c}
                      className="ml-5 pl-4"
                    ></TreeItem>
                  ))}
                  <TreeItem
                    nodeId={index.toString() + "createMind"}
                    label={
                      <Button
                        style={{ color: "white" }}
                        size="small"
                        startIcon={<AddCircleOutlined />}
                        onClick={() =>
                          handleClickOpenCriteria(Module.MIN, index)
                        }
                      >
                        {t("methodoDetail.newcriteria")}
                      </Button>
                    }
                    className="ml-5 pl-4"
                  ></TreeItem>
                </TreeItem>
              );
            })}
            <StyledTreeItem
              nodeId="999"
              label={
                <Button
                  style={{ color: "white", left: "-9px" }}
                  size="small"
                  startIcon={<AddCircleOutlined />}
                  onClick={() => handleClickOpen(Module.MIN)}
                >
                  {t("methodoDetail.newitem")}
                </Button>
              }
              className="ml-5 pl-4"
            ></StyledTreeItem>
          </TreeItem>

          {/* CLASSIFICATIONS */}

          <TreeItem nodeId="500" label="Classifications" className="mt-2">
            {methodology?.classification.map((item, index) => {
              return (
                <TreeItem
                  nodeId={index.toString() + "itemClassficication"}
                  label={item.title}
                  className="ml-5 pl-4"
                >
                  {item.criteria.map((c, index2) => (
                    <TreeItem
                      nodeId={
                        index.toString() +
                        index2.toString() +
                        "criteriaClassification"
                      }
                      label={c}
                      className="ml-5 pl-4"
                    ></TreeItem>
                  ))}
                  <TreeItem
                    nodeId={index.toString() + "createClassification"}
                    label={
                      <Button
                        style={{ color: "white" }}
                        size="small"
                        startIcon={<AddCircleOutlined />}
                        onClick={() =>
                          handleClickOpenCriteria(Module.CLASS, index)
                        }
                      >
                        {t("methodoDetail.newcriteria")}
                      </Button>
                    }
                    className="ml-5 pl-4"
                  ></TreeItem>
                </TreeItem>
              );
            })}
            <StyledTreeItem
              nodeId="1000"
              label={
                <Button
                  style={{ color: "white", left: "-9px" }}
                  size="small"
                  startIcon={<AddCircleOutlined />}
                  onClick={() => handleClickOpen(Module.CLASS)}
                >
                  {t("methodoDetail.newclass")}
                </Button>
              }
              className="ml-5 pl-4"
            ></StyledTreeItem>
          </TreeItem>
        </TreeView>

        {/* BUTTONS */}

        <div className="d-flex justify-content-end mt-3">
          <Button
            style={{
              background: "linear-gradient(#01B3F4, #044CAB)",
              color: "white",
            }}
            onClick={handleSubmitMethodology}
          >
            Enregistrer
          </Button>

          <Link to={`/methodology`}>
            <Button
              className="ml-3"
              style={{
                background: "red",
                color: "white",
              }}
              //   onClick={() => handleDeleteMethodology(methodology)}
            >
              Annuler
            </Button>
          </Link>
        </div>
      </div>

      {/* ITEM DIALOG */}

      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            backgroundColor: "black",
            boxShadow: "0px 0px 15px 2px rgba(255,252,255,1)",
          },
        }}
      >
        <Formik
          initialValues={{ itemTitle: "" }}
          validate={(values) => {
            const errors: any = {};
            if (!values.itemTitle) {
              errors.itemTitle = "Champs obligatoire";
            }
            return errors;
          }}
          onSubmit={async (values, { setSubmitting }) => {
            if (!methodology) return;

            let item: Item = new Item(values.itemTitle, []);
            switch (selectedModule) {
              case Module.TAC:
                methodology.tactic.push(item);
                break;
              case Module.TEC:
                methodology.technic.push(item);
                break;
              case Module.ATHL:
                methodology.athletic.push(item);
                break;
              case Module.MIN:
                methodology.mind.push(item);
                break;
              case Module.CLASS:
                methodology.classification.push(item);
                break;
            }

            setMethodology(methodology);

            handleClose();
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <DialogTitle>
                {selectedModule === Module.CLASS
                  ? t("methodoDetail.newclass")
                  : t("methodoDetail.newitem")}
              </DialogTitle>
              <DialogContent>
                <CustomTextField
                  label={
                    selectedModule === Module.CLASS
                      ? t("methodoDetail.nameclass")
                      : t("methodoDetail.nameitem")
                  }
                  type="text"
                  name="itemTitle"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                  disabled={isSubmitting}
                  value={values.itemTitle}
                />

                <ErrorMessage
                  name="itemTitle"
                  render={(msg) => <span style={{ color: "red" }}>{msg}</span>}
                />
              </DialogContent>
              <DialogActions className="justify-content-center mt-3 mb-4">
                <Button
                  type="submit"
                  variant="contained"
                  style={{
                    background: "linear-gradient(#01B3F4, #044CAB)",
                    color: "white",
                    borderRadius: "30px",
                  }}
                  size="large"
                >
                  <div className="mx-3">{t("methodoDetail.submit")}</div>
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>

      {/* CRITERIA DIALOG */}

      <Dialog
        open={openCriteria}
        onClose={handleCloseCriteria}
        PaperProps={{
          style: {
            backgroundColor: "black",
            boxShadow: "0px 0px 15px 2px rgba(255,252,255,1)",
          },
        }}
      >
        <Formik
          initialValues={{ criteria: "" }}
          validate={(values) => {
            const errors: any = {};
            if (!values.criteria) {
              errors.criteria = "Champs obligatoire";
            }
            return errors;
          }}
          onSubmit={async (values, { setSubmitting }) => {
            if (!methodology) return;

            switch (selectedModuleCriteria) {
              case Module.TAC:
                methodology.tactic[selectedItemIndex].criteria.push(
                  values.criteria
                );
                break;
              case Module.TEC:
                methodology.technic[selectedItemIndex].criteria.push(
                  values.criteria
                );
                break;
              case Module.ATHL:
                methodology.athletic[selectedItemIndex].criteria.push(
                  values.criteria
                );
                break;
              case Module.MIN:
                methodology.mind[selectedItemIndex].criteria.push(
                  values.criteria
                );
                break;
              case Module.CLASS:
                methodology.classification[selectedItemIndex].criteria.push(
                  values.criteria
                );
                break;
            }

            setMethodology(methodology);

            handleCloseCriteria();
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <DialogTitle>{t("methodoDetail.addcriteria")}</DialogTitle>
              <DialogContent>
                <CustomTextField
                  label={t("methodoDetail.namecriteria")}
                  type="text"
                  name="criteria"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                  disabled={isSubmitting}
                  value={values.criteria}
                />

                <ErrorMessage
                  name="criteria"
                  render={(msg) => <span style={{ color: "red" }}>{msg}</span>}
                />
              </DialogContent>
              <DialogActions className="justify-content-center mt-3 mb-4">
                <Button
                  type="submit"
                  variant="contained"
                  style={{
                    background: "linear-gradient(#01B3F4, #044CAB)",
                    color: "white",
                    borderRadius: "30px",
                  }}
                  size="large"
                >
                  <div className="mx-3">{t("methodoDetail.submit")}</div>
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
}

export default MethodologyDetail;
