import React, { useState } from "react";
import Player from "../../../../core/models/Player";
import { Button } from "@material-ui/core";
import {
  Assessment,
  DeveloperBoard,
  EmojiObjects,
  FitnessCenter,
} from "@material-ui/icons";
import PlayerEvaluation from "../../../../core/models/PlayerEvaluation";
import Category from "../../../../core/models/Category";
import PlayerCriteriasComponent from "../PlayerCriteria/PlayerCriteriasComponent";
import { useTranslation } from "react-i18next";

type PlayerEvaluationPros = {
  player: Player | null;
  evaluation: PlayerEvaluation | null;
};

function PlayerEvaluationPage({ player, evaluation }: PlayerEvaluationPros) {
  const { t } = useTranslation();

  const [readTactic, setReadTactic] = useState<boolean>(false);
  const [readTechnic, setReadTechnic] = useState<boolean>(false);
  const [readAthletic, setReadAthletic] = useState<boolean>(false);
  const [readMind, setReadMind] = useState<boolean>(false);

  const [category, setCategory] = useState<Category | null>(null);

  const handleTacticOnClick = () => {
    setReadTactic(true);
    setReadTechnic(false);
    setReadAthletic(false);
    setReadMind(false);

    evaluation?.categories.forEach((value) => {
      if (value.type === "tactic") {
        setCategory(value);
      }
    });
  };
  const handleTechnicOnClick = () => {
    setReadTechnic(true);
    setReadTactic(false);
    setReadAthletic(false);
    setReadMind(false);

    evaluation?.categories.forEach((value) => {
      if (value.type === "technic") {
        setCategory(value);
      }
    });
  };
  const handleAthleticOnClick = () => {
    setReadAthletic(true);
    setReadTechnic(false);
    setReadTactic(false);
    setReadMind(false);

    evaluation?.categories.forEach((value) => {
      if (value.type === "athletic") {
        setCategory(value);
      }
    });
  };
  const handleMindOnClick = () => {
    setReadMind(true);
    setReadTechnic(false);
    setReadAthletic(false);
    setReadTactic(false);

    evaluation?.categories.forEach((value) => {
      if (value.type === "mind") {
        setCategory(value);
      }
    });
  };

  return (
    <div>
      <div className="d-flex flex-column justify-content-start">
        <div className="d-flex flex-row justify-content-between p-3">
          <Button
            variant={readTactic ? "contained" : "outlined"}
            color="secondary"
            className="px-3"
            endIcon={<DeveloperBoard />}
            onClick={handleTacticOnClick}
          >
            {t("playerEvaluation.tactic")}
          </Button>

          <Button
            variant={readTechnic ? "contained" : "outlined"}
            color="secondary"
            className="px-3"
            endIcon={<Assessment />}
            onClick={handleTechnicOnClick}
          >
            {t("playerEvaluation.technic")}
          </Button>

          <Button
            variant={readAthletic ? "contained" : "outlined"}
            color="secondary"
            className="px-3"
            endIcon={<FitnessCenter />}
            onClick={handleAthleticOnClick}
          >
            {t("playerEvaluation.athletic")}
          </Button>

          <Button
            variant={readMind ? "contained" : "outlined"}
            color="secondary"
            className="px-3"
            endIcon={<EmojiObjects />}
            onClick={handleMindOnClick}
          >
            {t("playerEvaluation.mind")}
          </Button>
        </div>
        <div>
          {category !== null ? (
            <div className="p-5">
              <PlayerCriteriasComponent category={category} />
            </div>
          ) : (
            <div></div>
          )}
        </div>
      </div>
    </div>
  );
}

export default PlayerEvaluationPage;
