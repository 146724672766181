import React, { useContext, useEffect, useState } from "react";
import { Typography } from "@material-ui/core";
import PlayerCard from "../../../components/Player/PlayerCard/PlayerCard";
import Player from "../../../../core/models/Player";
import Wishlist from "../../../../core/models/Wishlist";
import { firestore } from "firebase";
import ModelFactory from "../../../../core/service/model.factory";
import { StoreUserContext } from "../../../../core/providers/StoreUserProvider";
import { useParams } from "react-router-dom";

function WishlistDetail() {
  const fsUser = useContext(StoreUserContext);

  const { wishlistId } = useParams();

  const [players, setPlayers] = useState<Player[] | null>(null);
  const [wishlist, setWishlist] = useState<Wishlist | null>(null);
  const [wishlists, setWishlists] = useState<Wishlist[] | null>(null);

  useEffect(() => {
    if (!fsUser) return;

    let unsubscribe = firestore()
      .collection("wishlists")
      .doc(wishlistId)
      .onSnapshot((doc) => {
        let w: any = doc.data();
        w.uid = doc.id;
        setWishlist(ModelFactory.CreateWishlistFromFirestore(w));
      });

    return () => {
      unsubscribe();
    };
  }, [fsUser, wishlistId]);

  useEffect(() => {
    if (!wishlist) return;

    setPlayers([]);

    let p: Player[] = [];

    wishlist.players.forEach(async (playerUid) => {
      let doc = await firestore().collection("players").doc(playerUid).get();
      let player: any = doc.data();
      player.uid = doc.id;
      p.push(ModelFactory.CreatePlayerFromFirestore(player));

      setPlayers([...p]);
    });
  }, [wishlist]);

  useEffect(() => {
    if (!fsUser) return;

    let unsubscribe = firestore()
      .collection("wishlists")
      .where("user", "==", fsUser.uid)
      .onSnapshot((data) => {
        let w: Wishlist[] = [];

        data.forEach((doc) => {
          let wishlist: any = doc.data();
          wishlist.uid = doc.id;
          w.push(ModelFactory.CreateWishlistFromFirestore(wishlist));
        });

        setWishlists([...w]);
      });

    return () => {
      unsubscribe();
    };
  }, [fsUser]);

  return (
    <div
      style={{ backgroundColor: "black", height: "100%" }}
      className="pt-5 px-5"
    >
      <div className="d-flex align-items-center">
        <Typography variant="h6" color="textPrimary" align="left">
          {wishlist?.name}
        </Typography>
      </div>

      <div
        className="d-inline-flex flex-row flex-wrap px-4 pb-4 rounded mt-3 float-left col-12"
        style={{ border: "1px solid rgb(50,50,50)" }}
      >
        {wishlists ? (
          players?.map((player) => (
            <div className="col-3 mt-4" key={player.uid}>
              <PlayerCard player={player} wishlists={wishlists}></PlayerCard>
            </div>
          ))
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
}

export default WishlistDetail;
