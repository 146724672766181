import React, { useState, useEffect } from "react";
import Document from "../../../../core/models/Document";
import { storage, firestore } from "firebase";
import Player from "../../../../core/models/Player";
import { Delete } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";

type PlayerDocumentPros = {
    document: Document| null;
    player: Player | null;
};

function PlayerDocument({document, player}: PlayerDocumentPros) {
    const [docUrl, setDocUrl] = useState<string | null>(null);

    const onClickDelete = () => {
        if (document !== null && player !== null) {
            let docRef = storage().ref(`attachments/${document.uid}`);
            docRef.delete();
            firestore().collection(`players/${player.uid}/attachments`).doc(document.uid).delete();
        }
    };

    useEffect(() => {
        if (!document) return;
        let docRef = storage().ref(`attachments/${document.uid}`);
        docRef.getDownloadURL().then((url) => {
          setDocUrl(url);
        });
    }, [document]);
    

    return <div className="d-flex flex-row justify-content-between">
        <a style={{color: "#fafafa"}} target="_blank" href={docUrl ? docUrl : ''} download>{document?.realName}</a>
        <IconButton
            aria-label="delete"
            className=""
            style={{ color: "white" }}
            onClick={() => onClickDelete()}
          >
            <Delete />
        </IconButton>
    </div>
}

export default PlayerDocument;