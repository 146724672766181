import './commentaireInput.css';

import React, { useState } from "react";
import { IComposition } from '../../pages/BoardPage/board';

type CommentaireInputProps = {
  composition : IComposition | null,
  setReload : Function,
  reload : boolean,
  addComment : Function
}

const CommentaireInput = (props : CommentaireInputProps) => {

  var [message, setMessages] = useState("");

  const handleSubmitCommentaire = () => {

    if (message != "" && props.composition?.id !== "")
    {
      props.addComment(message);
      setMessages("");
      props.setReload(!props.reload);
    }
    
  }

  return (
    <div className="commentaire-input-wrapper">
     <input type="text" placeholder="Ecrire un commentaire" value={message} onChange={ (e) => { setMessages(e.target.value) }}></input>
     <button className={(!props.composition || props.composition.id === "" || message === "") ? "commentaire-input-wrapper-button-desable" : "commentaire-input-wrapper-button-enable" } onClick={ () => { handleSubmitCommentaire() }}>Ajouter</button>
    </div>
  );
}

export default CommentaireInput;