import React, { useContext, useState, useEffect } from "react";
import { firestore } from "firebase";
import { Formik, ErrorMessage } from "formik";
import {
  Typography,
  Fab,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { Add } from "@material-ui/icons";
import "./Methodology.css";
import CustomTextField from "../../components/CustomTextField/CustomTextField";
import ModelFactory from "../../../core/service/model.factory";
import Methodology from "../../../core/models/Methodology";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { StoreUserContext } from "../../../core/providers/StoreUserProvider";

function MethodologyPage() {
  const { t } = useTranslation();

  const me = useContext(StoreUserContext);

  const [open, setOpen] = useState(false);
  const [methodologies, setMethodologies] = useState<Methodology[]>([]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteMethodology = (methodology: Methodology) => {
    firestore().collection("methodologies").doc(methodology.uid).delete();
  };

  useEffect(() => {
    if (!me) return;

    const hsxId: string =
      me.parent === "none" ? me.uid : me.parent.split("_")[0];

    let unsubscribe = firestore()
      .collection("methodologies")
      .where("owner", "==", hsxId)
      .onSnapshot((data) => {
        let m: Methodology[] = [];

        data.forEach((doc) => {
          let methodology: any = doc.data();
          methodology.uid = doc.id;
          m.push(ModelFactory.CreateMethodologyFromFirestore(methodology));
        });

        setMethodologies([...m]);
      });

    return () => {
      unsubscribe();
    };
  }, [me]);

  return (
    <div
      style={{ backgroundColor: "black", height: "100%" }}
      className="pt-5 pl-5"
    >
      <div className="d-flex align-items-center">
        <Typography variant="h6" color="textPrimary" align="left">
          {t("methodology.methodParam")}
        </Typography>
        <Fab
          variant="extended"
          className="ml-4"
          size="small"
          style={{
            background: "linear-gradient(#01B3F4, #044CAB)",
            color: "white",
          }}
          onClick={handleClickOpen}
        >
          <Add />
          <span className="ml-1">&nbsp;{t("methodology.typePos")}&nbsp;</span>
        </Fab>
      </div>

      <div
        className="d-inline-flex flex-column px-4 pt-4 border rounded mt-3 float-left"
        id="methodology-list"
      >
        {methodologies.map((methodology) => (
          <div className="d-flex align-items-center mb-4" key={methodology.uid}>
            <Typography
              variant="body1"
              color="textPrimary"
              className="mr-5 flex-grow-1"
              align="left"
            >
              {methodology.name}
            </Typography>
            <Link to={`/methodology/${methodology.uid}`}>
              <Button
                className="ml-5"
                style={{
                  background: "linear-gradient(#01B3F4, #044CAB)",
                  color: "white",
                }}
              >
                {t("methodology.methodology")}
              </Button>
            </Link>
            <Button
              className="ml-3"
              style={{
                background: "red",
                color: "white",
              }}
              onClick={() => handleDeleteMethodology(methodology)}
            >
              {t("methodology.deletePos")}
            </Button>
          </div>
        ))}
      </div>

      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            backgroundColor: "black",
            boxShadow: "0px 0px 15px 2px rgba(255,252,255,1)",
          },
        }}
      >
        <Formik
          initialValues={{ positionName: "" }}
          validate={(values) => {
            const errors: any = {};
            if (!values.positionName) {
              errors.positionName = "Champs obligatoire";
            }
            return errors;
          }}
          onSubmit={async (values, { setSubmitting }) => {
            await firestore().collection("methodologies").add({
              name: values.positionName,
              owner: me?.uid,
              tactic: [],
              technic: [],
              athletic: [],
              mind: [],
              classification: [],
            });

            handleClose();
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <DialogTitle>Ajout d'un nouveau type de poste</DialogTitle>
              <DialogContent>
                <CustomTextField
                  label={t("methodology.namePos")}
                  type="text"
                  name="positionName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                  disabled={isSubmitting}
                  value={values.positionName}
                />

                <ErrorMessage
                  name="positionName"
                  render={(msg) => <span style={{ color: "red" }}>{msg}</span>}
                />
              </DialogContent>
              <DialogActions className="justify-content-center mt-3 mb-4">
                <Button
                  type="submit"
                  variant="contained"
                  style={{
                    background: "linear-gradient(#01B3F4, #044CAB)",
                    color: "white",
                    borderRadius: "30px",
                  }}
                  size="large"
                >
                  <div className="mx-3">{t("methodology.submit")}</div>
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
}

export default MethodologyPage;
